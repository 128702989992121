import axios from 'axios'
import store from './store'

const instance = axios.create()
instance.defaults.baseURL = process.env.VUE_APP_BASE_URL_API;
instance.defaults.headers.get['Accept'] = 'application/json';
instance.defaults.headers.get['Content-Type'] = 'application/json';
instance.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`
    return config;
},  function (error) {
    // Do something with request error
    return Promise.reject(error);
});

instance.interceptors.response.use(function(response) {
    return response
}, function(error) {
    if (error.response.status === 401) {
        localStorage.removeItem('token')
        store.dispatch('updateUserInfo', null)
        store.dispatch('updateRoleId', null)
        window.location.replace('/login')
    }
    return Promise.reject(error)
})

export default instance