<template>
  <main>
    <a @click.prevent="$router.go(-1)" href="#" class="link-back"><span class="icon-ico-arrow-left"></span> Edit
        Communication</a>
    <div class="white-box p30" v-if="communication">
      <div class="row">
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Customer name<span class="text-danger">*</span></div>
             <v-select 
              :filterable="false"
              @search="debounceSearch" 
              @input="changeCustomer"
              placeholder="Choose One" 
              v-model="customer" 
              :options="customers"
              >
              <template v-if="customers.length >= 10 && (customers.length < totalCustomers)" #list-footer>
                <button @click="loadMoreCustomers" class="d-flex py-2" style="opacity: 0.8; margin: auto; background: transparent; border: none">
                 {{ onLoadMore ? 'Please wait...' : 'Click to load more' }}
                </button>
              </template>
            </v-select>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">PIC</div>
            <select class="form-select" v-model="pic_id">
              <option :value="null">Choose One</option>
              <option v-for="(pic, index) in pics" :key="index" :value="pic.id">{{pic.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">Date<span class="text-danger">*</span></div>
                <date-picker v-model="date" :config="options.date"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-date"></span>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">Time<span class="text-danger">*</span></div>
                <date-picker v-model="time" :config="options.time"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-time"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb15">Communication by</div>
            <div class="row">
              <div class="col-sm-4" v-for="(item, index) in communication_with_type" :key="index">
                <div class="form-check">
                  <input class="form-check-input" name="radio-com" type="radio" :id="item" :value="item" v-model="communication.communication_with">
                  <label class="form-check-label" :for="item">
                    {{item}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Description<span class="text-danger">*</span></div>
            <input type="text" class="form-control" v-model="communication.description">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Category<span class="text-danger">*</span></div>
            <select class="form-select" v-model="communication.category_id">
              <option disabled value="">Choose One</option>
              <option v-for="category in categories" :key="category.id" :value="category.id">{{category.name}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-xl-12">
        <div class="form-group">
          <div class="grey mb5">Attachment</div>
          <div class="row align-items-center g-1 mb5">
            <div class="col-10 col-sm-8 col-xl-7">
              <div class="box-form-comment flex-column px-3" @click="$refs.input.click()">
                <div v-if="files.length > 0">
                  <div v-for="file, index in files" :key="index">
                    <a @click.prevent="previewDocs(file)" href="#" class="blue" style="cursor: pointer;">{{file.name || file.filename}}</a>
                    <button @click.stop="deleteFile(index)" class="btn btn-close-file"><span class="red icon-ico-close"></span></button>
                  </div>
                </div>
                <span v-else>Choose File</span>
              </div>
            </div>
            <div class="col-2 col-sm-2 col-xl-1">
              <div class='file'>
                <label for='input-file'>
                  <span class="icon-ico-attach"></span>
                </label>
                <input multiple id='input-file' type='file' :accept="acceptTypes" @change="uploadAttach" ref="input"/>
              </div>
            </div>
          </div>
          <small v-if="files.length == 0" class="text-danger text-sm px-3">*format files: .jpg, .png, .pdf with max size 5mb</small>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <button v-if="hideDeleteBtn" @click="deleteCommunication" class="btn btn-border-red mx-2" type="button">Delete</button>
        <button v-if="hideEditBtn" :disabled="disabledBtn" @click="updateCommunication" class="btn btn-orange mx-2" type="button">Update</button>
      </div>
    </div>
  </main>
</template>

<script>
import axios from '@/axios'
import moment from 'moment'
import {showErrorMessage} from '@/utils';

export default {
  data() {
    return {
      options: {
        date: {
          format: 'DD-MM-YYYY',
          // minDate: moment().format(),
        },
        time: {
          format: 'HH:mm'
        }
      },
      communication_with_type: ["Email", "Whatsapp", "Phone"],
      date: null,
      time: null,
      customers: [],
      customer: null, 
      totalCustomers: 0,
      pics: [],
      pic_id: null,
      communication: null,
      categories: [],
      files: [],
      deleted_attachments: [],
      acceptTypes: '.pdf, .png, .jpg, .jpeg',
      totalFilesSize: 0,
      debounce: null,
      query: '',
      onLoadMore: false,
      initialPageCust: 1
    }
  },
  methods: {
    loadMoreCustomers(){
      this.onLoadMore = true
      this.initialPageCust++
      this.getCustomers(null, this.initialPageCust)
        .then(() => {
          this.onLoadMore = false
        })
    },
    debounceSearch(query, loading){
      clearTimeout(this.debounce)
      this.query = query
      this.initialPageCust = 1
      this.debounce = setTimeout(() => {
        this.customers = []
        this.getCustomers(loading, this.initialPageCust)
      }, 500)
    },
    getCustomers(loading, page) {
      loading && loading(true)
      axios.get(`/customers?per_page=10&page=${page}&search=${this.query}`)
        .then(res => {
          const customers = res.data.data.data
          loading && loading(false)
          customers.map(customer => {
            this.customers.push({
              label: customer.name,
              value: customer.id,
            })
          })
      }).catch(err => {
          showErrorMessage(err)
          loading && loading(false)
      }) 
    },
    getDataDetail() {
      this.$store.dispatch('toggleLoadingProgress', true)
      const getCommunicationHistory = axios.get(`/communication-histories/${this.$route.params.id}`)
      const getCategories = axios.get('/communication-categories')
      const getCustomers = axios.get('/customers?per_page=9&page=1')

      Promise.all([getCommunicationHistory, getCategories, getCustomers]).then(res => {
        this.$store.dispatch('toggleLoadingProgress', false)

        this.communication = res[0].data.data
        this.pic_id = this.communication.pic_id
        this.getPicsByCust({value: this.communication.customer_id})
        if(this.communication.attachments){
          this.communication.attachments.forEach(file => {
            file.size = file.filesize
            this.totalFilesSize += file.filesize
            this.files.push(file)
          }
        )}
        if(this.communication.communicated_at) {
          this.date = moment(this.communication.communicated_at).format('DD-MM-YYYY')
          this.time = moment(this.communication.communicated_at).format('HH:mm')
        }

        this.categories = res[1].data.data

        const customers = res[2].data.data
        customers.data.map(customer => {
          this.customers.push({
            label: customer.name,
            value: customer.id
          })
        })
        this.totalCustomers = customers.total
        this.customer = {
          label: this.communication.customer_name,
          value: this.communication.customer_id,
        }
        this.customers.push(this.customer)
      }).catch(err => {
        showErrorMessage(err)
      })
    },
    changeCustomer(){
      this.pic_id = null
      this.getPicsByCust({value: this.customer.value})
    },
    getPicsByCust(customer) {
      const {value} = customer
      this.$store.dispatch('toggleLoadingProgress', true)  
      axios.get(`/customers/${value}/pics`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)
          const {data} = res.data
          this.pics = data
        })
        .catch(err => {
          showErrorMessage(err)
        })
    },

    updateCommunication() {
      if(!this.disabledBtn){
        this.$store.dispatch('toggleLoadingProgress', true)
        let date = this.date.split('-').reverse().join('-')

        let form = new FormData()
        form.append('_method', 'PUT')
        form.append('customer_id', this.customer.value)
        form.append('pic_id', this.pic_id || '')
        form.append('date', date)
        form.append('time', this.time)
        form.append('communication_with', this.communication.communication_with)
        form.append('description', this.communication.description)
        form.append('category_id', this.communication.category_id)
        
        if(this.files.length > 0) {
          this.files.forEach(file => {
            if(!file.id) {
              form.append('attachments[]', file)
            }
          })
        }

        if(this.deleted_attachments.length > 0) {
          this.deleted_attachments.forEach(file => {
            form.append('deleted_attachments[]', file)
          })
        }
  
        axios.post(`/communication-histories/${this.$route.params.id}`, form, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(() => {
            this.$notify({ type: 'success', title: 'Success', text: 'Success edit communication' })
            this.$store.dispatch('toggleLoadingProgress', false)  
            this.$router.push({name: 'CommunicationHistory'})
          }).catch(error => {
            showErrorMessage(error)
          })
      }
    },
    deleteCommunication() {
      if(confirm('Are you sure you want to delete this data?')){
        this.$store.dispatch('toggleLoadingProgress', true)

        axios.delete(`/communication-histories/${this.$route.params.id}`)
          .then(() => {
            this.$notify({ type: 'success', title: 'Success', text: 'Success delete communication' })
            this.$store.dispatch('toggleLoadingProgress', false)
            this.$router.push({
              name: 'CommunicationHistory'
            })
          })
          .catch(err => {
            showErrorMessage(err)
          })
      }
    }, 
    uploadAttach(e) {
      let fileSizeLimit = 5120; // In MB
      let files = e.target.files
      let exitLoop = false
      Array.from(files).forEach(file => {
        // exit loop when totalfilessize in bigger than filesizelimit
        if(exitLoop) return

        if(file.type !== 'application/pdf' && file.type !== 'image/png' && file.type !== 'image/jpeg'){
          this.$notify({ type: 'error', title: 'Error', text: 'Format file not supported'})
          return
        }  

        this.totalFilesSize += file.size

        if (this.totalFilesSize <= fileSizeLimit * 1024) {    
          this.files.push(file)
          e.target.value = ''
        } else {
          this.totalFilesSize -= file.size
          this.$notify({ type: 'error', title: 'Error', text: 'Total file size must be lower than 5mb'})
          exitLoop = true
        }
      })
    },
    previewDocs(attach) {
      if(attach.filepath) {
        let url = process.env.VUE_APP_BASE_URL_API
        let newUrl = url.replace('/api', '/');
        window.open(newUrl+attach.filepath)
      } else {
        let file = this.files.filter(({name}) => name === attach.name)
        let url = URL.createObjectURL(file[0])
        window.open(url)
      }
    },
    deleteFile(index) {
      let file = this.files.splice(index, 1)
      this.totalFilesSize -= file[0].size
      if(file[0].id) this.deleted_attachments.push(file[0].id)
    },
  },
  computed: {
    disabledBtn() {
      return this.date === null ||
             this.time === null ||
             this.communication.with === '' ||
             this.communication.with === null ||
             this.customer === null ||
             this.communication.communication_with === '' ||
             this.communication.communication_with === null ||
             this.communication.description === '' ||
             this.communication.description === null 
    },
    AppActiveUser()     { return this.$store.state.AppActiveUser },
    hideDeleteBtn() {
      return this.AppActiveUser.permissions.includes('Delete Communication History')
    },
    hideEditBtn() {
      return this.AppActiveUser.permissions.includes('Edit Communication History')
    },
  },
  mounted() {
    this.getDataDetail()
  }
}
</script>

<style>

</style>