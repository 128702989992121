<template>
  <main>
    <a @click.prevent="$router.go(-1)" href="#" class="link-back"><span class="icon-ico-arrow-left"></span> Edit Technical</a>
    <div class="white-box p30">
      <div class="row">
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Address ID</div>
            <input type="text" class="form-control" :value="$route.params.idAddress" disabled>
          </div>
        </div>
        <div class="font-bold mb24">Product</div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Brand name </div>
            <input type="text" class="form-control" v-model="product.brand_name">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6"></div>
        <div class="col-xl-12 col-sm-12">
          <div class="row">
            <div class="col-sm-3 col-xl-2 col-12">
              <div class="form-group">
                <div class="grey mb5">Thickness (Micron)</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="product.thickness">
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Roll/CUT</div>
                <input type="text" class="form-control" v-model="product.roll_cut">
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Film width (cm)</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="product.film_width">
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Cup weignt (Gr)</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="product.cup_weight">
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Volume (MI)</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="product.volume">
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Denier</div>
                <input type="text" class="form-control" v-model="product.denier">
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Top load (Kg)</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="product.top_load">
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Tenacity (gpd)</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="product.tenacity">
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Drop test</div>
                <input type="text" class="form-control" v-model="product.drop_test">
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Elongation %</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="product.elongation">
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="separator dashed"></div>
        </div>
        <div class="font-bold mb24">Extruder</div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Type </div>
            <input type="text" class="form-control" v-model="extruder.type">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6"></div>
        <div class="col-xl-12 col-sm-12">
          <div class="row">
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Amount</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="extruder.amount">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Manufactured year</div>
                <input type="number" class="form-control" v-model="extruder.manufactured_year">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Line speed (M/Min)</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="extruder.line_speed">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">L/D Ratio</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="extruder.l_d_ratio">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-12">
              <div class="form-group">
                <div class="grey mb5">Temp. Setting (°C)</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="extruder.temperature_setting">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-12">
              <div class="form-group">
                <div class="grey mb5">Chilled Roll Temp. (°C)</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="extruder.chilled_roll_temperature">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Calpet conc (%)</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="extruder.calpet_conc">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6 none-mm"></div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Streching ratio</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="extruder.stretching_ratio">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-12">
              <div class="form-group">
                <div class="grey mb5">Cons. Kg/Hour</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="extruder.cons">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-12">
              <div class="form-group">
                <div class="grey mb5">Water Bath Temp (°C)</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="extruder.water_bath_temperature">
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="separator dashed"></div>
        </div>
        <div class="font-bold mb24">Weaving</div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Type </div>
            <input type="text" class="form-control" v-model="weaving.type">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6"></div>
        <div class="col-sm-3 col-xl-2 col-12">
          <div class="form-group">
            <div class="grey mb5">MNF year</div>
            <input type="number" class="form-control" v-model="weaving.manufactured_year">
          </div>
        </div>
        <div class="col-sm-3 col-xl-2 col-6">
          <div class="form-group">
            <div class="grey mb5">Quantity</div>
            <input type="number" min="0" step="0.01" class="form-control" v-model="weaving.quantity">
          </div>
        </div>
        <div class="col-sm-3 col-xl-2 col-6">
          <div class="form-group">
            <div class="grey mb5">Speed (Rpm)</div>
            <input type="number" min="0" step="0.01" class="form-control" v-model="weaving.speed">
          </div>
        </div>
        <div class="col-xl-12">
          <div class="separator dashed"></div>
        </div>
        <div class="col-xl-12 col-sm-12">
          <div class="row">
            <div class="col-sm-6 col-xl-6 col-6">
              <div class="form-group">
                <div class="grey mb5">Application</div>
                <input type="text" class="form-control" v-model="weaving.app_name">
              </div>
            </div>
            <div class="col-sm-6 col-xl-6 col-6">
              <div class="form-group">
                <div class="grey mb5">Type</div>
                <input type="text" class="form-control" v-model="weaving.app_type">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Amount</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="weaving.app_amount">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Manufactured year</div>
                <input type="number" class="form-control" v-model="weaving.app_manufactured_year">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-12">
              <div class="form-group">
                <div class="grey mb5">Number of cavity</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="weaving.app_number_of_cavity">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-12">
              <div class="form-group">
                <div class="grey mb5">Heater tempt (°C)</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="weaving.app_heater_temperature">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Tot production</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="weaving.app_total_production">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Cycle time</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="weaving.app_cycle_time">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-12">
              <div class="form-group">
                <div class="row mt40">
                  <div class="col-sm-6">
                    <div class="form-check">
                      <input class="form-check-input" name="radio-com" type="radio" id="radio1" value="Offline" v-model="weaving.app_status">
                      <label class="form-check-label" for="radio1">
                        Offline
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-check">
                      <input class="form-check-input" name="radio-com" type="radio" id="radio2" value="Online" v-model="weaving.app_status">
                      <label class="form-check-label" for="radio2">
                        Online
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button v-if="hideDeleteBtn" @click="deleteTechnical" class="btn btn-border-red mx-2" type="button">Delete</button>
        <button v-if="hideEditBtn" @click.prevent="updateTechnical" class="btn btn-orange mx-2" type="button">Update</button>
      </div>
    </div>
  </main>
</template>

<script>
import axios from '@/axios'
import {showErrorMessage} from '@/utils';

export default {
  data() {
    return {
      product: {},
      extruder: {},
      weaving: {}
    }
  },
  methods: {
    getTechnical() {
      this.$store.dispatch('toggleLoadingProgress', true)

      axios.get(`/customers/${this.$route.params.id}/addresses/${this.$route.params.idAddress}/technicals/${this.$route.params.idTech}`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)
          
          const data = {
            product: res.data.data.product,
            extruder: res.data.data.extruder,
            weaving: res.data.data.weaving
          }

          for (var keyHead of Object.keys(data)) {
            let head = data[keyHead]
            for (var key of Object.keys(head)) {
              if(head[key] === '-'){
                data[keyHead][key] = ""
              } 
            }
          }

          const {extruder, product, weaving} = data

          this.product = product
          this.extruder = extruder
          this.weaving = weaving
        }).catch(err => {
          showErrorMessage(err)
        })
    },
    updateTechnical(){
      this.$store.dispatch('toggleLoadingProgress', true)
      const data = {
        product: {
          brand_name: this.product.brand_name,
          thickness: this.product.thickness,
          roll_cut: this.product.roll_cut,
          film_width: this.product.film_width,
          cup_weight: this.product.cup_weight,
          volume: this.product.volume,
          denier: this.product.denier,
          top_load: this.product.top_load,
          tenacity: this.product.tenacity,
          drop_test: this.product.drop_test,
          elongation: this.product.elongation
        },
        extruder: {
          type: this.extruder.type,
          amount: this.extruder.amount,
          manufactured_year: this.extruder.manufactured_year,
          line_speed: this.extruder.line_speed,
          l_d_ratio: this.extruder.l_d_ratio,
          temperature_setting: this.extruder.temperature_setting,
          chilled_roll_temperature: this.extruder.chilled_roll_temperature,
          calpet_conc: this.extruder.calpet_conc,
          stretching_ratio: this.extruder.stretching_ratio,
          cons: this.extruder.cons,
          water_bath_temperature: this.extruder.water_bath_temperature,
        },
        weaving: {
          type: this.weaving.type,
          manufactured_year: this.weaving.manufactured_year,
          quantity: this.weaving.quantity,
          speed: this.weaving.speed,
          app_name: this.weaving.app_name,
          app_type: this.weaving.app_type,
          app_manufactured_year: this.weaving.app_manufactured_year,
          app_number_of_cavity: this.weaving.app_number_of_cavity,
          app_heater_temperature: this.weaving.app_heater_temperature,
          app_total_production: this.weaving.app_total_production,
          app_cycle_time: this.weaving.app_cycle_time,
          app_status: this.weaving.app_status,
          app_amount: this.weaving.app_amount
        } 
      }
      for (var keyHead of Object.keys(data)) {
        let head = data[keyHead]
        for (var key of Object.keys(head)) {
          if(head[key] === ''){
            data[keyHead][key] = "-"
          } 
        }
      }

      axios.put(`/customers/${this.$route.params.id}/addresses/${this.$route.params.idAddress}/technicals/${this.$route.params.idTech}`, data)
        .then(() => {
            this.$store.dispatch('toggleLoadingProgress', false)
            this.$notify({ type: 'success', text: 'Success edit technical' }) 
            this.$router.push({path: `/customer/${this.$route.params.id}/address/${this.$route.params.idAddress}/technical`})
          }).catch(error => {
            showErrorMessage(error)
          })

    },
    deleteTechnical(){
      if(confirm('Are you sure you want to delete this data?')){
        this.$store.dispatch('toggleLoadingProgress', true)

        axios.delete(`/customers/${this.$route.params.id}/addresses/${this.$route.params.idAddress}/technicals/${this.$route.params.idTech}`)
          .then(() => {
            this.$notify({ type: 'success', title: 'Success', text: 'Success delete technical' })
            this.$store.dispatch('toggleLoadingProgress', false)
            this.$router.push({path: `/customer/${this.$route.params.id}/address/${this.$route.params.idAddress}/technical`})
          })
          .catch(error => {
            this.$notify({ type: 'error', title: 'Error', text: error.response.data.message })
            this.$store.dispatch('toggleLoadingProgress', false)
          })
      }
    }
  },
  computed: {
    AppActiveUser() { return this.$store.state.AppActiveUser },
    hideDeleteBtn() {
      return this.AppActiveUser.permissions.includes('Delete Technical')
    },
    hideEditBtn() {
      return this.AppActiveUser.permissions.includes('Edit Technical')
    },
  },
  mounted() {
    this.getTechnical()
  }
}
</script>

<style>

</style>