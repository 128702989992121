<template>
  <main>
    <a @click.prevent="$router.go(-1)" href="#" class="link-back"><span class="icon-ico-arrow-left"></span> Add
      Communication</a>
    <div class="white-box p30">
      <div class="row">
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Customer name<span class="text-danger">*</span></div>
            <v-select 
              :disabled="disabledCustOpt" 
              :filterable="false"
              @search="debounceSearch" 
              @input="getPicsByCust"
              placeholder="Choose One" 
              v-model="customer" 
              :options="customers"
              >
              <template v-if="customers.length >= 10 && (customers.length < totalCustomers)" #list-footer>
                <button @click="loadMoreCustomers" class="d-flex py-2" style="opacity: 0.8; margin: auto; background: transparent; border: none">
                 {{ onLoadMore ? 'Please wait...' : 'Click to load more' }}
                </button>
              </template>
            </v-select>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">PIC</div>
            <select :disabled="disabledPicOpt" class="form-select" v-model="pic_id">
              <option value="">Choose One</option>
              <option v-for="pic in pics" :key="pic.id" :value="pic.id">{{pic.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">Date<span class="text-danger">*</span></div>
                <date-picker v-model="date" :config="options.date"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-date"></span>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">Time<span class="text-danger">*</span></div>
                <date-picker v-model="data.time" :config="options.time"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-time"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb15">Communication by</div>
            <div class="row">
              <div class="col-sm-4" v-for="(type, index) in communication_with_type" :key="index">
                <div class="form-check">
                  <input class="form-check-input" name="radio-com" type="radio" :id="type" v-model="data.communication_with" :value="type">
                  <label class="form-check-label" :for="type">
                    {{type}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Description<span class="text-danger">*</span></div>
            <input type="text" class="form-control" v-model="data.description">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Category<span class="text-danger">*</span></div>
            <select class="form-select" v-model="data.category_id">
              <option disabled value="">Choose One</option>
              <option v-for="category in categories" :key="category.id" :value="category.id">{{category.name}}</option>
            </select>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="form-group">
            <div class="grey mb5">Attachment</div>
            <div class="row align-items-center g-1 mb5">
              <div class="col-10 col-sm-8 col-xl-7">
                <div class="box-form-comment flex-column px-3" @click="$refs.input.click()">
                  <div v-if="files.length > 0">
                    <div v-for="file, index in files" :key="index">
                      <a @click.prevent="previewDocs(file.name)" href="#" class="blue" style="cursor: pointer;">{{file.name}}</a>
                      <button @click.stop="deleteFile(index)" class="btn btn-close-file"><span class="red icon-ico-close"></span></button>
                    </div>
                  </div>
                  <span v-else>Choose File</span>
                </div>
              </div>
              <div class="col-2 col-sm-2 col-xl-1">
                <div class='file'>
                  <label for='input-file'>
                    <span class="icon-ico-attach"></span>
                  </label>
                  <input multiple id='input-file' type='file' :accept="acceptTypes" @change="uploadAttach" ref="input"/>
                </div>
              </div>
            </div>
            <small v-if="files.length == 0" class="text-danger text-sm px-3">*format files: .jpg, .png, .pdf with max size 5mb</small>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button :disabled="disabledBtn" @click="addCommunicationHistory" class="btn btn-orange" type="button">Save</button>
      </div>
    </div>
  </main>
</template>

<script>
import axios from '@/axios'
import {showErrorMessage} from '@/utils';

export default {
  data() {
    return {
      options: {
        date: {
          format: 'DD-MM-YYYY',
        },
        time: {
          format: 'HH:mm'
        }
      },
      customers: [],
      totalCustomers: 0,
      customer: null,
      categories: [],
      files: [],
      acceptTypes: '.pdf, .png, .jpg, .jpeg',
      totalFilesSize: 0,
      pics: [],
      pic_id: '',
      date: null,
      communication_with_type: ["Email", "Whatsapp", "Phone"],
      data: {
        category_id: '',
        customer_id: '',
        date: '',
        time: null,
        communication_with: 'Email',
        description: '',
        attachments: []
      },
      query: '',
      onLoadMore: false,
      initialPageCust: 1
    }
  },

  methods: {
    loadMoreCustomers(){
      this.onLoadMore = true
      this.initialPageCust++
      this.getCustomers(null, this.initialPageCust)
        .then(() => {
          this.onLoadMore = false
        })
    },
    debounceSearch(query, loading){
      clearTimeout(this.debounce)
      this.query = query
      this.initialPageCust = 1
      this.debounce = setTimeout(() => {
        this.customers = []
        this.getCustomers(loading, this.initialPageCust)
      }, 500)
    },
    getCustomers(loading, page) {
      loading && loading(true)
      return axios.get(`/customers?per_page=10&page=${page}&search=${this.query}`)
        .then(res => {
          const customers = res.data.data.data
          this.totalCustomers = res.data.data.total
          loading && loading(false)
          customers.map(customer => {
            this.customers.push({
              label: customer.name,
              value: customer.id,
            })
          })
      }).catch(err => {
          showErrorMessage(err)
          loading && loading(false)
      }) 
    },
    getDataForm() {
      this.$store.dispatch('toggleLoadingProgress', true)  
      const getCustomers = axios.get('/customers?per_page=10&page=1')
      const getCategories = axios.get('/communication-categories')

      Promise.all([getCategories, getCustomers]).then(res => {
        this.$store.dispatch('toggleLoadingProgress', false)
        this.categories = res[0].data.data
        const customers = res[1].data.data
        this.totalCustomers = customers.total
        customers.data.map(customer => {
          this.customers.push({
            label: customer.name,
            value: customer.id,
          })
        })
      }).catch(err => {
        showErrorMessage(err)
      })
    },
    getPicsByCust(customer) {
      const {value} = customer
      this.$store.dispatch('toggleLoadingProgress', true)
      axios.get(`/customers/${value}/pics`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)
          const {data} = res.data
          this.pics = data
        })
        .catch(err => {
          showErrorMessage(err)
        })
    },
    addCommunicationHistory() {
      if(!this.disabledBtn){
        this.$store.dispatch('toggleLoadingProgress', true)  
        this.data.date = this.date.split('-').reverse().join('-')

        let form = new FormData()
        form.append('customer_id', this.customer.value)
        form.append('date', this.data.date)
        form.append('time', this.data.time)
        form.append('communication_with', this.data.communication_with)
        form.append('description', this.data.description)
        form.append('category_id', this.data.category_id)
        
        if(this.files.length > 0) {
          this.files.forEach(file => {
            form.append('attachments[]', file)
          })
        }
        if(this.pic_id !== ""){
          form.append('pic_id', this.pic_id)
        }
  
        axios.post('/communication-histories', form, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(() => {
            this.$store.dispatch('toggleLoadingProgress', false)  
            this.$notify({ type: 'success', title: 'Success', text: 'Success add communication' })
            this.$router.push({name: 'CommunicationHistory'})
          }).catch(error => {
            showErrorMessage(error)
          })
      }
    },

    uploadAttach(e) {
      let fileSizeLimit = 5120; // In MB
      let files = e.target.files
      let exitLoop = false
      Array.from(files).forEach(file => {
        // exit loop when totalfilessize in bigger than filesizelimit
        if(exitLoop) return

        if(file.type !== 'application/pdf' && file.type !== 'image/png' && file.type !== 'image/jpeg'){
          this.$notify({ type: 'error', title: 'Error', text: 'Format file not supported'})
          return
        }  

        this.totalFilesSize += file.size

        if (this.totalFilesSize <= fileSizeLimit * 1024) {    
          this.files.push(file)
          e.target.value = ''
        } else {
          this.totalFilesSize -= file.size
          this.$notify({ type: 'error', title: 'Error', text: 'Total file size must be lower than 5mb'})
          exitLoop = true
        }
      })
    },
    previewDocs(fileName) {
      let file = this.files.filter(({name}) => name === fileName)
      let url = URL.createObjectURL(file[0])
      window.open(url)
    },
    deleteFile(index) {
      let file = this.files.splice(index, 1)
      this.totalFilesSize -= file[0].size
    },
  },
  computed: {
    disabledBtn() {
      return this.customer === null ||
             this.date === null ||
             this.data.time === null ||
             this.data.communication_with === '' ||
             this.data.description === '' ||
             this.data.category_id === ''
    },
    disabledCustOpt() {
      return this.$route.params.customer_data
    },
    disabledPicOpt() {
      return this.customer === null
    },
  },
  mounted() {
    this.getDataForm()
    if(this.$route.params.customer_data) {
      this.customer = this.$route.params.customer_data
      this.getPicsByCust(this.$route.params.customer_data)
    }
  }
  
}
</script>

<style>

</style>