<template>
  <main>
    <a @click.prevent="$router.go(-1)" href="#" class="link-back"><span class="icon-ico-arrow-left"></span> Add Schedule</a>
    <div class="white-box p30">
      <div class="row">
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Schedule Type<span class="text-danger">*</span></div>
            <select class="form-select" v-model="data.schedule_type_id">
              <option value="" disabled>Choose One</option>
              <option v-for="(type, index) in types" :key="index" :value="type.value">{{type.label}}</option>
            </select>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">About<span class="text-danger">*</span></div>
            <input type="text" class="form-control" v-model="data.about">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Place<span class="text-danger">*</span></div>
            <input type="text" class="form-control" v-model="data.place">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6"></div>
        <div class="col-xl-6 col-sm-6">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">Start Date<span class="text-danger">*</span></div>
                <date-picker v-model="data.start_date" :config="options.start_date" @dp-change="doSomethingOnChange"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-date"></span>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">Start Time<span class="text-danger">*</span></div>
                <date-picker v-model="data.start_time" :config="options.time"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-time"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">End Date<span class="text-danger">*</span></div>
                <date-picker :disabled="ifStartDateNull" v-model="data.end_date" :config="options.end_date"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-date"></span>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">End Time<span class="text-danger">*</span></div>
                <date-picker v-model="data.end_time" :config="options.time"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-time"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Participant<span class="text-danger">*</span></div>
            <v-select placeholder="Choose One" :closeOnSelect="false" multiple v-model="scheduleParticipants" :options="participants.filter(o => scheduleParticipants.indexOf(o) < 0)"></v-select>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Customer Name (Optional)</div>
            <input type="text" class="form-control" v-model="data.customer_name">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Goals<span class="text-danger">*</span></div>
            <input type="text" class="form-control" v-model="data.goals">
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button :disabled="disableBtn" @click.prevent="addSchedule" class="btn btn-orange" type="button">Save</button>
      </div>
    </div>
  </main>
</template>

<script>
import axios from '@/axios'
import moment from 'moment'
import {showErrorMessage} from '@/utils';

export default {
  data() {
    return {
      options: {
        start_date: {
          format: 'DD-MM-YYYY',
        },
        end_date: {
          format: 'DD-MM-YYYY',
          minDate: moment().format("DD-MMMM-YYYY")
        },
        time: {
          format: 'HH:mm'
        }
      },

      data: {
        schedule_type_id: "",
        about: "",
        place: "",
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
        participants: [],
        customer_name: "",
        goals: "",
        status: "Need Review"
      },
      scheduleParticipants: [],

      participants: [],
      types: []
    }
  },
  methods: {
    getData() {
      this.$store.dispatch('toggleLoadingProgress', true)  

      const getScheduleType = axios.get('/schedule-types')
      const getEmployees = axios.get('/employees')

      Promise.all([getScheduleType, getEmployees]).then(res => {
        this.$store.dispatch('toggleLoadingProgress', false)
        let schType = res[0].data.data
        let employees = res[1].data.data

        schType.map(type => {
          this.types.push({
            label: type.name,
            value: type.id
          })
        })
        employees.map(item => {
          this.participants.push({
            label: item.name,
            value: item.nik
          })
        })
      }).catch(err => {
        showErrorMessage(err) 
      })
    },
    doSomethingOnChange(e) {
      this.options.end_date.minDate = e.date._d
    },
    addSchedule() {
      if(!this.disableBtn){
        this.$store.dispatch('toggleLoadingProgress', true)
        this.data.participants = this.scheduleParticipants.map(participant => participant.value)
        this.data.start_date = this.data.start_date.split('-').reverse().join('-')
        this.data.end_date = this.data.end_date.split('-').reverse().join('-')
  
        axios.post('/schedules', this.data)
          .then(() => {
            this.$notify({ type: 'success', title: 'Success', text: 'Success add schedule' })
            this.$store.dispatch('toggleLoadingProgress', false)  
            this.$router.push({name: 'Schedule'})
          })
          .catch(error => {
            showErrorMessage(error)
          })
      }
    }
  },
  computed: {
    disableBtn() {
      return this.data.about === '' ||
             this.data.place === '' ||
             this.scheduleParticipants.length < 1 ||
             this.data.goals === '' || 
             this.data.type === '' || 
             this.data.start_date === null || 
             this.data.end_date === null ||
             this.data.start_time === null || 
             this.data.end_time === null
    },
    AppActiveUser()     { return this.$store.state.AppActiveUser },

    ifStartDateNull() {
      return this.data.start_date === null
    },
  },
  mounted() {
    this.getData()
  }
}
</script>

<style>

</style>