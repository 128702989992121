<template>
  <div class="white-box mt-3">
    <div class="header-toolbar">
      <div class="left-side">
        <div class="row align-items-center">
          <div class="col-xl-2 col-sm-2 col-3">
            <div class="grey mm15"> Entries</div>
          </div>
          <div class="col-xl-3 col-sm-4 col-9 mm15">
            <select class="form-select mm15" aria-label="Default select" v-model="entries">
              <option v-for="(item, i) in listEntries" :key="i" :value="item.value">{{item.label}}</option>
            </select>
          </div>
          <div class="col-xl-7 col-sm-6">
            <a v-if="hideAddBtn" @click.prevent="$router.push({path: '/feedback/add'})" href="#" class="btn btn-orange w-100 mm15">Add New</a>
          </div>
        </div>
      </div>
      <div class="right-side">
        <div class="row">
          <div class="col-xl-7 col-sm-6">
            <input type="text" class="form-control mm15" placeholder="Search" @input="debounceSearch">
          </div>
          <div class="col-xl-5 col-sm-6">
            <button @click.prevent="showModalAction('filter')" class="btn btn-filter" type="button">Filter by</button>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table id="t-feedback" class="table">
        <thead>
          <tr>
            <th class="f-1">ID</th>
            <th class="tnd">Date and Time</th>
            <th class="fd">Subject</th>
            <th>Customer</th>
            <th>Type</th>
            <th>Assigned to</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody v-if="facs.length > 0">
          <tr v-for="(fac, index) in facs" :key="index">
            <td>{{fac.id}}</td>
            <td>{{moment(fac.date_time).format('DD MMM YYYY')}}, {{moment(fac.date_time).format('HH:mm')}} WIB</td>
            <td>{{truncate(fac.subject, 20)}}</td>
            <td>{{fac.customer ? fac.customer.name : ''}}</td>
            <td>{{fac.type}}</td>
            <td>
              <span>{{assigneesEmployee(fac.assignees)}}</span>
            </td>
            <td>
              <div class="status" :class="status_fac(fac.status)">{{fac.status}}</div>
            </td>
            <td>
              <a v-if="hideEditBtn" @click.prevent="editFeedback(fac.id)" href="#" class="link-edit"><span class="icon-ico-pencil"></span></a>
              <a @click.prevent="detailFeedback(fac.id)" href="#" class="btn btn-see rbm"><span class="icon-ico-eye"></span></a>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8" class="text-center">No data found</td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination :total="pages.total" :current="pages.current" @setCurrentPage="setCurrentPage" />



    <div class="overlay" :class="{'overlayshow': showModal}"></div>

    <!--Popup-->
    <div id="filter-content" class="popup-wrapper" :class="{'showing': filterModalShow}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center mb15">
          <div class="font-bold">Filter</div>
          <button @click.prevent="showModalAction('filter')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="row">
          <div class="col-xl-6 col-sm-6">
            <div class="form-group">
              <div class="grey mb5">Type</div>
              <select class="form-select" v-model="filter.category">
                <option value="" disabled>Choose One</option>
                <option value="Feedback">Feedback</option>
                <option value="Complaint">Complaint</option>
              </select>
            </div>
          </div>
          <div class="col-xl-6 col-sm-6">
            <div class="form-group">
              <div class="grey mb5">Status</div>
              <select class="form-select" v-model="filter.status">
                <option value="" disabled>Choose One</option>
                <option v-for="(status, index) in statuses" :key="index" :value="status">{{status}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button @click="resetFilter" class="btn btn-border-red mx-2" type="button">Reset</button>
          <button @click="filterFacs" class="btn btn-orange mx-2" type="button">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import moment from 'moment'
import {truncate, showErrorMessage} from '@/utils'
import Pagination from '../../components/Pagination.vue'
export default {
  components: { Pagination },
  data() {
    return {
      truncate,
      moment,
      showModal: false,
      filterModalShow: false,
      statuses: ["New", "In Progress", "No Status", "Checked In", "Reopened"],
      listEntries: [
        {
          label: '10',
          value: '10'
        },
        {
          label: '20',
          value: '20'
        },
        {
          label: '40',
          value: '40'
        },
        {
          label: '100',
          value: '100'
        },
      ],
      filter: {
        category: "",
        status: ""
      },
      category: "",
      status: "",
      entries: "10",
      pages: {
        total: 1,
        current: 1
      },
      query: "",
      debounce: null,

      facs: []
    }
  },
  methods: {
    status_fac(status) {
      return status === 'New' ? 'green' : status === 'In Progress' ? 'yellow' : status === 'No Status' || status === 'Closed' ? 'red' : status === 'Checked In' ? 'blue' : status === 'Reopened' ? 'purple' : 'grey'
    },
    assigneesEmployee(assignees){
      let items = assignees.map(item => item.name)
      return truncate(items.join(', '), 20)
    },
    showModalAction(type) {
      if(type == 'filter') {
        this.filterModalShow = !this.filterModalShow
      }
      this.showModal = !this.showModal
    },
    editFeedback(id) {
      this.$router.push({
        name: 'EditFeedback',
        params: {
          id
        }
      })
    },
    detailFeedback(id) {
      this.$router.push({
        name: 'DetailFeedback',
        params: {
          id
        }
      })
    },
    debounceSearch(event) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.query = event.target.value
        this.resetPage()
        this.getFacs()
      }, 600)
    },
    getFacs() {
      this.$store.dispatch('toggleLoadingProgress', true)  

      axios.get(`/feedback-and-complaints?per_page=${this.entries}&page=${this.pages.current}&search=${this.query}&category=${this.category}&status=${this.status}`)
        .then(res => {
          this.facs = res.data.data.data
          this.pages.total = res.data.data.last_page
          this.pages.current = res.data.data.current_page
          this.$store.dispatch('toggleLoadingProgress', false)
        }).catch(err => {
          showErrorMessage(err)
        })
    },
    resetFilter() {
        this.showModal = false
        this.filterModalShow = false
        this.category = ""
        this.status = ""
        this.filter.category = ""
        this.filter.status = ""
        this.getFacs()
    },
    filterFacs() {
      this.showModal = false
      this.filterModalShow = false
      this.category = this.filter.category
      this.status = this.filter.status
      this.resetPage()
      this.getFacs()
    },
    setCurrentPage(val) {
      this.pages.current = val;
      this.getFacs();
    },
    resetPage() {
      this.pages.total = 1;
      this.pages.current = 1;
    },
  },
  mounted(){
    this.getFacs()
  },
  computed: {
    AppActiveUser() { return this.$store.state.AppActiveUser },
    hideAddBtn() {
      return this.AppActiveUser.permissions.includes('Create Feedback and Complaint')
    },
    hideEditBtn() {
      return this.AppActiveUser.permissions.includes('Edit Feedback and Complaint') || this.AppActiveUser.permissions.includes('Delete Feedback and Complaint')
    }
  },
  watch: {
    entries(){
      this.resetPage()
      this.getFacs()
    }
  }
}
</script>

<style>
/* .description-fac p, .description-fac ol, .description-fac ul  {
  margin-top: unset !important;
  margin-bottom: 0 !important;
} */
</style>