<template>
  <main>
    <a @click.prevent="$router.push({path: `/customer/${$route.params.id}`})" href="#" class="link-back"><span class="icon-ico-arrow-left"></span> List PIC</a>
    <div class="white-box">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>No</th>
              <th>PIC ID</th>
              <th>Name</th>
              <th>Position</th>
              <th>Role PIC</th>
              <th>Label</th>
              <th width="100px">Actions</th>
            </tr>
          </thead>
          <tbody v-if="pics.length > 0" v-click-outside="resetDropdown">
            <tr v-for="(pic,index) in pics" :key="index">
              <td>{{index + 1}}</td>
              <td>
                {{pic.id}}
              </td>
              <td>{{pic.name}}</td>
              <td>{{pic.position}}</td>
              <td>{{pic.role}}</td>
              <td>
                <div v-if="pic.pic_labels">
                  <div v-for="(label, i) in pic.pic_labels" :key="i" class="status" :style="`background-color: ${label.color}1a;color: ${label.color}; margin-right: 2px`">{{label.name}}</div>
                </div>
              </td>
              <td>
                <div class="d-flex">
                  <a v-if="hideEditBtn" @click.prevent="$router.push({name: 'EditPicManagement', params: {id: $route.params.id, idAddress: $route.params.idAddress, idPic: pic.id}})" href="#" class="link-edit"><span class="icon-ico-pencil"></span></a>
                  <button @click.prevent="showModalAction('detail', pic.id)" class="btn btn-see rbm"><span class="icon-ico-eye"></span></button>
                  <div v-if="hideCreateComhis" class="dropdown">
                    <button @click.stop="openDropdown(pic.id)" class="btn btn-close-file" type="button" id="dropdownMenuButton1"
                      data-bs-toggle="dropdown" :aria-expanded="dropdownOpen">
                      <span class="icon-ico-pic"></span>
                    </button>
                    <ul :class="{'show': dropdownOpenValue === pic.id}" class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li><a class="dropdown-item" @click="addComhis(pic, 'Email')" href="#">Add Communication By Email</a></li>
                      <li><a class="dropdown-item" @click="addComhis(pic, 'Phone')" href="#">Add Communication By Phone</a></li>
                      <li><a class="dropdown-item" @click="addComhis(pic, 'Whatsapp')" href="#">Add Communication By Whatsapp</a></li>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="8" class="text-center">No data found</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="overlay" :class="{'overlayshow': showModal}"></div>
    <div v-if="pic" id="rbm-content" class="popup-wrapper" :class="{'showing': detailModalShow}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center">
          <div class="font-bold">PIC Detail</div>
          <button @click.prevent="showModalAction('detail')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="inner-land">
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">Label Name</div>
                <div v-if="pic.pic_labels">
                  <div v-for="(label, i) in pic.pic_labels" :key="i" class="status" :style="`background-color: ${label.color}1a;color: ${label.color}; margin-right: 2px`">{{label.name}}</div>
                </div>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">Address ID</div>
                <div class="status yelow">{{$route.params.idAddress}}</div>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">PIC ID</div>
                <span>{{pic.id}}</span>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">Name</div>
                <span>{{pic.name}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">Phone number</div>
                <span>{{pic.phone_number}}</span>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">Email</div>
                <span>{{pic.email}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">Date of birth</div>
                <span>{{moment(new Date(pic.date_of_birth)).format('DD MMMM YYYY')}}</span>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">Hobby</div>
                <span>{{pic.hobby}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">Position</div>
                <span>{{pic.position}}</span>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">Role PIC</div>
                <span>{{pic.role}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from '@/axios'
import {showErrorMessage} from '@/utils';
import moment from 'moment'
import ClickOutside from 'vue-click-outside'

export default {
  data() {
    return {
      dropdownOpen: false,
      dropdownOpenValue: null,
      moment,
      showModal: false,
      detailModalShow: false,
      pics: [],
      pic: null
    }
  },
  methods:{
    openDropdown(id) {
      this.dropdownOpen = !this.dropdownOpen
      if(id === this.dropdownOpenValue) this.dropdownOpenValue = this.dropdownOpen ? id : null
      else this.dropdownOpenValue = id
    },
    resetDropdown() {
      this.dropdownOpen = false
      this.dropdownOpenValue = null
    },
    showModalAction(type, id) {
      this.showModal = !this.showModal
      if(type == 'detail') {
        if(this.detailModalShow === false){
          this.getDetailPic(id)
        }
        this.detailModalShow = !this.detailModalShow
      }
    },
    addComhis(pic, type) {
      this.$store.dispatch('toggleLoadingProgress', true)
      const data = {
        customer_id: this.$route.params.id,
        pic_id: pic.id,
        communication_with: type
      }

      axios.post(`/communication-histories`, data)
        .then(() => {
          this.$store.dispatch('toggleLoadingProgress', false)
          this.$notify({ type: 'success', text: 'Success add communication' })
          if(type === 'Email' || type === 'Whatsapp' || type === 'Phone') {
            let phone_number = `62${pic.phone_number.substring(1)}`
            let link = document.createElement('a')
            link.href = type === 'Email' ? `mailto:${pic.email}` : type === 'Whatsapp' ? `https://api.whatsapp.com/send?phone=${phone_number}` : `tel:${pic.phone_number}`
            link.target = "_blank"
            link.click()
          } 
          this.$router.push({path: `/communication-history`})
          this.resetDropdown()
        }).catch(error => {
          showErrorMessage(error)
        })
    },
    getPics() {
      this.$store.dispatch('toggleLoadingProgress', true)

      axios.get(`/customers/${this.$route.params.id}/addresses/${this.$route.params.idAddress}/pics`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)
          let {data} = res.data
          this.pics = data
        }).catch(err => {
          showErrorMessage(err)
        })
    },
    getDetailPic(id) {
      let pic = this.pics.filter(pic => pic.id === id)
      this.pic = pic[0]
    }
  },
  computed: {
    AppActiveUser() { return this.$store.state.AppActiveUser },
    hideCreateComhis() {
      return this.AppActiveUser.permissions.includes('Create Communication History')
    },
    hideEditBtn() {
      return this.AppActiveUser.permissions.includes('Edit PIC') || this.AppActiveUser.permissions.includes('Delete PIC')
    },
  },
  directives: {
    ClickOutside
  },
  mounted() {
    this.getPics()
  }
}
</script>

<style scoped>
ul.dropdown-menu.show {
  right: 0 !important;
}
</style>