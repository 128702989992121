<template>
  <main v-if="schedule">
    <a @click.prevent="$router.go(-1)" href="#" class="link-back"><span class="icon-ico-arrow-left"></span> Edit Schedule</a>
    <div class="white-box p30">
      <div class="row">
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Schedule Type<span class="text-danger">*</span></div>
            <select v-if="schedule.type" class="form-select" v-model="schedule.type.id">
              <option value="" disabled>Choose One</option>
              <option v-for="(type, index) in types" :key="index" :value="type.value">{{type.label}}</option>
            </select>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">About<span class="text-danger">*</span></div>
            <input type="text" class="form-control" v-model="schedule.about">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Place<span class="text-danger">*</span></div>
            <input type="text" class="form-control" v-model="schedule.place">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6"></div>
        <div class="col-xl-6 col-sm-6">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">Start Date<span class="text-danger">*</span></div>
                <div class="date-picker-custom">
                  <date-picker @dp-show="hideValue('start')" @dp-hide="value => checkValue('start', value)" v-model="start_date" :config="options.start_date" @dp-change="doSomethingOnChange"></date-picker>
                  <!-- <span v-if="showStartDate">{{start_date}}</span> -->
                </div>
                <div class="icon-form">
                  <span class="icon-ico-date"></span>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">Start Time<span class="text-danger">*</span></div>
                <date-picker v-model="start_time" :config="options.time"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-time"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">End Date<span class="text-danger">*</span></div>
                <div class="date-picker-custom">
                  <date-picker @dp-show="hideValue('end')" @dp-hide="checkValue('end')" v-model="end_date" :config="options.end_date"></date-picker>
                  <span v-if="showEndDate">{{end_date}}</span>
                </div>
                <!-- <date-picker v-model="end_date" :config="options.end_date"></date-picker> -->
                <div class="icon-form">
                  <span class="icon-ico-date"></span>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">End Time<span class="text-danger">*</span></div>
                <date-picker v-model="end_time" :config="options.time"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-time"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Participant<span class="text-danger">*</span></div>
            <v-select placeholder="Choose One" :closeOnSelect="false" multiple v-model="scheduleParticipants" :options="participants.filter(({ label: id1 }) => !scheduleParticipants.some(({ label: id2 }) => id2 === id1))"></v-select>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Customer Name (Optional)</div>
            <input type="text" class="form-control" v-model="schedule.customer_name">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Goals<span class="text-danger">*</span></div>
            <input type="text" class="form-control" v-model="schedule.goals">
          </div>
        </div>
        <div class="col-sm-12">
          <div class="separator"></div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Results</div>
            <quill-editor
              ref="myQuillEditor"
              v-model="schedule.result"
              :options="editorOption"
              class="quill-editor-custom"
            />
            <!-- <textarea class="form-control" placeholder="Type result" rows="6" v-model="schedule.result"></textarea> -->
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Status</div>
            <select class="form-select" v-model="schedule.status">
              <option v-for="(status, index) in statuses" :key="index" :value="status.value">{{status.label}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button v-if="hideDeleteBtn" @click.prevent="deleteSchedule" class="btn btn-border-red mx-2" type="button">Delete</button>
        <button v-if="hideEditBtn" @click.prevent="updateSchedule" class="btn btn-orange mx-2" type="button" :disabled="disableBtn">Update</button>
      </div>
    </div>
  </main>
</template>

<script>
// Rich Text
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import {showErrorMessage} from '@/utils';


import axios from '@/axios'
import moment from 'moment'
export default {
  components: {
    quillEditor
  },
  data() {
    return {
      showStartDate: false,
      showEndDate: false,
      start_date_temp: null,
      end_date_temp: null,
      options: {
        start_date: {
          format: 'DD-MM-YYYY',
        },
        end_date: {
          format: 'DD-MM-YYYY',
          minDate: moment().format("DD-MMMM-YYYY"),
        },
        time: {
          format: 'HH:mm'
        }
      },
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'list': 'bullet' }, { 'list': 'ordered' }]
          ],
        }
      },

      start_date: null,
      start_time: null,
      end_date: null,
      end_time: null,

      types: [],
      statuses: [
        {label: 'Need Review', value: 'Need Review'},
        {label: 'Closed', value: 'Closed'},
      ],
      participants: [],
      scheduleParticipants: [],

      schedule: {}
    }
  },
  methods: {
    hideValue(type) {
      if(type === 'start') {
        if(this.showStartDate){
          this.start_date = null
          this.showStartDate = false
        }
      }
      if(type === 'end') {
        if (this.showEndDate) {
          this.end_date = null
          this.showEndDate = false
        }
      }
    },
    checkValue(type, e) {
      if(type === 'start'){
        if(this.showStartDate){
          if(this.start_date === null) {
            this.showStartDate = true
            this.start_date = this.start_date_temp
          }
        }
        let end_date = this.end_date.split('-').reverse().join('-')
        let value = moment(e.date._d).format('YYYY-MM-DD')

        if(moment(value).isAfter(end_date)){
          this.showStartDate = false
          this.showEndDate = false

          let new_val = moment(e.date._d).format('DD-MM-YYYY')
          this.end_date = new_val
        }
      }
      if(type === 'end'){
        if (this.showEndDate) {
          if(this.end_date === null) {
            this.showEndDate = true
            this.end_date = this.end_date_temp
          }
        }
      }
    },
    doSomethingOnChange(e) {
      this.options.end_date.minDate = e.date._d
    },
    getData() {
      this.$store.dispatch('toggleLoadingProgress', true)  

      const getScheduleType = axios.get('/schedule-types')
      const getEmployees = axios.get('/employees')
      const getSchedule = axios.get(`/schedules/${this.$route.params.id}`)

      Promise.all([getScheduleType, getEmployees, getSchedule]).then(res => {
        this.$store.dispatch('toggleLoadingProgress', false)
        let schType = res[0].data.data
        let employees = res[1].data.data
        let schedule = res[2].data.data

        schType.map(type => {
          this.types.push({
            label: type.name,
            value: type.id
          })
        })
        employees.map(item => {
          this.participants.push({
            label: item.name,
            value: item.nik
          })
        })

        this.schedule = schedule
        schedule.participants.map(item => {
          this.scheduleParticipants.push({
            label: item.name,
            value: item.nik
          })
        })
        this.start_date = moment(new Date(schedule.start_datetime)).format('DD-MM-YYYY')
        this.start_date_temp = moment(new Date(schedule.start_datetime)).format('DD-MM-YYYY')
        this.end_date = moment(new Date(schedule.end_datetime)).format('DD-MM-YYYY')
        this.end_date_temp = moment(new Date(schedule.end_datetime)).format('DD-MM-YYYY')
        this.start_time = moment(new Date(schedule.start_datetime)).format('HH:mm')
        this.end_time = moment(new Date(schedule.end_datetime)).format('HH:mm')

        let startDate = moment(schedule.start_datetime).format('YYYY-MM-DD')
        let endDate = moment(schedule.end_datetime).format('YYYY-MM-DD')
        let dateNow = moment().format('YYYY-MM-DD')
        
        if(moment(startDate).isSameOrBefore(dateNow)){
          if(startDate !== dateNow) {
            this.showStartDate = true
          }
        }
        if(moment(endDate).isSameOrBefore(dateNow)){
          if(endDate !== dateNow) {
            this.showEndDate = true
          }
        }
      }).catch(err => {
        showErrorMessage(err)
      })
    },
    updateSchedule() {
      this.$store.dispatch('toggleLoadingProgress', true)
      let data = {
        schedule_type_id: this.schedule.type.id,
        about: this.schedule.about,
        place: this.schedule.place,
        start_date: this.start_date.split('-').reverse().join('-'),
        end_date: this.end_date.split('-').reverse().join('-'),
        start_time: this.start_time,
        end_time: this.end_time,
        customer_name: this.schedule.customer_name,
        participants: this.scheduleParticipants.map(participant => participant.value),
        goals: this.schedule.goals,
        result: this.schedule.result,
        status: this.schedule.status,
      }

      axios.put(`/schedules/${this.$route.params.id}`, data)
        .then(() => {
          this.$notify({ type: 'success', title: 'Success', text: 'Success edit schedule' })
          this.$store.dispatch('toggleLoadingProgress', false)  
          this.$router.push({name: 'Schedule'})
        })
        .catch(error => {
          showErrorMessage(error)
        })
      
    },
    deleteSchedule(){
      if(confirm('Are you sure you want to delete this data?')){
        this.$store.dispatch('toggleLoadingProgress', true)

        axios.delete(`/schedules/${this.$route.params.id}`)
          .then(() => {
            this.$notify({ type: 'success', title: 'Success', text: 'Success delete schedule' })
            this.$store.dispatch('toggleLoadingProgress', false)
            this.$router.push({
              name: 'Schedule'
            })
          })
          .catch(err => {
            showErrorMessage(err)
          })
      }
    },
  },
  computed: {
    disableBtn() {
      return this.schedule.about === '' ||
             this.schedule.place === '' ||
             this.scheduleParticipants.length < 1 ||
             this.schedule.goals === '' || 
             this.schedule.type === '' ||
             this.start_date === null || 
             this.end_date === null ||
             this.start_time === null || 
             this.end_time === null
    },
    AppActiveUser()     { return this.$store.state.AppActiveUser },

    hideDeleteBtn() {
      return this.AppActiveUser.permissions.includes('Delete Schedule')
    },
    hideEditBtn() {
      return this.AppActiveUser.permissions.includes('Edit Schedule')
    },
  },
  mounted() {
    this.getData()
  }
}
</script>

<style>
</style>