<template>
  <main>
    <a @click.prevent="$router.go(-1)" href="#" class="link-back"><span class="icon-ico-arrow-left"></span> Edit Address</a>
    <div class="white-box p30">
      <div class="row">
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Address ID</div>
            <input type="text" class="form-control" :value="address.id" disabled>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6"></div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Province</div>
            <v-select v-if="!hasRefId" label="label" :options="provinces" v-model="province"></v-select>
            <input v-else :disabled="hasRefId" v-model="address.address_warehouse.provinsi" type="text" class="form-control">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">City</div>
            <v-select v-if="!hasRefId" :disabled="disabledCity" label="label" :options="cities" v-model="city"></v-select>
            <input v-else :disabled="hasRefId" v-model="address.address_warehouse.city" type="text" class="form-control">

          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Area </div>
            <input v-if="!hasRefId" v-validate="'required'" v-model="address.area" type="text" class="form-control" name="Area">
            <input v-else :disabled="hasRefId" type="text" class="form-control" :value="address.address_warehouse.area">
            <span class="text-danger text-sm" v-show="errors.has('Area')">{{ errors.first('Area') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Address</div>
            <input v-if="!hasRefId" v-validate="'required'" v-model="address.address" type="text" class="form-control" name="Address">
            <input v-else :disabled="hasRefId" type="text" class="form-control" :value="address.address_warehouse.address">
            <span class="text-danger text-sm" v-show="errors.has('Address')">{{ errors.first('Address') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Phone number</div>
            <input v-if="!hasRefId" v-validate="'required|numeric'" @keypress="checkValidationNumber" v-model="address.phone_number" type="tel" class="form-control" name="Phone Number">
            <input v-else :disabled="hasRefId" type="text" class="form-control" :value="address.address_warehouse.phone_number">
            <span class="text-danger text-sm" v-show="errors.has('Phone Number')">{{ errors.first('Phone Number') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Fax</div>
            <input v-if="!hasRefId" v-validate="'required|numeric'" @keypress="checkValidationNumber" v-model="address.fax" type="tel" class="form-control" name="Fax">
            <input v-else :disabled="hasRefId" type="text" class="form-control" :value="address.address_warehouse.fax">
            <span class="text-danger text-sm" v-show="errors.has('Fax')">{{ errors.first('Fax') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Select place</div>
            <select class="form-select" v-model="address.place_type">
              <option disabled>Choose One</option>
              <option value="Warehouse">Warehouse</option>
              <option value="Plant">Plant</option>
              <option value="HO">HO</option>
            </select>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6"></div>
      </div>
      <div class="d-flex justify-content-center">
        <button v-if="hideDeleteBtn && !hasRefId" @click.prevent="deleteAddress" class="btn btn-border-red mx-2" type="button">Delete</button>
        <button v-if="hideEditBtn" @click.prevent="updateAddress" :disabled="ifFieldEmpty" class="btn btn-orange mx-2" type="button">Update</button>
      </div>
    </div>
  </main>
</template>

<script>
import axios from '@/axios'
import {checkValidationNumber, showErrorMessage} from '@/utils'
export default {
  data() {
    return {
      checkValidationNumber,
      dataLoaded: false,
      disabledCity: false,
      cities: [],
      city: null,
      provinces: [],
      province: null,
      address: {},
      hasRefId: null
    }
  },
  methods: {
    getAddressAndLocaton(){
      this.$store.dispatch('toggleLoadingProgress', true)  
      const promiseCities = axios.get('/cities')
      const promiseProvinces = axios.get('/provinces')
      const promiseAddress = axios.get(`/customers/${this.$route.params.id}/addresses/${this.$route.params.idAddress}`)

      Promise.all([promiseCities, promiseProvinces, promiseAddress]).then(res => {
        let cities = res[0].data.data
        let provinces = res[1].data.data
        this.address = res[2].data.data

        cities.some(city => {
          if(city.id == this.address.city_id){
            return this.city = {
              label: city.name,
              value: city.id
            }
          }
        })
        provinces.some(province => {
          if(province.id == this.address.province_id){
            return this.province = {
              label: province.name,
              value: province.id
            }
          }
        })
        
        provinces.map(city => {
          this.provinces.push({
            label: city.name,
            value: city.id
          })
        })
        this.hasRefId = this.address.ref_id
        this.$nextTick(() => { //with this we skip the first change
          this.dataLoaded = true
        })
        this.$store.dispatch('toggleLoadingProgress', false)  
      }).catch(err => {
        showErrorMessage(err)
      })
    },

    getCityByProvince(id) {
      this.$store.dispatch('toggleLoadingProgress', true)

      axios.get(`/cities?province_id=${id}`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)  
          let {data} = res.data
          this.cities = []
          data.map(city => {
            this.cities.push({
              label: city.name,
              value: city.id
            })
          })
        }).catch(err => {
          showErrorMessage(err)
        })
    },

    updateAddress() {
      this.$validator.validate().then(valid => {
        if(valid){
          if(!this.ifFieldEmpty){
            this.$store.dispatch('toggleLoadingProgress', true)  
      
            let data;
            if(!this.hasRefId){
              data = {
                province_id: this.province.value,
                city_id: this.city.value,
                area: this.address.area,
                address: this.address.address,
                phone_number: this.address.phone_number,
                fax: this.address.fax,
                place_type: this.address.place_type,
              }
            } else {
              data = {
                ref_id: this.address.ref_id,
                place_type: this.address.place_type,
              }
            }

            axios.put(`/customers/${this.$route.params.id}/addresses/${this.$route.params.idAddress}`, data)
              .then(() => {
                this.$store.dispatch('toggleLoadingProgress', false) 
                this.$notify({ type: 'success', text: 'Success edit address' }) 
                this.$router.push({path: `/customer/${this.$route.params.id}`})
              }).catch(error => {
                showErrorMessage(error)
              })
          }
        }
      })

    },
    deleteAddress(){
      if(confirm('Are you sure you want to delete this data?')){
        this.$store.dispatch('toggleLoadingProgress', true)

        axios.delete(`/customers/${this.$route.params.id}/addresses/${this.$route.params.idAddress}`)
          .then(() => {
            this.$notify({ type: 'success', title: 'Success', text: 'Success delete address' })
            this.$store.dispatch('toggleLoadingProgress', false)
            this.$router.push({path: `/customer/${this.$route.params.id}`})
          })
          .catch(error => {
            this.$notify({ type: 'error', title: 'Error', text: error.response.data.message })
            this.$store.dispatch('toggleLoadingProgress', false)
          })
      }
    },
  },
  computed: {
    ifRefIdNull() {
      return this.city === null ||
             this.province === null ||
             this.address.area === '' ||
             this.address.address === '' ||
             this.address.phone_number === '' ||
             this.address.fax === '' ||
             this.address.place_type === ''
    },
    ifHasRefId(){
      return this.address.place_type === '' || this.address.place_type === null
    },

    ifFieldEmpty() {
      return this.hasRefId ? this.ifHasRefId : this.ifRefIdNull
    },
    AppActiveUser() { return this.$store.state.AppActiveUser },
    hideDeleteBtn() {
      return this.AppActiveUser.permissions.includes('Delete Customer Address')
    },
    hideEditBtn() {
      return this.AppActiveUser.permissions.includes('Edit Customer Address')
    },
  },
  mounted() {
    this.getAddressAndLocaton()
  },
  watch: {
    province(new_val) {
      if(this.dataLoaded){
        this.city = null
        if(new_val){
          this.getCityByProvince(new_val.value)
          this.disabledCity = false
        }else {
          this.disabledCity = true
        }
      }
    }
  }
}
</script>

<style>

</style>