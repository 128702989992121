<template>
  <div class="white-box mt-3">
    <div class="header-toolbar">
      <div class="left-side">
        <div class="row align-items-center">
          <div class="col-xl-2 col-sm-2 col-3">
            <div class="grey"> Entries</div>
          </div>
          <div class="col-xl-3 col-sm-4 col-9">
            <select class="form-select mm15" aria-label="Default select" v-model="entries">
              <option v-for="(item, i) in listEntries" :key="i" :value="item.value">{{item.label}}</option>
            </select>
          </div>
          <div class="col-xl-7 col-sm-6">
          </div>
        </div>
      </div>
      <div class="right-side">
        <div class="row">
          <div class="col-xl-7 col-sm-6">
            <input type="text" class="form-control mm15" placeholder="Search" @input="debounceSearch">
          </div>
          <div class="col-xl-5 col-sm-6">
            <button @click.prevent="showModalAction('filter')" class="btn btn-filter" type="button">Filter by</button>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive-cm">
      <table class="table">
        <thead>
          <tr>
            <th>No</th>
            <th>Customer</th>
            <th>Address</th>
            <th>PIC</th>
            <th width="100px">Actions</th>
          </tr>
        </thead>
        <tbody v-if="customers.length > 0"  v-click-outside="resetDropdown">
          <tr v-for="(customer, index) in customers" :key="index">
            <td>{{getOverallIndex(index)}}</td>
            <td>
              {{customer.name}}
            </td>
            <td>{{customer.total_addresses}}</td>
            <td>{{customer.total_pics}}</td>
            <td>
              <div class="d-flex">
                <div class="dropdown">
                  <button @click.stop="openDropdown(customer.id)" class="btn btn-close-file" type="button" id="dropdownMenuButton1"
                    data-bs-toggle="dropdown" :aria-expanded="dropdownOpen">
                    <span class="icon-ico-pic"></span>
                  </button>
                  <ul :class="{'show': dropdownOpenValue === customer.id}" class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item" @click.prevent="$router.push({name: 'AddCommunicationHistory', params: {customer_data: {label: customer.name, value: customer.id} }})" href="#">Add Comunication History</a></li>
                    <li><a class="dropdown-item" @click.prevent="$router.push({name: 'AddBroadcast'})" href="#">Broadcast</a></li>
                  </ul>
                </div>
                <a v-if="hideDetailBtn" @click.prevent="$router.push({path: `/customer/${customer.id}`})" href="#" class="btn btn-see "><span class="icon-ico-eye"></span></a>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5" class="text-center">No data found</td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination :total="pages.total" :current="pages.current" @setCurrentPage="setCurrentPage" />

    <div class="overlay" :class="{'overlayshow': modal.overlay}"></div>

    <!--Popup-->
    <div id="filter-content" class="popup-wrapper" :class="{'showing': modal.filter}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center mb15">
          <div class="font-bold">Filter</div>
          <button @click.prevent="showModalAction('filter')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="row">
          <div class="col-xl-6 col-sm-6">
            <div class="form-group">
              <div class="grey mb5">End Product</div>
              <select class="form-select" v-model="filter.end_product">
                <option value="" disabled>Choose One</option>
                <option v-for="(product, index) in end_products" :key="index" :value="product">{{product}}</option>

              </select>
            </div>
          </div>
          <div class="col-xl-6 col-sm-6">
            <div class="form-group">
              <div class="grey mb5">Sales Person</div>
              <select class="form-select" v-model="filter.sales_person">
                <option value="" disabled>Choose One</option>
                <option v-for="(person, index) in sales_persons" :key="index" :value="person">{{person}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-sm-12">
            <div class="form-group">
              <div class="grey mb5">Payment Type</div>
              <select class="form-select" v-model="filter.payment">
                <option value="" disabled>Choose One</option>
                <option v-for="(payment, index) in payments" :key="index" :value="payment">{{payment}}</option>

              </select>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button @click="resetFilter" class="btn btn-border-red mx-2" type="button">Reset</button>
          <button @click="filterCustomers" class="btn btn-orange mx-2" type="button">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import ClickOutside from 'vue-click-outside'
import {showErrorMessage} from '@/utils';
import {Pagination} from '../../../components'

export default {
  components: {Pagination},
  data() {
    return {
      dropdownOpen: false,
      dropdownOpenValue: null,
      listEntries: [
        {
          label: '10',
          value: '10'
        },
        {
          label: '20',
          value: '20'
        },
        {
          label: '40',
          value: '40'
        },
        {
          label: '100',
          value: '100'
        },
      ],
      modal: {
        overlay: false,
        filter: false,
      },
      query: '',
      debounce: null,
      entries: '10',
      pages: {
        total: 1,
        current: 1
      },
      customers: [],
      end_products: [],
      end_product: '',
      sales_persons: [],
      sales_person: '',
      payments: [],
      payment: '',
      filter: {
        end_product: '',
        sales_person: '',
        payment: ''
      }
    }
  },
  methods: {
    showModalAction(type) {
      this.modal.overlay = !this.modal.overlay
      if(type == 'filter') {
        this.modal.filter = !this.modal.filter
      }
    },
    getCustomers() {
      this.$store.dispatch('toggleLoadingProgress', true)

      axios.get(`/customers?per_page=${this.entries}&page=${this.pages.current}&search=${this.query}&end_product=${this.end_product}&sales_person=${this.sales_person}&payment=${this.payment}&with_total=true`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)  
          this.pages.total = res.data.data.last_page
          this.pages.current = res.data.data.current_page
          
          let { data } = res.data.data
          this.customers = data
        })
        .catch(err => {
          showErrorMessage(err)
        })
    },
    resetPage() {
      this.pages.total = 1;
      this.pages.current = 1;
    },
    getOverallIndex(index) {
      return this.pages.current * this.entries - this.entries + index + 1
    },
    openDropdown(id) {
      this.dropdownOpen = !this.dropdownOpen
      if(id === this.dropdownOpenValue) this.dropdownOpenValue = this.dropdownOpen ? id : null
      else this.dropdownOpenValue = id
    },
    resetDropdown() {
      this.dropdownOpen = false
      this.dropdownOpenValue = null
    },
    setCurrentPage(val) {
      this.pages.current = val;
      this.getCustomers();
    },
    debounceSearch(event) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.query = event.target.value
        this.resetPage()
        this.getCustomers()
      }, 600)
    },
    getFilterData() {
      this.$store.dispatch('toggleLoadingProgress', true)

      const getEndProducts = axios.get('/misc/customer-end-product')
      const getSalesPersons = axios.get('/misc/customer-sales-person')
      const getPayments = axios.get('/misc/customer-payment')

      Promise.all([getEndProducts, getSalesPersons, getPayments]).then(res => {
        this.end_products = res[0].data.data
        this.sales_persons = res[1].data.data
        this.payments = res[2].data.data
      }).catch(err => {
        showErrorMessage(err)
      })
    },
    resetFilter() {
        this.modal.overlay = false
        this.modal.filter = false
        this.end_product = ""
        this.sales_person = ""
        this.payment = ""
        this.filter.end_product = ""
        this.filter.sales_person = ""
        this.filter.payment = ""
        this.getCustomers()
    },
    filterCustomers() {
      this.modal.overlay = false
      this.modal.filter = false
      this.end_product = this.filter.end_product
      this.sales_person = this.filter.sales_person
      this.payment = this.filter.payment
      this.resetPage()
      this.getCustomers()
    },
  },
  computed: {
    AppActiveUser() { return this.$store.state.AppActiveUser },
    hideDetailBtn() {
      return this.AppActiveUser.permissions.includes('View Customer Address Management')
    },
  },
  mounted() {
    this.getCustomers()
    this.getFilterData()
  },
  watch: {
    entries(){
      this.resetPage()
      this.getCustomers()
    },
  },
  directives: {
    ClickOutside
  }
}
</script>

<style scoped>
.dropdown-menu{
  position: absolute; 
  inset: 0px auto auto 0px; 
  margin: 0px; 
  transform: translate(-181px, 26px);
}

@media only screen and (min-width:768px){
  .dropdown-menu{
    transform: translate3d(-181px, 26px, 0px);
  }
}
</style>