<template>
  <main>
    <a @click.prevent="$router.go(-1)" href="#" class="link-back"><span class="icon-ico-arrow-left"></span> Edit PIC</a>
    <div class="white-box p30">
      <div class="row">
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Address ID</div>
            <input type="text" class="form-control" :value="$route.params.idAddress" disabled>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Label</div>
            <v-select placeholder="Choose One" :closeOnSelect="false" multiple v-model="labels" :options="listLabels.filter(({ label: id1 }) => !labels.some(({ label: id2 }) => id2 === id1))">
              <template
                #selected-option-container="{ option }"
              >
                <div class="selected_custom" :style="`background-color: ${option.color}1a;color: ${option.color}`">
                  <span>{{ option.label }}</span>
                  <button @click.capture="deleteLabelFromCurrent(option.value)" class="vs__deselect"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path></svg></button>
                </div>
              </template>
            </v-select>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">PIC ID </div>
            <input type="text" class="form-control" :value="$route.params.idPic" disabled>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Name<span class="text-danger">*</span></div>
            <input type="text" class="form-control" v-model="pic.name">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Phone number<span class="text-danger">*</span></div>
            <input v-validate="'required|numeric'" @keypress="checkValidationNumber" v-model="pic.phone_number" type="tel" class="form-control" name="Phone Number">
            <span class="text-danger text-sm" v-show="errors.has('Phone Number')">{{ errors.first('Phone Number') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Email<span class="text-danger">*</span></div>
            <input v-validate="'required|email'" v-model="pic.email" type="email" class="form-control" name="Email">
            <span class="text-danger text-sm" v-show="errors.has('Email')">{{ errors.first('Email') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Date of Birth</div>
            <date-picker v-model="date_of_birth" :config="options.date"></date-picker>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Hobby</div>
            <input type="text" class="form-control" v-model="pic.hobby">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Position<span class="text-danger">*</span></div>
            <input type="text" class="form-control" v-model="pic.position">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Role PIC</div>
            <input type="text" class="form-control" v-model="pic.role">
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button v-if="hideDeleteBtn" @click="deletePic" class="btn btn-border-red mx-2" type="button">Delete</button>
        <button v-if="hideEditBtn" :disabled="disabledBtn" @click="updatePic" class="btn btn-orange mx-2" type="button">Update</button>
      </div>
    </div>
  </main>
</template>

<script>
import axios from '@/axios'
import moment from 'moment'
import {checkValidationNumber, showErrorMessage} from '@/utils'
export default {
 data() {
   return {
    checkValidationNumber,
    options: {
      date: {
        format: 'DD MMMM YYYY',
        maxDate: moment().subtract(1, 'days')
      }
    },
    date_of_birth: null,
    labels: [],
    listLabels: [],
    pic: {}
   }
 },
 methods: {
   getPicAndLabels() {
     this.$store.dispatch('toggleLoadingProgress', true)  
     const promiseLabels = axios.get('/labels')
     const promisePic = axios.get(`/customers/${this.$route.params.id}/addresses/${this.$route.params.idAddress}/pics/${this.$route.params.idPic}`)

     Promise.all([promiseLabels, promisePic]).then(res => {
        this.$store.dispatch('toggleLoadingProgress', false)  
        let labels = res[0].data.data
        labels.map(label => {
          this.listLabels.push({
            label: label.name,
            value: label.id,
            color: label.color,
          })
        })
        this.pic = res[1].data.data
        this.date_of_birth = moment(new Date(res[1].data.data.date_of_birth)).format('DD MMMM YYYY')
        this.pic.pic_labels.map(label => {
          this.labels.push({
            label: label.name,
            value: label.id,
            color: label.color,
          })
        })

     }).catch(err => {
        showErrorMessage(err)
     })
   },
   deleteLabelFromCurrent(value) {
     this.labels = this.labels.filter(item => item.value !== value)
   },
   updatePic(){
     this.$validator.validate().then(valid => {
       if(valid){
         if(!this.disabledBtn){
            this.$store.dispatch('toggleLoadingProgress', true)  
            let date_of_birth = moment(new Date(this.date_of_birth)).format('YYYY-MM-DD')
            let labels = []
            this.labels.map(label => {
              labels.push(label.value)
            })
            const data = {
              name: this.pic.name,
              phone_number: this.pic.phone_number,
              email: this.pic.email,
              date_of_birth,
              hobby: this.pic.hobby,
              position: this.pic.position,
              role: this.pic.role,
              labels
            }
    
            axios.put(`/customers/${this.$route.params.id}/addresses/${this.$route.params.idAddress}/pics/${this.$route.params.idPic}`, data)
              .then(() => {
                this.$store.dispatch('toggleLoadingProgress', false)
                this.$notify({ type: 'success', text: 'Success edit pic' }) 
                this.$router.push({path: `/customer/${this.$route.params.id}/address/${this.$route.params.idAddress}/pic`})
    
              }).catch(error => {
                showErrorMessage(error)
              })
         }
       }
     })

   },
   deletePic(){
      if(confirm('Are you sure you want to delete this data?')){
        this.$store.dispatch('toggleLoadingProgress', true)

        axios.delete(`/customers/${this.$route.params.id}/addresses/${this.$route.params.idAddress}/pics/${this.$route.params.idPic}`)
          .then(() => {
            this.$notify({ type: 'success', title: 'Success', text: 'Success delete pic' })
            this.$store.dispatch('toggleLoadingProgress', false)
            this.$router.push({path: `/customer/${this.$route.params.id}/address/${this.$route.params.idAddress}/pic`})
          })
          .catch(error => {
            this.$notify({ type: 'error', title: 'Error', text: error.response.data.message })
            this.$store.dispatch('toggleLoadingProgress', false)
          })
      }
    }
 },
 computed: {
   disabledBtn() {
      return this.pic.name === '' ||
             this.pic.phone_number === '' ||
             this.pic.email === '' ||
             this.pic.position === ''
    },
    AppActiveUser() { return this.$store.state.AppActiveUser },
    hideDeleteBtn() {
      return this.AppActiveUser.permissions.includes('Delete PIC')
    },
    hideEditBtn() {
      return this.AppActiveUser.permissions.includes('Edit PIC')
    },
 },
 mounted() {
   this.getPicAndLabels()
 }
}
</script>

<style>

</style>