<template>
  <div class="spinner">
    <BeatLoader :color="color"/>
  </div>
</template>

<script>
import BeatLoader from 'vue-spinner/src/BeatLoader.vue'
export default {
  data() {
    return {
      color: '#F4AF00'
    }
  },
  components: {
    BeatLoader
  },
}
</script>

<style>
.spinner {
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.322);
  z-index: 9999;
}
</style>