<template>
  <div class="header">
    <div class="for-toggle">
      <button @click.prevent="$store.dispatch('toggleMenuResponsive', true)" class="btn btn-toggle"><img src="../assets/img/toggle.svg" class="img-fluid"></button>
    </div>
    <div class="for-logo">
      <a @click.prevent="redirectDashboard" href="#"><img src="../assets/img/logo.png" class="img-fluid logo-mobile"></a>
    </div>
    <div class="for-mr">
      <div class="d-flex justify-content-end">
        <div class="dropdown" v-if="AppActiveUser">
          <a @click.prevent="profileClicked = !profileClicked" class="btn btn-tranparent dropdown-toggle" href="#" role="button" id="main-user"
            data-bs-toggle="dropdown" aria-expanded="false">
            <span>{{AppActiveUser.name}}</span>
            <img src="../assets/img/user.png" class="img-fluid img-circle">
          </a>

          <ul class="dropdown-menu dropdown-menu-end" :class="{'show': profileClicked}" aria-labelledby="main-user">
            <li>
              <div class="w-100">{{AppActiveUser.name}}</div>
              <div class="grey">NIK {{AppActiveUser.nik}}</div>
            </li>
            <li>
              <a class="dropdown-item" @click.prevent="$router.push({name: 'ChangePassword'})" href="#">Change Password</a>
            </li>
            <li><a @click.prevent="logout" class="dropdown-item red" href="#">Logout</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
export default {
  data() {
    return {
      profileClicked: false,
    }
  },
  methods: {
    changePassword() {
      this.profileClicked = false
      this.$router.push({name: 'ChangePassword'})
    },
    toggleMenu(){
      this.$store.dispatch('toggleMenuResponsive', true)
    },
    redirectDashboard() {
      if(this.$route.name !== 'Dashboard') {
        this.$router.push({name: 'Dashboard'})
      }
    },
    logout(){
      this.$store.dispatch('toggleLoadingProgress', true)
      this.profileClicked = false
      axios.post('/logout')
        .then(() => {
          localStorage.removeItem('token')
          this.$store.dispatch('toggleLoadingProgress', false)
          this.$store.dispatch('updateUserInfo', null)
          this.$store.dispatch('updateRoleId', null)
          this.$notify({ type: 'success', title: 'Success', text: 'Success logout' })
          this.$router.push({
            path: '/login'
          })
        })
        .catch(error => {
          this.$notify({ type: 'error', title: 'Error', text: error.response.data.message })
          this.$store.dispatch('toggleLoadingProgress', false)
        })
    }
  },
  computed: {
    AppActiveUser() { return this.$store.state.AppActiveUser },
  },
  watch: {
    "$route"() {
      this.profileClicked = false
    },
  }
}
</script>

<style scoped>
.dropdown-menu {
  position: absolute;
  margin: 0px; 
  right: 0;
}
</style>