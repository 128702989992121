<template>
  <main>
    <a @click.prevent="$router.go(-1)" href="#" class="link-back"><span class="icon-ico-arrow-left"></span> Technical Detail</a>
    <div class="white-box p30" v-if="technical">
      <div class="row">
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Address ID</div>
            <span>{{$route.params.idAddress}}</span>
          </div>
        </div>
        <div class="font-bold mb24">Product</div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Brand name </div>
            <span>{{technical.product.brand_name}}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6"></div>
        <div class="col-xl-12 col-sm-12">
          <div class="row">
            <div class="col-sm-3 col-xl-2 col-12">
              <div class="form-group">
                <div class="grey mb5">Thickness (Micron)</div>
                <span>{{technical.product.thickness}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Roll/CUT</div>
                <span>{{technical.product.roll_cut}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Film width (cm)</div>
                <span>{{technical.product.film_width}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Cup weignt (Gr)</div>
                <span>{{technical.product.cup_weight}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Volume (MI)</div>
                <span>{{technical.product.volume}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Denier</div>
                <span>{{technical.product.denier}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Top load (Kg)</div>
                <span>{{technical.product.top_load}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Tenacity (gpd)</div>
                <span>{{technical.product.tenacity}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Drop test</div>
                <span>{{technical.product.drop_test}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Elongation %</div>
                <span>{{technical.product.elongation}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="separator dashed"></div>
        </div>
        <div class="font-bold mb24">Extruder</div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Type </div>
            <span>{{technical.extruder.type}}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6"></div>
        <div class="col-xl-12 col-sm-12">
          <div class="row">
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Amount</div>
                <span>{{technical.extruder.amount}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Manufactured year</div>
                <span>{{technical.extruder.manufactured_year}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Line speed (M/Min)</div>
                <span>{{technical.extruder.line_speed}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">L/D Ratio</div>
                <span>{{technical.extruder.l_d_ratio}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-12">
              <div class="form-group">
                <div class="grey mb5">Temp. Setting (°C)</div>
                <span>{{technical.extruder.temperature_setting}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-12">
              <div class="form-group">
                <div class="grey mb5">Chilled Roll Temp. (°C)</div>
                <span>{{technical.extruder.chilled_roll_temperature}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Calpet conc (%)</div>
                <span>{{technical.extruder.calpet_conc}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6 none-mm"></div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Stretching ratio</div>
                <span>{{technical.extruder.stretching_ratio}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-12">
              <div class="form-group">
                <div class="grey mb5">Cons. Kg/Hour</div>
                <span>{{technical.extruder.cons}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-12">
              <div class="form-group">
                <div class="grey mb5">Water Bath Temp (°C)</div>
                <span>{{technical.extruder.water_bath_temperature}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="separator dashed"></div>
        </div>
        <div class="font-bold mb24">Weaving</div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Type </div>
            <span>{{technical.weaving.type}}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6"></div>
        <div class="col-sm-3 col-xl-2 col-12">
          <div class="form-group">
            <div class="grey mb5">MNF year</div>
            <span>{{technical.weaving.manufactured_year}}</span>
          </div>
        </div>
        <div class="col-sm-3 col-xl-2 col-6">
          <div class="form-group">
            <div class="grey mb5">Quantity</div>
            <span>{{technical.weaving.quantity}}</span>
          </div>
        </div>
        <div class="col-sm-3 col-xl-2 col-6">
          <div class="form-group">
            <div class="grey mb5">Speed (Rpm)</div>
            <span>{{technical.weaving.speed}}</span>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="separator dashed"></div>
        </div>
        <div class="col-xl-12 col-sm-12">
          <div class="row">
            <div class="col-sm-6 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Application</div>
                <span>{{technical.weaving.app_name}}</span>
              </div>
            </div>
            <div class="col-sm-6 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Type</div>
                <span>{{technical.weaving.app_type}}</span>
              </div>
            </div>
            <div class="col-sm-6 col-xl-4 col-6 none-mm">

            </div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Amount</div>
                <span>{{technical.weaving.app_amount}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Manufactured year</div>
                <span>{{technical.weaving.app_manufactured_year}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-12">
              <div class="form-group">
                <div class="grey mb5">Number of cavity</div>
                <span>{{technical.weaving.app_number_of_cavity}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-12">
              <div class="form-group">
                <div class="grey mb5">Heater tempt (°C)</div>
                <span>{{technical.weaving.app_heater_temperature}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Tot production</div>
                <span>{{technical.weaving.app_total_production}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Cycle time</div>
                <span>{{technical.weaving.app_cycle_time}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-12">
              <div class="form-group">
                <span>{{technical.weaving.app_status}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from '@/axios'
import {showErrorMessage} from '@/utils';

export default {
  data() {
    return {
      technical: null
    }
  },
  methods: {
    getTechnical() {
      this.$store.dispatch('toggleLoadingProgress', true)

      axios.get(`/customers/${this.$route.params.id}/addresses/${this.$route.params.idAddress}/technicals/${this.$route.params.idTech}`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)
          let {data} = res.data
          this.technical = data
        }).catch(err => {
          showErrorMessage(err)
        })
    }
  },
  mounted() {
    this.getTechnical()
  }
}
</script>

<style>

</style>