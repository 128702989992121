<template>
  <main v-if="customer">
    <a @click.prevent="$router.push({name: 'CustomerManagement'})" href="#" class="link-back"><span class="icon-ico-arrow-left"></span> Customer Detail</a>
    <div class="row">
      <div class="col-lg-6 col-sm-6 col-xl-6">
        <div class="white-box no-mh mm15 p30">
          <div class="popup-item pt-0 wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">Customer ID</div>
                <div class="grey font-bold">{{customer.id || '-'}}</div>
              </div>
              <div class="col-sm-6 col-6">
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">Customer name</div>
                <span>{{customer.name || '-'}}</span>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">No NPWP</div>
                <span>{{customer.no_npwp || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Date of establishment</div>
                <span>{{moment(customer.date_of_establishment).format('DD MMMM YYYY') || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">Email</div>
                <span>{{customer.email || '-'}}</span>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">End product</div>
                <span>{{customer.end_product || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">Sales person</div>
                <span>{{customer.sales_person || '-'}}</span>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">Payment</div>
                <span>{{customer.payment || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Website</div>
                <a target="_blank" :href="customer.website">{{customer.website || '-'}}</a>
              </div>
            </div>
          </div>
          <div class="popup-item">
            <div class="row">
              <div class="col-sm-4 col-6">
                <div class="grey">Facebook</div>
                <a v-if="customer.social_media_facebook" target="_blank" :href="`https://facebook.com/${removeSpesificChar(customer.social_media_facebook, '@')}`">{{customer.social_media_facebook}}</a>
                <span v-else>-</span>
              </div>
              <div class="col-sm-4 col-6">
                <div class="grey">Instagram</div>
                <a v-if="customer.social_media_instagram" target="_blank" :href="`https://instagram.com/${removeSpesificChar(customer.social_media_instagram, '@')}`">{{customer.social_media_instagram}}</a>
                <span v-else>-</span>
              </div>
              <div class="col-sm-4 col-6">
                <div class="grey">Twitter</div>
                <a v-if="customer.social_media_twitter" target="_blank" :href="`https://twitter.com/${removeSpesificChar(customer.social_media_twitter, '@')}`">{{customer.social_media_twitter}}</a>
                <span v-else>-</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-6 col-xl-6">
        <div v-if="hideAddAddress" class="white-box no-mh mb30 p30">
          <a @click.prevent="$router.push({name: 'AddAddressCustomerManagement', params: {id: $route.params.id}})" href="#" class="btn btn-orange w-100">Add Address</a>
        </div>
        <div v-if="customer.addresses.length > 0" class="font-bold mb24">List Address</div>
        <nav v-if="customer.addresses.length > 0">
          <div class="nav nav-tabs justify-content-center mb30" id="nav-tab" role="tablist">
            <button @click.prevent="tabPane = 'active'" :class="{'active': tabPane == 'active'}" class="nav-link" id="nav-active-tab" data-bs-toggle="tab" data-bs-target="#nav-active"
              type="button" role="tab" aria-controls="nav-active" aria-selected="true">Active</button>
            <button @click.prevent="tabPane = 'not_active'" :class="{'active': tabPane == 'not_active'}" class="nav-link" id="nav-not-active-tab" data-bs-toggle="tab" data-bs-target="#nav-not-active"
              type="button" role="tab" aria-controls="nav-not-active" aria-selected="false">Not Active</button>
          </div>
        </nav>
        <div v-if="customer.addresses.length > 0" class="tab-content" id="nav-tabContent">
          <!-- Active Tab -->
          <div v-if="activeAddress.length > 0" :class="{'show active': tabPane == 'active'}" class="tab-pane fade" id="nav-active" role="tabpanel" aria-labelledby="nav-active-tab">
            <div v-for="address in activeAddress" :key="address.id" class="white-box no-mh p20 position-relative mb-3">
              <div class="tri-bullet-btn">
                <div class="btn-group">
                  <button @click.stop="openDropdownAddress(address.id)" type="button" class="btn btn-more" data-bs-toggle="dropdown">
                    <span class="icon-ico-more"></span>
                  </button>
                  <ul v-click-outside="resetDropdownAddress" :class="{'show': dropdownAddressValue === address.id}" class="dropdown-menu dropdown-menu-lg-end">
                    <li><a @click.prevent="showModalAction('detail', address.id)" class="dropdown-item rbm" href="#">Show Detail</a></li>
                    <li v-if="hideEditAddress"><a @click.prevent="$router.push({name: 'EditAddressCustomerManagement', params: {id: $route.params.id, idAddress: address.id}})" class="dropdown-item" href="#">Edit</a></li>
                    <li v-if="hideEditStatusAddress"><a @click.prevent="changeAddressStatus(address.id, 'in_active')" class="dropdown-item red" href="#">Non active</a></li>
                  </ul>
                </div>
              </div>
              <div class="popup-item pt-0 wb">
                <div class="row">
                  <div class="col-sm-6 col-6">
                    <div class="grey">Place</div>
                    <div class="status" :class="{'yellow': address.place_type}">{{address.place_type || '-'}}</div>
                  </div>
                  <div class="col-sm-6 col-6">
                    <div class="grey">Address ID</div>
                    <span class="font-bold grey">{{address.id}}</span>
                  </div>
                </div>
              </div>
              <div class="popup-item">
                <div class="row">
                  <div class="col-sm-6 col-6">
                    <div class="grey">Address </div>
                    <span>{{address.address || address.address_warehouse.address || '-'}}</span>
                  </div>
                  <div class="col-sm-6 col-6">
                    <div class="grey">Area </div>
                    <span>{{address.area || address.address_warehouse.area || '-'}}</span>
                  </div>
                </div>
              </div>
              <div class="box-address-item mb15 p15">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="w-50">
                    <div class="grey">PIC</div>
                    <span>{{address.total_pics}}</span>
                  </div>
                  <a v-if="hidePicView" @click.prevent="$router.push({name: 'PicManagement', params: {id: $route.params.id, idAddress: address.id}})" href="#" class="btn btn-see "><span class="icon-ico-eye"></span></a>
                </div>
              </div>
              <div class="box-address-item mb24 p15">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="w-50">
                    <div class="grey">Technical</div>
                    <span>{{address.total_technicals}}</span>
                  </div>
                  <a v-if="hideTechnicalView" @click.prevent="$router.push({name: 'TechnicalManagement', params: {id: $route.params.id, idAddress: address.id}})" href="#" class="btn btn-see "><span class="icon-ico-eye"></span></a>
                </div>
              </div>
              <div class="row">
                <div v-if="hidePicAdd" class="col-sm-6">
                  <a @click.prevent="$router.push({name: 'AddPicManagement', params: {id: $route.params.id, idAddress: address.id}})" href="#" class="btn btn-border-orange mm15 w-100">Add PIC</a>
                </div>
                <div v-if="hideTechnicalAdd" class="col-sm-6">
                  <a @click.prevent="$router.push({name: 'AddTechnicalManagement', params: {id: $route.params.id, idAddress: address.id}})" href="#" class="btn btn-border-orange w-100">Add Technical</a>
                </div>
              </div>
            </div>
          </div>
          <div v-else :class="{'show active': tabPane == 'active'}" class="tab-pane fade" id="nav-active" role="tabpanel" aria-labelledby="nav-active-tab">
            <div class="white-box no-mh p20">
              <p class="text-center m-0">No Address List Available</p>
            </div>
          </div>

          <!-- Inactive Tab -->
          <div v-if="inactiveAddress.length > 0" :class="{'show active': tabPane == 'not_active'}" class="tab-pane fade" id="nav-not-active" role="tabpanel" aria-labelledby="nav-not-active-tab">
            <div v-for="address in inactiveAddress" :key="address.id" class="white-box no-mh p20 position-relative mb-3">
              <div class="tri-bullet-btn">
                <div class="btn-group">
                  <button @click.stop="openDropdownAddress(address.id)" type="button" class="btn btn-more" data-bs-toggle="dropdown" aria-expanded="false">
                    <span class="icon-ico-more"></span>
                  </button>
                  <ul v-click-outside="resetDropdownAddress" :class="{'show': dropdownAddressValue === address.id}" class="dropdown-menu dropdown-menu-lg-end">
                    <li><a @click.prevent="showModalAction('detail', address.id)" class="dropdown-item rbm" href="#">Show Detail</a></li>
                    <li v-if="hideEditAddress"><a @click.prevent="$router.push({name: 'EditAddressCustomerManagement', params: {id: $route.params.id, idAddress: address.id}})" class="dropdown-item" href="#">Edit</a></li>
                    <li v-if="hideEditStatusAddress"><a @click.prevent="changeAddressStatus(address.id, 'active')"  class="dropdown-item red" href="#">Active</a></li>
                  </ul>
                </div>
              </div>
              <div class="popup-item pt-0 wb">
                <div class="row">
                  <div class="col-sm-6 col-6">
                    <div class="grey">Place</div>
                    <div class="status" :class="{'yellow': address.place_type}">{{address.place_type || '-'}}</div>
                  </div>
                  <div class="col-sm-6 col-6">
                    <div class="grey">Address ID</div>
                    <span class="font-bold grey">{{address.id || '-'}}</span>
                  </div>
                </div>
              </div>
              <div class="popup-item">
                <div class="row">
                  <div class="col-sm-6 col-6">
                    <div class="grey">Address </div>
                    <span>{{address.address || address.address_warehouse.address || '-'}}</span>
                  </div>
                  <div class="col-sm-6 col-6">
                    <div class="grey">Area </div>
                    <span>{{address.area || address.address_warehouse.area || '-'}}</span>
                  </div>
                </div>
              </div>
              <div class="box-address-item mb15 p15">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="w-50">
                    <div class="grey">PIC</div>
                    <span>{{address.total_pics}}</span>
                  </div>
                  <a v-if="hidePicView" @click.prevent="$router.push({name: 'PicManagement', params: {id: $route.params.id, idAddress: address.id}})" href="#" class="btn btn-see "><span class="icon-ico-eye"></span></a>
                </div>
              </div>
              <div class="box-address-item p15">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="w-50">
                    <div class="grey">Technical</div>
                    <span>{{address.total_technicals}}</span>
                  </div>
                  <a v-if="hideTechnicalView" @click.prevent="$router.push({name: 'TechnicalManagement', params: {id: $route.params.id, idAddress: address.id}})" href="#" class="btn btn-see "><span class="icon-ico-eye"></span></a>
                </div>
              </div>
            </div>
          </div>
          <div v-else :class="{'show active': tabPane == 'not_active'}" class="tab-pane fade" id="nav-not-active" role="tabpanel" aria-labelledby="nav-not-active-tab">
            <div class="white-box no-mh p20">
              <p class="text-center m-0">No Address List Available</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="overlay" :class="{'overlayshow': showModal}"></div>
    <div v-if="detailAddress" id="rbm-content" class="popup-wrapper" :class="{'showing': detailModalShow}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center">
          <div class="font-bold">Detail address</div>
          <button @click.prevent="showModalAction('detail')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="popup-item wb">
          <div class="row">
            <div class="col-sm-6 col-6">
              <div class="grey">Place</div>
              <div class="status" :class="{'yellow': detailAddress.place_type}">{{detailAddress.place_type || '-'}}</div>
            </div>
            <div class="col-sm-6 col-6">
              <div class="grey">Address ID</div>
              <div class="status yelow">{{detailAddress.id}}</div>
            </div>
          </div>
        </div>
        <div class="popup-item wb">
          <div class="row">
            <div class="col-sm-6 col-6">
              <div class="grey">Address </div>
              <span>{{detailAddress.address || detailAddress.address_warehouse.address || '-'}}</span>
            </div>
            <div class="col-sm-6 col-6">
              <div class="grey">Area </div>
              <span>{{detailAddress.area || detailAddress.address_warehouse.area || '-'}}</span>
            </div>
          </div>
        </div>
        <div class="popup-item">
          <div class="row">
            <div class="col-sm-6 col-6">
              <div class="grey">Phone number</div>
              <span>{{detailAddress.phone_number || detailAddress.address_warehouse.phone_number || '-'}}</span>
            </div>
            <div class="col-sm-6 col-6">
              <div class="grey">Fax</div>
              <span>{{detailAddress.fax || detailAddress.address_warehouse.fax || '-'}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from '@/axios'
import moment from 'moment'
import ClickOutside from 'vue-click-outside'
import {removeSpesificChar, showErrorMessage} from '@/utils'
export default {
  data() {
    return {
      removeSpesificChar,
      moment,
      tabPane: 'active',
      dropdownAddressValue: null,
      dropdownAddressNotAct: false,
      detailModalShow: false,
      showModal: false,

      customer: null,
      detailAddress: null,
      activeAddress: [],
      inactiveAddress: []
    }
  },
  methods: {
    showModalAction(type,id) {
      if(type == 'detail') {
        if(this.detailModalShow === false){
          this.dropdownAddressValue = null
          this.getDetailAddress(id)
        }
        this.detailModalShow = !this.detailModalShow
      }
      this.showModal = !this.showModal
    },
    getDetailAddress(id) {
      let address = this.customer.addresses.filter(address => address.id === id)
      this.detailAddress = address[0]
    },
    openDropdownAddress(id) {
      this.dropdownAddressValue = id === this.dropdownAddressValue ? null : id
    },
    resetDropdownAddress(e) {
      if(e.target.classList[0] !== 'dropdown-item'){
        this.dropdownAddressValue = null
      }
    },
    getCustomer() {
      this.$store.dispatch('toggleLoadingProgress', true)

      axios.get(`/customers/${this.$route.params.id}`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)
          let {data} = res.data
          this.customer = data
          this.activeAddress = []
          this.inactiveAddress = []
          data.addresses.map(address => {
            if(address.status === 'active') {
              this.activeAddress.push(address)
            } else {
              this.inactiveAddress.push(address)
            }
          })
        })
        .catch(err => {
          showErrorMessage(err)
        })
    },
    changeAddressStatus(address_id, status) {
      this.$store.dispatch('toggleLoadingProgress', true)

      axios.put(`/customers/${this.$route.params.id}/addresses/${address_id}/update-status`, {status})
        .then(() => {
          this.$store.dispatch('toggleLoadingProgress', false)  
          this.$notify({ type: 'success', text: 'Success change status' })
          this.dropdownAddressValue = null
          this.getCustomer()
        })
        .catch(err => {
          showErrorMessage(err)
        })
    }
  },
  computed: {
    AppActiveUser() { return this.$store.state.AppActiveUser },
    hideAddAddress() {
      return this.AppActiveUser.permissions.includes('Create Customer Address')
    },
    hideEditAddress() {
      return this.AppActiveUser.permissions.includes('Edit Customer Address') || this.AppActiveUser.permissions.includes('Delete Customer Address')
    },
    hideEditStatusAddress() {
      return this.AppActiveUser.permissions.includes('Edit Customer Address Status')
    },
    hidePicView() {
      return this.AppActiveUser.permissions.includes('View PIC Management')
    },
    hidePicAdd() {
      return this.AppActiveUser.permissions.includes('Create PIC')
    },
    hideTechnicalView() {
      return this.AppActiveUser.permissions.includes('View Technical Management')
    },
    hideTechnicalAdd() {
      return this.AppActiveUser.permissions.includes('Create Technical')
    },
  },
  mounted() {
    this.getCustomer()
  },
  directives: {
    ClickOutside
  }
}
</script>

<style scoped>
.dropdown-menu {
  right: 40%;
}
</style>