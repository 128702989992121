<template>
  <div class="white-box mt-3">
    <div class="header-toolbar">
      <div class="left-side">
        <div class="row align-items-center">
          <div class="col-xl-2 col-sm-2 col-3">
            <div class="grey"> Entries</div>
          </div>
          <div class="col-xl-3 col-sm-4 col-9">
            <select class="form-select mm15" aria-label="Default select" v-model="entries">
              <option v-for="(item, i) in listEntries" :key="i" :value="item.value">{{item.label}}</option>
            </select>
          </div>
          <div class="col-xl-7 col-sm-6">
            <a v-if="hideAddBtn" @click.prevent="$router.push({path: '/role/add'})" href="#" class="btn btn-orange w-100 mm15">Add New</a>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>No</th>
            <th width="80%">Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody v-if="roles.length > 0">
          <tr v-for="(role, index) in roles" :key="role.id">
            <td>{{getOverallIndex(index)}}</td>
            <td>
              <div class="status" :style="`background-color: ${role.color}1a;color: ${role.color}`">{{role.name}}</div>
            </td>
            <td>
              <a v-if="hideEditBtn" @click.prevent="editRole(role.id)" href="#" class="link-edit"><span class="icon-ico-pencil"></span></a>
              <button @click.prevent="showModalAction('detail', role.id)" class="btn btn-see rbm"><span class="icon-ico-eye"></span></button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="3" class="text-center">No data found</td>
          </tr>
        </tbody>
      </table>
    </div>

    <pagination :total="pages.total" :current="pages.current" @setCurrentPage="setCurrentPage" />


    <div class="overlay" :class="{'overlayshow': showModal}"></div>
    <!--Popup-->
    <div id="rbm-content" class="popup-wrapper" :class="{'showing': detailModalShow}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center">
          <div class="font-bold">Detail Role</div>
          <button @click.prevent="showModalAction('detail')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="inner-scroll">
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">Role</div>
                <div class="status" :style="`background-color: ${detailRole.color}1a;color: ${detailRole.color}`">{{detailRole.name}}</div>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">Atasan</div>
                <span>{{detailRole.superior || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Access</div>
                <ul class="access-list">
                  <li v-for="(detail, index) in detailRole.role_permissions" :key="index">{{detail}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import {Pagination} from '../../../components'
import {showErrorMessage} from '@/utils';

export default {
  components: { Pagination },
  data() {
    return{
      // utils
      showModal: false,
      detailModalShow: false,
      listEntries: [
        {
          label: '10',
          value: '10'
        },
        {
          label: '20',
          value: '20'
        },
        {
          label: '40',
          value: '40'
        },
        {
          label: '100',
          value: '100'
        },
      ],
      entries: '10',
      pages: {
        total: 1,
        current: 1
      },

      // data
      roles: [],
      detailRole: {
        name: '',
        role_permissions: []
      }
    }
  },
  methods: {
    showModalAction(type, id) {
      this.showModal = !this.showModal
      if (type == 'detail'){
        if(this.detailModalShow === false){
          this.getDetailRole(id)
        }
        this.detailModalShow = !this.detailModalShow
      }
    },
    getDetailRole(id) {
      let role = this.roles.filter(role => role.id === id)
      this.detailRole = role[0]
    },
    editRole(id) {
      this.$router.push({
        name: 'EditRoleManagement',
        params: {
          id
        }
      })
    },
    setCurrentPage(val) {
      this.pages.current = val;
      this.getRoles();
    },
    resetPage() {
      this.pages.total = 1;
      this.pages.current = 1;
    },
    getOverallIndex(index) {
      return this.pages.current * this.entries - this.entries + index + 1
    },

    
    getRoles() {
      this.$store.dispatch('toggleLoadingProgress', true)      
      axios.get(`/roles?per_page=${this.entries}&page=${this.pages.current}`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)
          this.pages.total = res.data.data.last_page
          this.pages.current = res.data.data.current_page
          this.roles = res.data.data.data
        })
        .catch(err => {
          showErrorMessage(err)
        })
    },
    
  },
  computed:{
    AppActiveUser() { return this.$store.state.AppActiveUser },
    hideAddBtn() {
      return this.AppActiveUser.permissions.includes('Create Role Employee')
    },
    hideEditBtn() {
      return this.AppActiveUser.permissions.includes('Edit Role Employee') || this.AppActiveUser.permissions.includes('Delete Role Employee')
    },
  },
  mounted() {
    this.getRoles()
  },
  watch: {
    entries(){
      this.resetPage()
      this.getRoles()
    }
  }
}
</script>

<style>

</style>