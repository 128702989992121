<template>
  <div id="app">
    <loading v-if="loading"/>
    <notifications/>
    <router-view/>
  </div>
</template>

<script>
import Loading from './components/Loading.vue'
export default {
  components: { Loading },
  computed: {
    loading() { return this.$store.state.loading },
  },
}
</script>

<style>
@import url('./assets/styles/bootstrap.min.css');
@import url('./assets/styles/normalize.css');
@import url('./assets/styles/bootstrap-datetimepicker.css');
@import url('./assets/styles/main.css');

.vs__dropdown-toggle {
  border: 1px solid #E9E9E9 !important;
  padding: 8px !important;
}

.error-message {
  position: absolute;
}

.date-picker-custom {
  position: relative;
}
.date-picker-custom span {
  position: absolute;
  top: 12px;
  left: 15px;
}

.ql-container .ql-editor {
  min-height: 150px;
}
</style>
