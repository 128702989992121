<template>
  <main>
    <a @click.prevent="$router.go(-1)" href="#" class="link-back"><span class="icon-ico-arrow-left"></span> Add Label</a>
    <div class="white-box p30">
      <div class="row">
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Label Name</div>
            <input v-validate="'required'" v-model="name" type="text" class="form-control" name="Label Name">
            <span class="text-danger text-sm" v-show="errors.has('Label Name')">{{ errors.first('Label Name') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Color</div>
            <div class="box-pick">
              <div class="color-picker showed">
                <label for="colorPicker" class="d-flex align-items-center text-uppercase grey">
                  <input type="color" id="colorPicker" v-model="color" ref="picker">
                  <span>{{currentColor}}</span>
                </label>
              </div>
              <div>
                <button @click="$refs.picker.click()" class="btn btn-for-pick">Select color</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button @click.prevent="addLabel" class="btn btn-orange" type="button" :disabled="disabledBtn">Save</button>
      </div>
    </div>
  </main>
</template>

<script>
import axios from '@/axios'
import {showErrorMessage} from '@/utils';

export default {
  data() {
    return {
      name: '',
      color: '#000000'
    }
  },
  methods: {
    addLabel() {
      if(!this.disabledBtn){
        this.$store.dispatch('toggleLoadingProgress', true)
        axios.post('/labels', {
          name: this.name,
          color: this.color
        }).then(() => {
          this.$notify({ type: 'success', title: 'Success', text: 'Success add label' })
          this.$store.dispatch('toggleLoadingProgress', false) 
          this.$router.push({name: 'LabelManagement'})
        }).catch(error => {
          showErrorMessage(error)
        })
      }
    }
  },
  computed: {
    currentColor() {
      return this.color
    },
    disabledBtn() {
      return this.name === ''
    }
  }
}
</script>

<style>

</style>