import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Vue Notifications
import Notifications from 'vue-notification'
Vue.use(Notifications)

// Vee Validate
import VeeValidate from 'vee-validate';
Vue.use(VeeValidate)

// Bootstrap Date & Time Picker
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
Vue.use(datePicker);

// Vue Select
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
