<template>
  <main>
    <a @click.prevent="$router.push({path: `/customer/${$route.params.id}`})" href="#" class="link-back"><span class="icon-ico-arrow-left"></span> List Technical</a>
    <div v-if="latest_update" class="italic text-dark mb-15">Latest update by: {{technicals.technical_last_updated_by}} ({{technicals.technical_last_updated_at.substr(0, 10)}})</div>
    <div class="white-box">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>No</th>
              <th>Brand Name</th>
              <th class="tnd">EXT Type</th>
              <th>MNF Year</th>
              <th>EXT Number</th>
              <th>EXT Temp Setting</th>
              <th width="100px">Actions</th>
            </tr>
          </thead>
          <tbody v-if="technicals.data.length > 0">
            <tr v-for="(technical, index) in technicals.data" :key="index">
              <td>{{index + 1}}</td>
              <td>
                {{technical.product.brand_name}}
              </td>
              <td>{{technical.extruder.type}}</td>
              <td>{{technical.weaving.mnf_year}}</td>
              <td>{{technical.extruder.number}}</td>
              <td>
                {{technical.extruder.temperature_setting}}
              </td>
              <td>
                <a v-if="hideEditBtn" @click.prevent="$router.push({name: 'EditTechnicalManagement', params: {id: $route.params.id, idAddress: $route.params.idAddress, idTech: technical.id}})" href="#" class="link-edit"><span class="icon-ico-pencil"></span></a>
                <a @click.prevent="$router.push({name: 'DetailTechnicalManagement', params: {id: $route.params.id, idAddress: $route.params.idAddress, idTech: technical.id}})" href="#" class="btn btn-see"><span class="icon-ico-eye"></span></a>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="7" class="text-center">No data found</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import axios from '@/axios'
import {showErrorMessage} from '@/utils';

export default {
  data() {
    return {
      technicals: {
        data: []
      }
    }
  },
  methods: {
    getTechnicals() {
      this.$store.dispatch('toggleLoadingProgress', true)

      axios.get(`/customers/${this.$route.params.id}/addresses/${this.$route.params.idAddress}/technicals`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)
          let {data} = res.data
          this.technicals = data
        }).catch(err => {
          showErrorMessage(err)
        })
    }
  },
  computed: {
    AppActiveUser() { return this.$store.state.AppActiveUser },
    hideEditBtn() {
      return this.AppActiveUser.permissions.includes('Edit Technical') || this.AppActiveUser.permissions.includes('Delete Technical')
    },
    latest_update() {
      return this.technicals.technical_last_updated_at && this.technicals.technical_last_updated_by
    },
  },
  mounted() {
    this.getTechnicals()
  }
}
</script>

<style>

</style>