const actions = {
  toggleMenuResponsive({ commit }, payload) {
    commit('TOGGLE_MENU_RESPONSIVE', payload)
  },
  toggleLoadingProgress({ commit }, payload) {
    commit('TOGGLE_LOADING_PROGRESS', payload)
  },
  updateUserInfo({ commit }, payload) {
    commit('UPDATE_USER_INFO', payload)
  },
  updateRoleId({ commit }, payload) {
    commit('UPDATE_ROLE_ID', payload)
  },
  setPermissions({ commit }, payload) {
    commit('SET_PERMISSIONS', payload)
  },
}

export default actions