<template>
  <main>
    <a @click.prevent="$router.go(-1)" href="#" class="link-back"><span class="icon-ico-arrow-left"></span> Add New Target</a>
    <div class="white-box mb30 no-mh">
      <div class="p20">
        <div class="font-500">Last Month Target: {{currentMonth}}</div>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>No</th>
              <th>Customer</th>
              <th>Grade</th>
              <th>Purchase Type</th>
              <th>TOP</th>
              <th>Target Order</th>
              <th>Target Sales</th>
              <th>W1</th>
              <th>W2</th>
              <th>W3</th>
              <th>W4</th>
              <th>W5</th>
              <th>Remarks</th>
              <th>Ship To</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody v-if="previous_targets.data.length > 0">
            <tr v-for="target, index in previous_targets.data" :key="index">
              <td>{{index + 1}}</td>
              <td>{{target.customer.name}}</td>
              <td>{{target.grade}}</td>
              <td>{{target.purchase_type}}</td>
              <td>{{target.top}}</td>
              <td>{{target.target_order}}</td>
              <td>{{target.target_sales}}</td>
              <td>{{target.first_week}}</td>
              <td>{{target.second_week}}</td>
              <td>{{target.third_week}}</td>
              <td>{{target.fourth_week}}</td>
              <td>{{target.fifth_week}}</td>
              <td>
                {{truncate(target.remarks, 7)}}
              </td>
              <td>{{target.ship_to.name}}</td>
              <td>
                <div v-if="target.status === 'Open'" class="status green">Open</div>
                <div v-else class="status grey">Close</div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="15" class="text-center">No data found</td>
            </tr>
          </tbody>
          <tfoot v-if="previous_targets.data.length > 0">
            <tr>
              <td colspan="5">
                <div class="font-bold">TOTAL</div>
              </td>
              <td>
                <div class="font-bold">{{previous_targets.total_order}}</div>
              </td>
              <td colspan="5">
                <div class="font-bold">{{previous_targets.total_sales}}</div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <div class="white-box mb30 no-mh">
      <div class="p20">
        <div class="font-500">New Target: {{nextMonth}}</div>
      </div>
      <div class="table-responsive" style="min-height: 250px">
        <table class="table">
          <thead>
            <tr>
              <th>No</th>
              <th>Customer</th>
              <th>Grade</th>
              <th>Purch. Type</th>
              <th>TOP</th>
              <th>Target Order</th>
              <th>Target Sales</th>
              <th>W1</th>
              <th>W2</th>
              <th>W3</th>
              <th>W4</th>
              <th>W5</th>
              <th>Remarks</th>
              <th>Outstanding Order</th>
              <th>Ship To</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="target, index in targets" :key="index">
              <td>{{index + 1}}</td>
              <td>
                <v-select 
                  :filterable="false"
                  @search="debounceSearch"
                  placeholder="Choose One" 
                  v-model="target.customer" 
                  :options="customers"
                  class="form-select-cust-target"
                  label="label"
                  >
                  <template v-if="customers.length >= 10 && (customers.length < totalCustomers)" #list-footer>
                    <button @click="loadMoreCustomers('customers')" class="d-flex py-2" style="opacity: 0.8; margin: auto; background: transparent; border: none">
                    {{ onLoadMore ? 'Please wait...' : 'Click to load more' }}
                    </button>
                  </template>
                </v-select>
              </td>
              <td>
                <select class="form-select for-target w70" v-model="target.grade">
                  <option disabled value="">Choose One</option>
                  <option v-for="grade, index in grades" :key="index" :value="grade">{{grade}}</option>
                </select>
              </td>
              <td>
                <select class="form-select for-target w80" v-model="target.purchase_type">
                  <option disabled value="">Choose One</option>
                  <option v-for="type, index in purchaseTypes" :key="index" :value="type">{{type}}</option>
                </select>
              </td>
              <td>
                <select class="form-select for-target w80" v-model="target.top">
                  <option disabled value="">Choose One</option>
                  <option v-for="top, index in tops" :key="index" :value="top">{{top}}</option>
                </select>
              </td>
              <td>
                <input type="number" @keypress="checkValidationNumber" class="form-control target" placeholder="..." v-model="target.target_order">
              </td>
              <td>
                <input disabled type="number" @keypress="checkValidationNumber" class="form-control target " placeholder="..." :value="target.target_sales">
              </td>
              <td>
                <input type="number" @change="sumTotalSales(index)" @keypress="checkValidationNumber" class="form-control target" placeholder="..." v-model="target.first_week">
              </td>
              <td>
                <input type="number" @change="sumTotalSales(index)" @keypress="checkValidationNumber" class="form-control target" placeholder="..." v-model="target.second_week">
              </td>
              <td>
                <input type="number" @change="sumTotalSales(index)" @keypress="checkValidationNumber" class="form-control target" placeholder="..." v-model="target.third_week">
              </td>
              <td>
                <input type="number" @change="sumTotalSales(index)" @keypress="checkValidationNumber" class="form-control target" placeholder="..." v-model="target.fourth_week">
              </td>
              <td>
                <input type="number" @change="sumTotalSales(index)" @keypress="checkValidationNumber" class="form-control target" placeholder="..." v-model="target.fifth_week">
              </td>
              <td>
                <input type="text" class="form-control target" placeholder="..." v-model="target.remarks">
              </td>
              <td>
                <input type="text" class="form-control target" placeholder="..." v-model="target.outstanding_order">
              </td>
              <td>
                <v-select 
                  :filterable="false"
                  @search="debounceSearch2"
                  placeholder="Choose One" 
                  v-model="target.shipTo" 
                  :options="shipsTo"
                  class="form-select-cust-target"
                  label="label"
                  >
                  <template v-if="shipsTo.length >= 10 && (shipsTo.length < totalShipsTo)" #list-footer>
                    <button @click="loadMoreCustomers" class="d-flex py-2" style="opacity: 0.8; margin: auto; background: transparent; border: none">
                    {{ onLoadMore ? 'Please wait...' : 'Click to load more' }}
                    </button>
                  </template>
                </v-select>
              </td>
              <td>
                <div class="status green">Open</div>
              </td>
              <td>
                <button @click="manageTarget(null, index)" class="btn btn-delete del"><span class="icon-ico-delete"></span></button>
              </td>
            </tr>
          </tbody>
        </table>
        <button @click="manageTarget('add')" class="btn btn-add"><span class="icon-ico-add"></span> Add More</button>
      </div>
      <div v-if="targets.length > 0" class="d-flex justify-content-center p30">
        <button :disabled="disabledBtn" @click.prevent="createTarget" class="btn btn-orange" type="button">Save</button>
      </div>
    </div>
  </main>
</template>

<script>
import moment from 'moment';
import axios from '@/axios';
import {showErrorMessage, truncate, checkValidationNumber} from '@/utils';

export default {
  data() {
    return {
      checkValidationNumber,
      truncate, 
      targets: [],
      customers: [],
      shipsTo: [],
      grades: ["MAS332", "ECO201", "OPI555"],
      purchaseTypes: ["Spot", "Distributor"],
      tops: ["Term14", "CORP", "KULT"],
      previous_targets: {
        data: [],
        total_order: 0,
        total_sales: 0
      },

      current_month: '',
      next_month: '',

      debounce: null,
      debounce2: null,

      totalCustomers: 0,
      totalShipsTo: 0,

      query: '',
      query2: '',
      onLoadMore: false,
      initialPageCust: 2,
      initialPageCust2: 2
    }
  },
  methods: {
    loadMoreCustomers(type){
      this.onLoadMore = true
      const page = type === 'customers' ? this.initialPageCust++ : this.initialPageCust2++
      this.getCustomers(null, page, type)
        .then(() => {
          this.onLoadMore = false
        })
    },
    debounceSearch(query, loading){
      clearTimeout(this.debounce)
      this.customers = []
      this.query = query
      this.initialPageCust = 1
      this.debounce = setTimeout(() => {
        this.getCustomers(loading, this.initialPageCust, 'customers')
      }, 500)
    },
    debounceSearch2(query, loading){
      clearTimeout(this.debounce2)
      this.shipsTo = []
      this.query2 = query
      this.initialPageCust2 = 1
      this.debounce2 = setTimeout(() => {
        this.getCustomers(loading, this.initialPageCust2)
      }, 500)
    },
    getCustomers(loading, page, type) {
      loading && loading(true)
      const query = type === 'customers' ? this.query : this.query2
      return axios.get(`/customers?per_page=10&page=${page}&search=${query}`)
        .then(res => {
          const customers = res.data.data
          type === 'customers' ? this.totalCustomers = customers.total : this.totalShipsTo = customers.total
          loading && loading(false)
          customers.data.map(customer => {
            if(type === 'customers') {
              this.customers.push({
                label: customer.name,
                value: customer.id,
              })
            } else {
              this.shipsTo.push({
                label: customer.name,
                value: customer.id,
              })
            }
          })
      }).catch(err => {
          showErrorMessage(err)
          loading && loading(false)
      }) 
    },


    getFormsData() {
      this.$store.dispatch('toggleLoadingProgress', true)
      let currentMonth = sessionStorage.getItem('month_id')

      const getCustomers = axios.get('/customers?per_page=10&page=1')
      const getPrevTarget = axios.get(`/target-sales?month_id=${currentMonth}`)
      
      Promise.all([getCustomers, getPrevTarget]).then(res => {
        const customers = res[0].data.data
        this.totalCustomers = this.totalShipsTo = customers.total
        customers.data.map(customer => {
          this.customers.push({
            label: customer.name,
            value: customer.id,
          })
          this.shipsTo.push({
            label: customer.name,
            value: customer.id,
          })
        })

        const {data, total_target_order, total_target_sales, is_next_month_has_data} = res[1].data.data

        if(data) {
          data.forEach(item => {
            this.targets.push({
              shipTo: null,
              customer_id: item.customer_id,
              customer: null,
              grade: item.grade,
              purchase_type: item.purchase_type,
              top: item.top,
              target_order: '',
              target_sales: '',
              first_week: '',
              second_week: '',
              third_week: '',
              fourth_week: '',
              fifth_week: '',
              outstanding_order: '',
              remarks: '',
              ship_to_id: item.ship_to_id,
            })
          })
        } else {
          this.targets.push({
            shipTo: null,
            customer_id: '',
            customer: null,
            grade: '',
            purchase_type: '',
            top: '',
            target_order: '',
            target_sales: '',
            first_week: '',
            second_week: '',
            third_week: '',
            fourth_week: '',
            fifth_week: '',
            outstanding_order: '',
            remarks: '',
            ship_to_id: '',
          })
        }

        this.current_month = data ? moment(this.currentMonthIndex).format('MMMM YYYY') : moment(this.currentMonthIndex).subtract(1, 'months').format('MMMM YYYY')
        this.next_month = data ? moment(this.currentMonthIndex).add(1, 'months').format('MMMM YYYY') : moment(this.currentMonthIndex).format('MMMM YYYY')

        if(is_next_month_has_data) this.$router.push({name: 'Target'})

        this.$store.dispatch('toggleLoadingProgress', false)

        this.previous_targets.data = data || []
        this.previous_targets.total_order = total_target_order
        this.previous_targets.total_sales = total_target_sales
        
      }).catch(error => {
        showErrorMessage(error)
      })
    },
    manageTarget(type, index) {
      if(type == 'add') {
        this.targets.push({
            shipTo: null,
            customer_id: '',
            customer: null,
            grade: '',
            purchase_type: '',
            top: '',
            target_order: '',
            target_sales: '',
            first_week: '',
            second_week: '',
            third_week: '',
            fourth_week: '',
            fifth_week: '',
            outstanding_order: '',
            remarks: '',
            ship_to_id: '',
        })
      } else {
        this.targets.splice(index, 1)
      }
    },
    createTarget() {
      this.$store.dispatch('toggleLoadingProgress', true)

      const targets = JSON.parse(JSON.stringify(this.targets))
      targets.forEach(target => {
        let weeks = ["first_week", "second_week", "third_week", "fourth_week", "fifth_week"]
        weeks.forEach(week => {
          if(target[week] == '') {
            target[week] = 0
          }
        })
        if(target.target_sales == '') target.target_sales = 0
        target.customer_id = target.customer.value
        target.ship_to_id = target.shipTo.value
        delete target.customer
        delete target.shipTo
      })
      let data = {
        month_id: moment(this.next_month).format('YYYYMM'),
        data: targets
      }
      axios.post('/target-sales/bulk', data)
        .then(() => {
          this.$store.dispatch('toggleLoadingProgress', false)
          sessionStorage.setItem('month_id', data.month_id)
          this.$notify({ type: 'success', title: 'Success', text: 'Success create target' })
          this.$router.push({name: 'Target'})
        }).catch(error => {
          showErrorMessage(error)
        })
    },
    noTargetsValueNull() {
      let targetValue = []
      this.targets.forEach(target => {
         let i = Boolean(
               target.customer === null ||
               target.shipTo === null ||
               target.grade === '' ||
               target.purchase_type === '' ||
               target.top === '' ||
               target.target_order === '' ||
               target.outstanding_order === '')
          targetValue.push(i)
      })
      return targetValue.includes(true)
    },
    sumTotalSales(index) {
      const target = this.targets[index]

      let weeks = ["first_week", "second_week", "third_week", "fourth_week", "fifth_week"]
      weeks.forEach(week => {
        if(target[week] !== '') {
          let newVal = parseInt(target[week], 10)
          target[week] = newVal
        }
      })

      target.target_sales = +target.first_week + +target.second_week + +target.third_week + +target.fourth_week + +target.fifth_week
    }
  },
  mounted() {
    this.getFormsData()
  },
  computed: {
    currentMonthIndex() {
      return sessionStorage.getItem('month_id')
    },
    currentMonth() {
      return this.current_month
    },
    nextMonth() {
      return this.next_month
    },
    disabledBtn() {
      return this.noTargetsValueNull()
    },
  }
}
</script>

<style>

</style>