<template>
  <main>
    <div v-if="latest_update" class="italic text-dark mb15 mt-3">Latest update by: {{data.latest_update_name}} ({{data.latest_update_date.substr(0, 10)}})</div>
    <div :class="{'mt-3': !latest_update}" class="white-box mb30 no-mh">
      <div class="header-toolbar">
          <div class="left-side big">
            <div class="row align-items-center">
              <!-- <div class="col-xl-4 col-sm-12 col-12">
                <div class="row align-items-center">
                  <div class="col-sm-3 col-lg-4 col-3">
                    <div class="grey mm15 mb-sm"> Entries</div>
                  </div>
                  <div class="col-sm-9 col-lg-8 col-9">
                    <select class="form-select mm15 mb-sm" aria-label="Default select">
                      <option selected>10</option>
                      <option value="1">20</option>
                      <option value="2">40</option>
                      <option value="3">100</option>
                    </select>
                  </div>
                </div>
              </div> -->
              <div v-if="enabledAdd" class="col-xl-4 col-sm-6">
                <a @click.prevent="addTarget" href="#" class="btn btn-orange w-100 mm15">Add New</a>
              </div>
              <div v-if="disabledEdit" class="col-xl-4 col-sm-6">
                <a @click.prevent="editTarget" href="#" class="btn btn-dark-blue w-100 mm15">Edit Target</a>
              </div>
            </div>
          </div>
          <div class="right-side sm">
            <div class="row">
              <div class="col-xl-7 col-sm-12">
                <select class="form-select mm15 mb-sm" v-model="filter.month">
                  <option v-for="month, index in months" :key="index" :value="month">{{month}}</option>
                </select>
              </div>
              <div class="col-xl-5 col-sm-12">
                <select class="form-select" v-model="filter.year">
                  <option v-for="year, index in years" :key="index" :value="year">{{year}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>No</th>
              <th>Customer</th>
              <th>Grade</th>
              <th>Purchase Type</th>
              <th>TOP</th>
              <th>Target Order</th>
              <th>Target Sales</th>
              <th>Remarks</th>
              <th>Outstanding Order</th>
              <th>Ship To</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody v-if="data.targets.length > 0">
            <tr v-for="target,index in data.targets" :key="index">
              <td>{{index + 1}}</td>
              <td v-if="target.customer">{{target.customer.name}}</td>
              <td>{{target.grade}}</td>
              <td>{{target.purchase_type}}</td>
              <td>{{target.top}}</td>
              <td>{{target.target_order}}</td>
              <td>{{target.target_sales}}</td>
              <td>
                {{truncate(target.remarks,7)}}
              </td>
              <td>
                {{truncate(target.outstanding_order,10)}}
              </td>
              <td>{{target.ship_to.name}}</td>
              <td>
                <div v-if="target.status === 'Open'" class="status green">Open</div>
                <div v-else class="status grey">Close</div>
              </td>
              <td>
                <button @click.prevent="showModalAction('target', target.id)" class="btn btn-see rbm"><span class="icon-ico-eye"></span></button>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="11" class="text-center">No data found</td>
            </tr>
          </tbody>
          <tfoot v-if="data.targets.length > 0">
            <tr>
              <td colspan="5">
                <div class="font-bold">TOTAL</div>
              </td>
              <td>
                <div class="font-bold">{{data.total_target_order}}</div>
              </td>
              <td colspan="5">
                <div class="font-bold green">{{data.total_target_sales}}</div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="white-box mb40 no-mh">
      <div class="p20">
        <div class="d-flex align-items-center">
          <div class="font-500">Quantity Adjustment</div>
          <button v-if="disabledAddQuantity" @click.prevent="showModalAction('addqa')" class="btn btn-orange qa mx-3">Add New</button>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>No</th>
              <th>Quantity</th>
              <th width="70%" class="fd">Notes</th>
              <th>Month</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody v-if="data.quantities.length > 0">
            <tr v-for="quantity, index in data.quantities" :key="index">
              <td>{{index + 1}}</td>
              <td>{{quantity.quantity}}</td>
              <td>{{quantity.notes}}</td>
              <td>{{currentMonth}}</td>
              <td>
                <a v-if="disabledEditQuantity" @click.prevent="showModalAction('editqa', quantity.id)" href="#" class="link-edit eqa"><span class="icon-ico-pencil"></span></a>
                <button @click.prevent="showModalAction('qa', quantity.id)" class="btn btn-see rbe"><span class="icon-ico-eye"></span></button>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="5" class="text-center">No data found</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="overlay" :class="{'overlayshow': showModal}"></div>
    <!--Popup-->
    <!-- Detail Target Modal -->
    <div v-if="detailTarget" id="rbm-content" class="popup-wrapper" :class="{'showing': targetModalShow}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center">
          <div class="font-bold">Detail Target</div>
          <button @click.prevent="showModalAction('target')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="inner-land">
          <div class="inner-scroll">
            <div class="popup-item wb">
              <div class="row">
                <div class="col-sm-6 col-6">
                  <div class="grey">Customer</div>
                  <span>{{detailTarget.customer.name}}</span>
                </div>
                <div class="col-sm-6 col-6">
                  <div class="grey">Grade</div>
                  <span>{{detailTarget.grade}}</span>
                </div>
              </div>
            </div>
            <div class="popup-item wb">
              <div class="row">
                <div class="col-sm-6 col-6">
                  <div class="grey">Purchase Type</div>
                  <span>{{detailTarget.purchase_type}}</span>
                </div>
                <div class="col-sm-6 col-6">
                  <div class="grey">TOP</div>
                  <span>{{detailTarget.top}}</span>
                </div>
              </div>
            </div>
            <div class="popup-item wb">
              <div class="row">
                <div class="col-sm-6 col-6">
                  <div class="grey">Target Order</div>
                  <span>{{detailTarget.target_order}}</span>
                </div>
                <div class="col-sm-6 col-6">
                  <div class="grey">Target Sales</div>
                  <span>{{detailTarget.target_sales}}</span>
                </div>
              </div>
            </div>
            <div class="popup-item wb">
              <div class="row">
                <div class="col-sm-7">
                  <div class="row">
                    <div class="col-4">
                      <div class="grey">Target W1</div>
                      <span>{{detailTarget.first_week || '-'}}</span>
                    </div>
                    <div class="col-4">
                      <div class="grey">Target W2</div>
                      <span>{{detailTarget.second_week || '-'}}</span>
                    </div>
                    <div class="col-4">
                      <div class="grey">Target W3</div>
                      <span>{{detailTarget.third_week || '-'}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-5">
                  <div class="row">
                    <div class="col-6">
                      <div class="grey">Target W4</div>
                      <span>{{detailTarget.fourth_week || '-'}}</span>
                    </div>
                    <div class="col-6">
                      <div class="grey">Target W5</div>
                      <span>{{detailTarget.fifth_week || '-'}}</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="popup-item wb">
              <div class="row">
                <div class="col-sm-6 col-6">
                  <div class="grey">Remarks</div>
                  <span>{{detailTarget.remarks || '-'}}</span>
                </div>
                <div class="col-sm-6 col-6">
                  <div class="grey">Outstanding Order</div>
                  <span>{{detailTarget.outstanding_order}}</span>
                </div>
              </div>
            </div>
            <div class="popup-item">
              <div class="row">
                <div class="col-sm-6">
                  <div class="grey">Ship to</div>
                  <span>{{detailTarget.ship_to.name}}</span>
                </div>
                <div class="col-sm-6">
                  <div class="grey">Status</div>
                  <div v-if="detailTarget.status === 'Open'" class="status green">Open</div>
                  <div v-else class="status grey">Close</div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- Detail QA Modal -->
    <div v-if="detailQuantity" id="rbe-content" class="popup-wrapper" :class="{'showing': qaModalShow}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center">
          <div class="font-bold">Detail Quantity Adjustment</div>
          <button @click.prevent="showModalAction('qa')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="inner-land">
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6">
                <div class="grey">Quantity</div>
                <span>{{detailQuantity.quantity}}</span>
              </div>
              <div class="col-sm-6">
                <div class="grey">Month</div>
                <span>{{currentMonth}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Notes</div>
                <span v-html="detailQuantity.notes"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add QA Modal -->
    <div id="qa-content" class="popup-wrapper" :class="{'showing': addQaModalShow}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center mb24">
          <div class="font-bold">Add New Quantity Adjustment</div>
          <button @click.prevent="showModalAction('addqa')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="inner-land">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey">Quantity</div>
                <input @keypress="RestrictFirstZero" type="number" class="form-control" v-model="quantity.quantity">
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey">Month</div>
                <input type="text" class="form-control" :value="currentMonth" disabled>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <div class="grey">Notes</div>
                <textarea class="form-control" rows="6" v-model="quantity.notes"></textarea>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button @click.prevent="resetQuantity" class="btn btn-border-red mx-2" type="button">Cancel</button>
            <button :disabled="disabledQuantity" @click.prevent="addQuantity" class="btn btn-orange mx-2" type="button">Save</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit QA Modal -->
    <div id="eqa-content" class="popup-wrapper" :class="{'showing': editQaModalShow}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center mb24">
          <div class="font-bold">Edit New Quantity Adjustment</div>
          <button @click.prevent="showModalAction('editqa')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="inner-land">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey">Quantity</div>
                <input @keypress="RestrictFirstZero" type="number" class="form-control" v-model="quantity.quantity">
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey">Date</div>
                <input type="text" class="form-control" :value="currentMonth" disabled>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <div class="grey">Notes</div>
                <textarea class="form-control"
                  rows="6" v-model="quantity.notes"></textarea>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button :disabled="disabledQuantity" v-if="quantity.id" @click="editQuantity(quantity.id)" class="btn btn-orange mx-2" type="button">Save</button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from 'moment';
import axios from '@/axios';
import {showErrorMessage, truncate, getPastYears} from '@/utils';

export default {
  data() {
    return {
      truncate,
      moment,
      // Modal Data
      targetModalShow: false,
      qaModalShow: false,
      addQaModalShow: false,
      editQaModalShow: false,
      showModal: false,

      data: {
        total_target_order: null,
        total_target_sales: null,

        latest_update_name: null,
        latest_update_date: null,
        targets: [],
        detailTarget: null,

        quantities: [],
        detailQuantity: null
      },
      quantity: {
        quantity: "",
        notes: ""
      },
      filter: {
        month: moment().format('MMMM'),
        year: moment().format('YYYY')
      },
      months: [],
      years: [],
      next_month_has_target: false,
      statusTarget: '',
    }
  },
  methods: {
    showModalAction(type, id) {
      if(type == 'target') {
        if(this.targetModalShow === false){
          this.getDetailTarget(id, 'target')
        }
        this.targetModalShow = !this.targetModalShow
      } else if (type == 'qa') {
        if(this.qaModalShow === false){
          this.getDetailTarget(id)
        }
        this.qaModalShow = !this.qaModalShow
      } else if (type == 'addqa') {
        this.addQaModalShow = !this.addQaModalShow
        this.quantity = {
          quantity: "",
          notes: ""
        }
      } else if (type == 'editqa') {
        if(this.editQaModalShow === false){
          this.getDetailTarget(id, 'editqa')
        }
        this.editQaModalShow = !this.editQaModalShow
      }
      this.showModal = !this.showModal
    },
    addTarget() {
      const id = this.getCurrentMonthIndex()
      sessionStorage.setItem('month_id', id)
      this.$router.push({
        name: 'AddTarget',
        params: {
          id
        }
      })
    },
    editTarget(){
      const id = this.getCurrentMonthIndex()
      sessionStorage.setItem('month_id', id)
      this.$router.push({
        name: 'EditTarget',
        params: {
          id
        }
      })
    },
    getMonthsList() {
      this.months = []
      let allMonths = moment.months()
      let months = []
      if(this.filter.year == moment().format('YYYY')){
        for (let month of allMonths) {
          months.push(month)
          if (month.toString() == moment().format('MMMM').toString()) break;
        }
      } else {
        months = allMonths
      }

      if(!months.includes(this.filter.month)){
        this.filter.month = months[months.length - 1]
      }
      
      this.months = months
    },
    getCurrentMonthIndex() {
      let indexMonth = this.months.indexOf(this.filter.month) + 1
      if(indexMonth.toString().length === 1) {
        indexMonth = `0${indexMonth}`
      }
      return `${this.filter.year}${indexMonth}`
    },
    getDataTarget(){
      this.$store.dispatch('toggleLoadingProgress', true)
      let currentMonth = this.getCurrentMonthIndex()
      const getTarget = axios.get(`/target-sales?month_id=${currentMonth}`)
      const getQuantity = axios.get(`/quantity-adjustments?month_id=${currentMonth}`)

      Promise.all([getTarget, getQuantity]).then(res => {
        this.$store.dispatch('toggleLoadingProgress', false)
        let {data, is_next_month_has_data, total_target_order, total_target_sales, status, last_updated_at, last_updated_by} = res[0].data.data
        let quantities = res[1].data.data

        this.data.targets = data || []
        this.data.total_target_order = total_target_order
        this.data.total_target_sales = parseInt(total_target_sales) + parseInt(quantities.total_qty)
        this.statusTarget = status
        this.next_month_has_target = is_next_month_has_data

        if(quantities.data && last_updated_at !== '') {
          let condition = last_updated_at > quantities.last_updated_at
          this.data.latest_update_name = condition ? last_updated_by : quantities.last_updated_by
          this.data.latest_update_date = condition ? last_updated_at : quantities.last_updated_at
        } else {
          this.data.latest_update_name = last_updated_at !== '' ? last_updated_by : quantities.data ? quantities.last_updated_by : null
          this.data.latest_update_date = last_updated_at !== '' ? last_updated_at : quantities.data ? quantities.last_updated_at : null
        }

        if(this.filter.month == moment().format('MMMM') && is_next_month_has_data) {
          if(!this.months.includes(moment().add(1, 'month').format('MMMM'))){
            this.months.push(moment().add(1, 'month').format('MMMM'))
          }
        }

        this.data.quantities = []
        if(quantities.data) {
          quantities.data.forEach(quantity => this.data.quantities.unshift(quantity))
        }
      }).catch(error => {
        showErrorMessage(error)
      })
    },
    RestrictFirstZero(e) {
      if (e.srcElement.value.length == 0 && e.which == 48) {
          e.preventDefault();
          return false;
      }
    },
    addQuantity() {
      if(!this.disabledQuantity){
        this.$store.dispatch('toggleLoadingProgress', true)
        let currentMonth = this.getCurrentMonthIndex()
        this.quantity.month_id = currentMonth
        axios.post('/quantity-adjustments', this.quantity)
          .then(() => {
            this.quantity = {
              quantity: "",
              notes: ""
            }
            this.addQaModalShow = false
            this.showModal = false
            this.getDataTarget()
          }).catch(error => {
            showErrorMessage(error)
          })
      }
    },
    editQuantity(id) {
      if(!this.disabledQuantity){
        this.$store.dispatch('toggleLoadingProgress', true)
        let data = {
          quantity: this.quantity.quantity,
          notes: this.quantity.notes,
        }
        axios.put(`/quantity-adjustments/${id}`, data)
          .then(() => {
            this.quantity = {
              quantity: "",
              notes: ""
            }
            this.editQaModalShow = false
            this.showModal = false
            this.getDataTarget()
          }).catch(error => {
            showErrorMessage(error)
          })
      }
    },
    resetQuantity() {
      this.quantity = {
        quantity: '',
        notes: ''
      }
      this.showModal = false
      this.addQaModalShow = false
    },
    getDetailTarget(id, type) {
      if(type === 'target') {
        let targets = this.data.targets.filter(target => target.id === id)
        this.data.detailTarget = targets[0]
      } else {
        let quantity = this.data.quantities.filter(quantity => quantity.id === id)
        if(type !== 'editqa') {
          this.data.detailQuantity = Object.assign({}, quantity[0])
          this.data.detailQuantity.notes = this.data.detailQuantity.notes.replaceAll("\n", "<br/>")
        } else {
          this.quantity = Object.assign({}, quantity[0])
        }
      }
    },

  },
  mounted() {
    this.years = getPastYears(moment().year(), 5)
    this.getMonthsList()
    if(sessionStorage.getItem('month_id')){
      const month_id = sessionStorage.getItem('month_id')
      this.filter.month = moment(month_id.substr(4,6)).format('MMMM')
      this.getDataTarget()
    } else {
      this.getDataTarget()
    }
  },
  computed: {
    AppActiveUser()     { return this.$store.state.AppActiveUser },
    detailTarget(){
      return this.data.detailTarget
    },
    detailQuantity(){
      return this.data.detailQuantity
    },
    month() {
      return this.filter.month
    },
    year() {
      return this.filter.year
    },
    currentMonth() {
      return `${this.filter.month} ${this.filter.year}`
    },
    latest_update() {
      return this.data.latest_update_name && this.data.latest_update_date
    },
    disabledQuantity(){
      return this.quantity.quantity == '' || this.quantity.notes == ''
    },
    disabledAddQuantity(){
      return this.statusTarget == 'Open' && this.AppActiveUser.permissions.includes('Create Quantity Adjustment')
    },
    disabledEditQuantity(){
      return this.statusTarget == 'Open' && this.AppActiveUser.permissions.includes('Edit Quantity Adjustment')
    },
    disabledEdit(){
      return this.statusTarget == 'Open' && (this.AppActiveUser.permissions.includes('Edit Target') || this.AppActiveUser.permissions.includes('Delete Target') || this.AppActiveUser.permissions.includes('Close Target'))
    },
    enabledAdd() {
      return ((this.statusTarget == 'Closed' && !this.next_month_has_target) || (this.data.targets.length == 0 && moment(`${this.filter.month}${this.filter.year}`).isBefore(moment().format()) && !this.next_month_has_target) && this.AppActiveUser.permissions.includes('Create Target')) && (`${this.filter.month}${this.filter.year}` !== moment().format('MMMMYYYY') || this.data.targets > 0 || this.statusTarget == 'Closed')
    }
  },
  watch: {
    month(){
      this.getDataTarget()
    },
    year(){
      this.getMonthsList()
      this.getDataTarget()
    },
  }
}
</script>

<style>

</style>