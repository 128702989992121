<template>
  <main>
    <div class="link-back">Change Password</div>
    <div class="white-box p30">
      <div class="row">
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Old Password</div>
            <div class="form-group">
              <input v-validate="'required|min:8'" v-model="data.current_password" :type="type.current_password" name="Old Password" class="form-control" id="password-field1">
              <div class="for-btn-show">
                <button @click.prevent="togglePassword('current_password')" toggle="#password-field1" class="btn btn-show"></button>
              </div>
              <span class="text-danger text-sm error-message" v-show="errors.has('Old Password')">{{ errors.first('Old Password') }}</span>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6"></div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">New Password</div>
            <div class="form-group">
              <input v-validate="'required|min:8'" v-model="data.password" :type="type.password" name="New Password" class="form-control" id="password-field2" ref="password">
              <div class="for-btn-show">
                <button @click.prevent="togglePassword('password')" toggle="#password-field2" class="btn btn-show"></button>
              </div>
              <span class="text-danger text-sm error-message" v-show="errors.has('New Password')">{{ errors.first('New Password') }}</span>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Confirm New Password</div>
            <div class="form-group">
              <input v-validate="'required|min:8|confirmed:password'" v-model="data.password_confirmation" :type="type.password_confirmation" name="Password Confirmation" class="form-control" id="password-field3" data-vv-as="password">
              <div class="for-btn-show">
                <button @click.prevent="togglePassword('password_confirmation')" toggle="#password-field3" class="btn btn-show"></button>
              </div>
              <span class="text-danger text-sm error-message" v-show="errors.has('Password Confirmation')">{{ errors.first('Password Confirmation') }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button @click.prevent="changePassword" class="btn btn-orange" type="button" :class="{'disabled': disabledBtn}" :disabled="disabledBtn">Save</button>
      </div>
    </div>
  </main>
</template>

<script>
import axios from '@/axios'
export default {
  data() {
    return {
      data: {
        current_password: '',
        password: '',
        password_confirmation: ''
      },
      type: {
        current_password: 'password',
        password: 'password',
        password_confirmation: 'password'
      }
    }
  },
  methods: {
    togglePassword(form_type) {
      if(this.type[form_type] === 'password') {
          this.type[form_type] = 'text'
       } else {
          this.type[form_type] = 'password'
       }
    },
    changePassword() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.$store.dispatch('toggleLoadingProgress', true)
          axios.post('/change-password', this.data)
            .then(() => {
              this.$store.dispatch('toggleLoadingProgress', false)
              this.$router.push({name: 'Dashboard'})
              this.$notify({ type: 'success', title: 'Success', text: 'Success change password' })
            })
            .catch(() => {
              this.$store.dispatch('toggleLoadingProgress', false)
              this.$notify({ type: 'error', title: 'Error', text: `Old password is incorrect` })
            })
        }
      });
        
    }
  }, 
  computed: {
    disabledBtn() {
      return this.data.current_password === '' || this.data.password === '' || this.data.password_confirmation === ''
    }
  }
}
</script>

<style>

</style>