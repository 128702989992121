import Vue from 'vue';
import store from '../store';


const checkValidationNumber = (event) => {
  if (event.which < 48 || event.which > 57){
    event.preventDefault();
  }
}

const removeSpesificChar = (str, char) => {
  if(str) {
    return str.replace(char, '')
  } else {
    return ''
  }
}

const truncate = (str, n) => {
  return str ? str.length > n ? str.substring(0, n - 1) + "..." : str : '-' 
};

const getPastYears = (year, range) => {
  // set older year range
  let min = year - range

  // set newer year range
  // let max = year + range

  let years = []
  for (var i = year; i >= min; i--) {
    years.push(i)
  }
  // for (var j = year; j <= max; j++) {
  //   years.push(j)
  // }
  // years = [...new Set(years)].sort()

  return years
}

const showErrorMessage = error => {
  if(error && error.response) {
    if(error.response.status == 422) {
      let errors = []
      for (const property in error.response.data.errors) {
        error.response.data.errors[property].map(error => {
          errors.push(error)
        })
      }
      let listError = ""
      errors.map(error => {
        listError += `<li>${error}</li>`  
      })
      Vue.notify({ type: 'error', title: 'Errors', text: `<ul>${listError}</ul>` })
    } else {
      Vue.notify({ type: 'error', title: 'Errors', text: error.response.data.message })
    }
    store.dispatch('toggleLoadingProgress', false)
  }
}

const ifBothArrayIsEqual = (array1, array2) => {
  return JSON.stringify(array1) === JSON.stringify(array2);
}

export {
  ifBothArrayIsEqual,
  removeSpesificChar,
  checkValidationNumber,
  truncate,
  showErrorMessage,
  getPastYears
}