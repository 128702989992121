<template>
  <main>
    <a @click.prevent="$router.go(-1)" href="#" class="link-back"><span class="icon-ico-arrow-left"></span> Edit Label</a>
    <div class="white-box p30">
      <div class="row">
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Label Name</div>
            <input type="text" class="form-control" v-model="label.name">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Color</div>
            <div class="box-pick">
              <div class="color-picker showed">
                <label for="colorPicker" class="d-flex align-items-center text-uppercase grey">
                  <input type="color" id="colorPicker" v-model="label.color" ref="picker">
                  <span>{{label.color}}</span>
                </label>
              </div>
              <div>
                <button @click="$refs.picker.click()" class="btn btn-for-pick">Select color</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button v-if="hideDeleteBtn" @click.prevent="deleteLabel(label.id)" class="btn btn-border-red mx-2" type="button">Delete</button>
        <button v-if="hideEditBtn" @click.prevent="updateLabel(label.id)" class="btn btn-orange mx-2" type="button" :disabled="disabledBtn">Update</button>
      </div>
    </div>
  </main>
</template>

<script>
import axios from '@/axios'
import {showErrorMessage} from '@/utils';

export default {
  data() {
    return {
      color: '#000000',
      label: {}
    }
  },
  computed: {
    currentColor() {
      return this.color
    },
    disabledBtn() {
      return this.label.name === ''
    },
    AppActiveUser() { return this.$store.state.AppActiveUser },
    hideDeleteBtn() {
      return this.AppActiveUser.permissions.includes('Delete Label Customer')
    },
    hideEditBtn() {
      return this.AppActiveUser.permissions.includes('Edit Label Customer')
    },
  },
  mounted() {
    this.getDetailLabel()
  },
  methods: {
    getDetailLabel(){
      this.$store.dispatch('toggleLoadingProgress', true)
      
      axios.get(`/labels/${this.$route.params.id}`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)
          const {data} = res.data
          this.label = data
        })
        .catch(err => {
          showErrorMessage(err)
        })
    },
    updateLabel(id){
      if(!this.disabledBtn){
        this.$store.dispatch('toggleLoadingProgress', true)
  
        axios.put(`/labels/${id}`, {
          name: this.label.name,
          color: this.label.color
        }).then(() => {
          this.$store.dispatch('toggleLoadingProgress', false)
          this.$notify({ type: 'success', title: 'Success', text: 'Success edit label' })
          this.$router.push({
            name: 'LabelManagement'
          })
        }).catch(error => {
          showErrorMessage(error)
        })
      }
    },
    deleteLabel(id){
      if(confirm('Are you sure you want to delete this data?')){
        this.$store.dispatch('toggleLoadingProgress', true)

        axios.delete(`/labels/${id}`)
          .then(() => {
            this.$store.dispatch('toggleLoadingProgress', false)
            this.$notify({ type: 'success', title: 'Success', text: 'Success delete label' })
            this.$router.push({
              name: 'LabelManagement'
            })
          })
          .catch(error => {
            this.$store.dispatch('toggleLoadingProgress', false)
            this.$notify({ type: 'error', title: 'Error', text: error.response.data.message })
          })
      }
    }
  }
}
</script>

<style>

</style>