<template>
  <main>
    <a @click.prevent="$router.go(-1)" href="#" class="link-back"><span class="icon-ico-arrow-left"></span> Add Technical</a>
    <div class="white-box p30">
      <div class="row">
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Address ID</div>
            <input type="text" class="form-control" :value="$route.params.idAddress" disabled>
          </div>
        </div>
        <div class="font-bold mb24">Product</div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Brand name </div>
            <input type="text" class="form-control" v-model="product.brand_name">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6"></div>
        <div class="col-xl-12 col-sm-12">
          <div class="row">
            <div class="col-sm-3 col-xl-2 col-12">
              <div class="form-group">
                <div class="grey mb5">Thickness (Micron)</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="product.thickness">
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Roll/CUT</div>
                <input type="text" class="form-control" v-model="product.roll_cut">
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Film width (cm)</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="product.film_width">
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Cup weight (Gr)</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="product.cup_weight">
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Volume (MI)</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="product.volume">
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Denier</div>
                <input type="text" class="form-control" v-model="product.denier">
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Top load (Kg)</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="product.top_load">
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Tenacity (gpd)</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="product.tenacity">
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Drop test</div>
                <input type="text" class="form-control" v-model="product.drop_test">
              </div>
            </div>
            <div class="col-sm-3 col-xl-2 col-6">
              <div class="form-group">
                <div class="grey mb5">Elongation %</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="product.elongation">
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="separator dashed"></div>
        </div>
        <div class="font-bold mb24">Extruder</div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Type </div>
            <input type="text" class="form-control" v-model="extruder.type">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6"></div>
        <div class="col-xl-12 col-sm-12">
          <div class="row">
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Amount</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="extruder.amount">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Manufactured year</div>
                <input type="number" class="form-control" v-model="extruder.manufactured_year">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Line speed (M/Min)</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="extruder.line_speed">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">L/D Ratio</div>
                <input  type="number" min="0" step="0.01" class="form-control" v-model="extruder.l_d_ratio">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-12">
              <div class="form-group">
                <div class="grey mb5">Temp. Setting (°C)</div>
                <input  type="number" min="0" step="0.01" class="form-control" v-model="extruder.temperature_setting">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-12">
              <div class="form-group">
                <div class="grey mb5">Chilled Roll Temp. (°C)</div>
                <input  type="number" min="0" step="0.01" class="form-control" v-model="extruder.chilled_roll_temperature">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Calpet conc (%)</div>
                <input  type="number" min="0" step="0.01" class="form-control" v-model="extruder.calpet_conc">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6 none-mm"></div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Streching ratio</div>
                <input  type="number" min="0" step="0.01" class="form-control" v-model="extruder.stretching_ratio">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-12">
              <div class="form-group">
                <div class="grey mb5">Cons. Kg/Hour</div>
                <input  type="number" min="0" step="0.01" class="form-control" v-model="extruder.cons">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-12">
              <div class="form-group">
                <div class="grey mb5">Water Bath Temp (°C)</div>
                <input  type="number" min="0" step="0.01" class="form-control" v-model="extruder.water_bath_temperature">
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="separator dashed"></div>
        </div>
        <div class="font-bold mb24">Weaving</div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Type </div>
            <input type="text" class="form-control" v-model="weaving.type">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6"></div>
        <div class="col-sm-3 col-xl-2 col-12">
          <div class="form-group">
            <div class="grey mb5">MNF year</div>
            <input type="number" class="form-control" v-model="weaving.manufactured_year">
          </div>
        </div>
        <div class="col-sm-3 col-xl-2 col-6">
          <div class="form-group">
            <div class="grey mb5">Quantity</div>
            <input  type="number" min="0" step="0.01" class="form-control" v-model="weaving.quantity">
          </div>
        </div>
        <div class="col-sm-3 col-xl-2 col-6">
          <div class="form-group">
            <div class="grey mb5">Speed (Rpm)</div>
            <input  type="number" min="0" step="0.01" class="form-control" v-model="weaving.speed">
          </div>
        </div>
        <div class="col-xl-12">
          <div class="separator dashed"></div>
        </div>
        <div class="col-xl-12 col-sm-12">
          <div class="row">
            <div class="col-sm-6 col-xl-6 col-6">
              <div class="form-group">
                <div class="grey mb5">Application</div>
                <input type="text" class="form-control" v-model="weaving.app_name">
              </div>
            </div>
            <div class="col-sm-6 col-xl-6 col-6">
              <div class="form-group">
                <div class="grey mb5">Type</div>
                <input type="text" class="form-control" v-model="weaving.app_type">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Amount</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="weaving.app_amount">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Manufactured year</div>
                <input type="number" class="form-control" v-model="weaving.app_manufactured_year">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-12">
              <div class="form-group">
                <div class="grey mb5">Number of cavity</div>
                <input  type="number" min="0" step="0.01" class="form-control" v-model="weaving.app_number_of_cavity">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-12">
              <div class="form-group">
                <div class="grey mb5">Heater tempt (°C)</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="weaving.app_heater_temperature">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Tot production</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="weaving.app_total_production">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-6">
              <div class="form-group">
                <div class="grey mb5">Cycle time</div>
                <input type="number" min="0" step="0.01" class="form-control" v-model="weaving.app_cycle_time">
              </div>
            </div>
            <div class="col-sm-3 col-xl-3 col-12">
              <div class="form-group">
                <div class="row mt40">
                  <div class="col-sm-6">
                    <div class="form-check">
                      <input class="form-check-input" name="radio-com" type="radio" id="radio1" value="Offline" v-model="weaving.app_status">
                      <label class="form-check-label" for="radio1">
                        Offline
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-check">
                      <input class="form-check-input" name="radio-com" type="radio" id="radio2" value="Online" v-model="weaving.app_status">
                      <label class="form-check-label" for="radio2">
                        Online
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button @click.prevent="addTechnical" class="btn btn-orange" type="button">Save</button>
      </div>
    </div>
  </main>
</template>

<script>
import axios from '@/axios'
import {showErrorMessage} from '@/utils';

export default {
  data() {
    return {
      product: {
        brand_name: "",
        thickness: "",
        roll_cut: "",
        film_width: "",
        cup_weight: "",
        volume: "",
        denier: "",
        top_load: "",
        tenacity: "",
        drop_test: "",
        elongation: ""
      },
      extruder: {
        type: "",
        amount: "",
        manufactured_year: "",
        line_speed: "",
        l_d_ratio: "",
        temperature_setting: "",
        chilled_roll_temperature: "",
        calpet_conc: "",
        stretching_ratio: "",
        cons: "",
        water_bath_temperature: "",
      },
      weaving: {
        type: "",
        manufactured_year: "",
        quantity: "",
        speed: "",
        app_name: "",
        app_type: "",
        app_manufactured_year: "",
        app_number_of_cavity: "",
        app_heater_temperature: "",
        app_total_production: "",
        app_cycle_time: "",
        app_status: "Offline",
        app_amount: ""
      } 
    }
  },
  methods: {
    addTechnical(){
      this.$store.dispatch('toggleLoadingProgress', true)
      const data = {
        product: this.product,
        extruder: this.extruder,
        weaving: this.weaving
      }

      for (var keyHead of Object.keys(data)) {
        let head = data[keyHead]
        for (var key of Object.keys(head)) {
          if(head[key] === ''){
            data[keyHead][key] = "-"
          } 
        }
      }

      axios.post(`/customers/${this.$route.params.id}/addresses/${this.$route.params.idAddress}/technicals`, data)
          .then(() => {
            this.$store.dispatch('toggleLoadingProgress', false)
            this.$notify({ type: 'success', text: 'Success add technical' }) 
            this.$router.push({path: `/customer/${this.$route.params.id}/address/${this.$route.params.idAddress}/technical`})
          }).catch(error => {
            showErrorMessage(error)
          })
    }
  }
}
</script>

<style>

</style>