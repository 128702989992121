<template>
  <main class="bg-login">
    <div class="wrapper-login">
      <div class="login-inner">
        <div class="for-logo-login mb50">
          <div class="icon-logo-login"><img src="../assets/img/logo.png" class="img-fluid"></div>
          <div class="logo-text">PT POLYTAMA PROPINDO</div>
        </div>
        <form @submit.prevent="login">
          <div class="form-group">
            <div class="label-login text-black">NIK</div>
            <input v-model="data.nik" type="text" class="form-control login">
          </div>
          <div class="form-group">
            <div class="mb5 text-black">Password</div>
            <input v-model="data.password" :type="inputTypePassword" class="form-control">
            <div class="for-btn-show">
              <button @click.prevent="togglePassword" type="button" class="btn btn-show"></button>
            </div>
          </div>
          <div class="form-group">
            <button type="submit" @click.prevent="login" class="btn btn-orange w-100" :disabled="disabledBtn">Log in</button>
          </div>
        </form>
        <p class="text-center text-login font-500">If you don't have an account, you can contact the admin</p>
      </div>
    </div>
  </main>
</template>

<script>
import $axios from 'axios'
import axios from '@/axios'

const base_url = process.env.VUE_APP_BASE_URL_API.replace('/api', '')
export default {
  data() {
    return {
      data: {
        nik: '',
        password: '',
      },
      inputTypePassword: 'password'
    }
  },
  methods: {
    login(){
      if(!this.disabledBtn){
        this.$store.dispatch('toggleLoadingProgress', true)
        $axios.get(base_url + '/sanctum/csrf-cookie').then(() => {
          axios.post('/login', this.data)
            .then(res => {
              localStorage.setItem('token', res.data.data.token)
              axios.get('/profile')
                .then(response => {
                  let userData = {
                    ...response.data.data,
                    role: response.data.data.role.name
                  }
                  let roleId = response.data.data.role.id
  
                  this.$store.dispatch('updateUserInfo', userData)
                  this.$store.dispatch('updateRoleId', roleId)
                  this.$router.push({
                    path: '/dashboard'
                  })
                  this.$store.dispatch('toggleLoadingProgress', false)
                })
            })
            .catch(error => {
              this.$store.dispatch('toggleLoadingProgress', false)
              this.$notify({ type: 'error', title: 'Error', text: error.response.data.message })
            })
        }).catch((error) => {
          this.$store.dispatch('toggleLoadingProgress', false)
          this.$notify({ type: 'error', title: 'Error', text: error.response.data.message })
        })
      }
    },
    togglePassword() {
      if(this.inputTypePassword === 'password') {
          this.inputTypePassword = 'text'
       } else {
          this.inputTypePassword = 'password'
       }
    },
    
  },
  computed: {
    disabledBtn() {
      return this.data.nik === '' || this.data.password === ''
    }
  }
}
</script>

<style>
.bg-login {
  background-image: url('../assets/img/login-bg.jpg');
}
</style>