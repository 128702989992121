import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import axios from '../axios'
import { AddAddressCustomerManagement, AddBroadcast, AddCommunicationHistory, AddEmployeeManagement, AddFeedback, AddLabelManagement, AddPicManagement, AddRoleManagement, AddSchedule, AddTarget, AddTechnicalManagement, Broadcast, ChangePassword, CommunicationHistory, CustomerManagement, Dashboard, DetailCustomerManagement, DetailFeedback, DetailTechnicalManagement, EditAddressCustomerManagement, EditCommunicationHistory, EditEmployeeManagement, EditFeedback, EditLabelManagement, EditPicManagement, EditRoleManagement, EditSchedule, EditTarget, EditTechnicalManagement, EmployeeManagement, Feedback, LabelManagement, Login, NotFound, PicManagement, RoleManagement, Schedule, Target, TechnicalManagement, FeedbackLabel, AddFeedbackLabel, EditFeedbackLabel } from '../views'

Vue.use(VueRouter)

const getUserData = (next) => {
    axios.get('/profile')
      .then(response => {
          let userData = {
            ...response.data.data,
            role: response.data.data.role.name
          }
          let roleId = response.data.data.role.id
          store.dispatch('updateRoleId', roleId)
          store.dispatch('updateUserInfo', userData) 
          next()
        })
        .catch(() => {
          next()
    })
}

const redirectUserHaventPermission = (next, permissions) => {
  let userPermissions = store.state.AppActiveUser.permissions;
  if(userPermissions){
    if(typeof permissions === 'string') {
      if(userPermissions.includes(permissions)){
        next()
      } else {
        window.location.replace('/dashboard')
      }
    } else {
      let result = permissions.filter(permission => {
        return Boolean(userPermissions.includes(permission))
      })
      if(result.length > 0){
        next()
      } else {
        window.location.replace('/dashboard')
      } 
    }
  }
}

const routes = [
  {
    path: '',
    component: () => import('../App.vue'),
    children: [
      // ==========================================================================
      // FULL PAGE LAYOUTS
      // ==========================================================================
      {
        path: '',
        component: () => import('../layouts/full-page/FullPage.vue'),
        children: [
          {
            path: '/',
            redirect: {
              path: '/login'
            }
          },
          {
            path: '/login',
            component: Login,
            meta: {
              guestOnly: true
            }
          }
        ]
      },

      // ==========================================================================
      // MAIN LAYOUTS
      // ==========================================================================
      {
        path: '',
        component: () => import('../layouts/main/Main.vue'),
        beforeEnter: (to, from, next) => {
          getUserData(next)
        },
        children: [
          {
            path: '/dashboard',
            name: 'Dashboard',
            component: Dashboard,
            meta: {
              authRequired: true
            }
          },
          {
            path: '/change-password',
            name: 'ChangePassword',
            component: ChangePassword,
            meta: {
              authRequired: true
            }
          },

          // Communication History Page
          {
            path: '/communication-history',
            name: 'CommunicationHistory',
            component: CommunicationHistory,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'View Communication Histories')
          },
          {
            path: '/communication-history/add',
            name: 'AddCommunicationHistory',
            component: AddCommunicationHistory,
            meta: {
              authRequired: true
            },
            props: true,
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, "Create Communication History")
          },
          {
            path: '/communication-history/edit/:id',
            name: 'EditCommunicationHistory',
            component: EditCommunicationHistory,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, ["Edit Communication History", "Delete Communication History"])
          },

          // Schedule Page
          {
            path: '/schedule',
            name: 'Schedule',
            component: Schedule,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, ["View All Schedules", "View My Schedules"])
          },
          {
            path: '/schedule/add',
            name: 'AddSchedule',
            component: AddSchedule,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'Create Schedule')
          },
          {
            path: '/schedule/edit/:id',
            name: 'EditSchedule',
            component: EditSchedule,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, ['Edit Schedule', 'Delete Schedule'])
          },

          // Target Page
          {
            path: '/target',
            name: 'Target',
            component: Target,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'View Target')

          },
          {
            path: '/target/add',
            name: 'AddTarget',
            component: AddTarget,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'Create Target')
          },
          {
            path: '/target/edit/:id',
            name: 'EditTarget',
            component: EditTarget,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, ['Edit Target', 'Delete Target', 'Close Target'])
          },

          // Target Page
          {
            path: '/feedback',
            name: 'Feedback',
            component: Feedback,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'View All Feedback and Complaints')

          },
          {
            path: '/feedback/label',
            name: 'FeedbackLabel',
            component: FeedbackLabel,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'View All Feedback and Complaint Labels')
          },
          {
            path: '/feedback/add',
            name: 'AddFeedback',
            component: AddFeedback,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'Create Feedback and Complaint')
          },
          {
            path: '/feedback/label/add',
            name: 'AddFeedbackLabel',
            component: AddFeedbackLabel,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'Create Feedback and Complaint Label')
          },
          {
            path: '/feedback/edit/:id',
            name: 'EditFeedback',
            component: EditFeedback,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, ['Edit Feedback and Complaint', 'Delete Feedback and Complaint'])
          },
          {
            path: '/feedback/label/edit/:id',
            name: 'EditFeedbackLabel',
            component: EditFeedbackLabel,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, ['Edit Feedback and Complaint Label', 'Delete Feedback and Complaint Label'])
          },
          {
            path: '/feedback/:id',
            name: 'DetailFeedback',
            component: DetailFeedback,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'View All Feedback and Complaints')
          },

          // Broadcast Page
          {
            path: '/broadcast',
            name: 'Broadcast',
            component: Broadcast,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'View All Broadcasts')

          },
          {
            path: '/broadcast/add',
            name: 'AddBroadcast',
            component: AddBroadcast,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'Create Broadcast')
          },

          // Employee Page
          {
            path: '/employee',
            name: 'EmployeeManagement',
            component: EmployeeManagement,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'View Employee Management')
          },
          {
            path: '/employee/add',
            name: 'AddEmployeeManagement',
            component: AddEmployeeManagement,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'Create Employee')
          },
          {
            path: '/employee/edit/:id',
            name: 'EditEmployeeManagement',
            component: EditEmployeeManagement,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, ['Edit Employee', 'Delete Employee'])
          },
          
          {
            path: '/role',
            name: 'RoleManagement',
            component: RoleManagement,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'View Role Management')
          },
          {
            path: '/role/add',
            name: 'AddRoleManagement',
            component: AddRoleManagement,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'Create Role Employee')
          },
          {
            path: '/role/edit/:id',
            name: 'EditRoleManagement',
            component: EditRoleManagement,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, ['Edit Role Employee', 'Delete Role Employee'])
          },

          // Customer Page
          {
            path: '/customer',
            name: 'CustomerManagement',
            component: CustomerManagement,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'View Customer Management')
          },
          {
            path: '/customer/:id',
            name: 'DetailCustomerManagement',
            component: DetailCustomerManagement,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'View Customer Management')
          },
          {
            path: '/customer/:id/address/add',
            name: 'AddAddressCustomerManagement',
            component: AddAddressCustomerManagement,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'View Customer Management')
          },
          {
            path: '/customer/:id/address/edit/:idAddress',
            name: 'EditAddressCustomerManagement',
            component: EditAddressCustomerManagement,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'View Customer Management')
          },
          {
            path: '/customer/:id/address/:idAddress/pic',
            name: 'PicManagement',
            component: PicManagement,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'View Customer Management')
          },
          {
            path: '/customer/:id/address/:idAddress/pic/add',
            name: 'AddPicManagement',
            component: AddPicManagement,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'View Customer Management')
          },
          {
            path: '/customer/:id/address/:idAddress/pic/edit/:idPic',
            name: 'EditPicManagement',
            component: EditPicManagement,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'View Customer Management')
          },
          {
            path: '/customer/:id/address/:idAddress/technical',
            name: 'TechnicalManagement',
            component: TechnicalManagement,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'View Customer Management')
          },
          {
            path: '/customer/:id/address/:idAddress/technical/add',
            name: 'AddTechnicalManagement',
            component: AddTechnicalManagement,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'View Customer Management')
          },
          {
            path: '/customer/:id/address/:idAddress/technical/edit/:idTech',
            name: 'EditTechnicalManagement',
            component: EditTechnicalManagement,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'View Customer Management')
          },
          {
            path: '/customer/:id/address/:idAddress/technical/:idTech',
            name: 'DetailTechnicalManagement',
            component: DetailTechnicalManagement,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'View Customer Management')
          },
          {
            path: '/label',
            name: 'LabelManagement',
            component: LabelManagement,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'View Label Management')
          },
          {
            path: '/label/add',
            name: 'AddLabelManagement',
            component: AddLabelManagement,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, 'Create Label Customer')
          },
          {
            path: '/label/edit/:id',
            name: 'EditLabelManagement',
            component: EditLabelManagement,
            meta: {
              authRequired: true
            },
            beforeEnter: (to, from, next) => redirectUserHaventPermission(next, ['Edit Label Customer', 'Delete Label Customer'])
          },

        ]
      },

      // Not Found Page
      {
        path: '*',
        component: NotFound
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior () {
      return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach(async (to, from, next) => {
  const authUser = localStorage.getItem("token");
  
  if (to.meta.authRequired && authUser === null) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      next({
          path: "/login",
      });
      alert('Not Authorized User, Please Login Before.')
  } else {
      next(); // make sure to always call next()!
  }

  if (to.meta.guestOnly && authUser) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      next({
          path: "/dashboard",
      });
  } else {
      next(); // make sure to always call next()!
  }
});

export default router
