<template>
  <div class="white-box mt-3">
    <div class="header-toolbar">
      <div class="left-side">
        <div class="row align-items-center">
          <div class="col-xl-2 col-sm-2 col-3">
            <div class="grey"> Entries</div>
          </div>
          <div class="col-xl-3 col-sm-4 col-9">
            <select class="form-select mm15" aria-label="Default select" v-model="entries">
              <option v-for="(item, i) in listEntries" :key="i" :value="item.value">{{item.label}}</option>
            </select>
          </div>
          <div class="col-xl-7 col-sm-6">
            <a v-if="hideAddEmployeeBtn" @click.prevent="$router.push({path: '/employee/add'})" href="#" class="btn btn-orange w-100 mm15">Add New</a>
          </div>
        </div>
      </div>
      <div class="right-side">
        <div class="row">
          <div class="col-xl-7 col-sm-6">
            <input type="text" @input="debounceSearch" class="form-control mm15" placeholder="Search">
          </div>
          <div class="col-xl-5 col-sm-6">
            <button @click.prevent="showModalAction('filter')" class="btn btn-filter" type="button">Filter by</button>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>No</th>
            <th>NIK</th>
            <th>Name</th>
            <th>Position</th>
            <th>Phone Number</th>
            <th class="tnd">Email</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody v-if="employees.length > 0">
          <tr v-for="(employee, index) in employees" :key="employee.id">
            <td>{{getOverallIndex(index)}}</td>
            <td>{{employee.nik}}</td>
            <td>{{employee.name}}</td>
            <td >
              <div v-if="employee.role" class="status" :style="`background-color: ${employee.role.color}1a;color: ${employee.role.color}`">{{employee.role.name}}</div>
            </td>
            <td>{{employee.phone_number}}</td>
            <td>{{employee.email}}</td>
            <td>
              <div class="status" :style="`background-color: ${statusBackground(employee.status)}1a;color: ${statusBackground(employee.status)};text-transform: Capitalize;`">
                {{employee.status}}
              </div>
            </td>
            <td>
              <a v-if="hideEditEmployeeBtn" @click.prevent="editEmployee(employee.nik)" href="#" class="link-edit"><span class="icon-ico-pencil"></span></a>
              <button @click.prevent="showModalAction('detail', employee.nik)" class="btn btn-see rbm"><span class="icon-ico-eye"></span></button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7" class="text-center">No data found</td>
          </tr>
        </tbody>
      </table>
    </div>

    <pagination :total="pages.total" :current="pages.current" @setCurrentPage="setCurrentPage" />


    <div class="overlay" :class="{'overlayshow': showModal}"></div>
    <!--Popup-->
    <!-- Detail Employee Modal -->
    <div id="rbm-content" class="popup-wrapper" :class="{'showing': detailModalShow}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center">
          <div class="font-bold">Detail Employee</div>
          <button @click.prevent="showModalAction('detail')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="inner-land">
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Position</div>
                <div v-if="detailEmployee.role" class="status" :style="`background-color: ${detailEmployee.role.color}1a;color: ${detailEmployee.role.color}`">{{detailEmployee.role.name}}</div>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Name</div>
                 <span>{{detailEmployee.name}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item">
            <div class="row">
              <div class="col-sm-6 mm15">
                <div class="grey">Phone number</div>
                <span>{{detailEmployee.phone_number}}</span>
              </div>
              <div class="col-sm-6">
                <div class="grey">Email</div>
                <span>{{detailEmployee.email}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Filter Employee Modal -->
    <div id="filter-content" class="popup-wrapper" :class="{'showing': filterModalShow}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center mb15">
          <div class="font-bold">Filter</div>
          <button @click.prevent="showModalAction('filter')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <div class="grey mb5">Position</div>
              <select class="form-select" v-model="position">
                <option value="" disabled>Choose One</option>
                <option v-for="position in positions" :key="position.id" :value="position.id">{{position.name}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button @click="resetFilter" class="btn btn-border-red mx-2" type="button">Reset</button>
          <button @click="filterPositions" class="btn btn-orange mx-2" type="button">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import {Pagination} from '../../../components'
import {showErrorMessage} from '@/utils';

export default {
  components: { Pagination },
  data() {
    return {
      showModal: false,
      detailModalShow: false,
      filterModalShow: false,
      listEntries: [
        {
          label: '10',
          value: '10'
        },
        {
          label: '20',
          value: '20'
        },
        {
          label: '40',
          value: '40'
        },
        {
          label: '100',
          value: '100'
        },
      ],
      entries: '10',
      pages: {
        total: 1,
        current: 1
      },

      // data
      employees: [],
      detailEmployee: {},
      positions: [],
      position: '',
      query: '',
      debounce: null
    }
  },
  methods: {
    statusBackground(status){
      return status === 'active' ? '#28A745' : '#DC3545'
    },

    showModalAction(type, nik) {
      this.showModal = !this.showModal

      if(type == 'filter') {
        this.filterModalShow = !this.filterModalShow
      } else if (type == 'detail'){
        if (this.detailModalShow === false) {
          this.getDetailEmployee(nik)
        }
        this.detailModalShow = !this.detailModalShow
      }
    },
    editEmployee(id){
      this.$router.push({
        name: 'EditEmployeeManagement',
        params: {
          id
        }
      })
    },
    getDetailEmployee(nik) {
      let employee = this.employees.filter(employee => employee.nik === nik)
      this.detailEmployee = employee[0]
    },
    getEmployees() {
      this.$store.dispatch('toggleLoadingProgress', true)
      
      axios.get(`/employees?per_page=${this.entries}&page=${this.pages.current}&search=${this.query}&position=${this.position}`)
        .then(res => {
          this.pages.total = res.data.data.last_page
          this.pages.current = res.data.data.current_page
          this.employees = res.data.data.data
          this.$store.dispatch('toggleLoadingProgress', false)
        })
        .catch(err => {
          showErrorMessage(err)
        })
    },
    getRoles() {
      this.$store.dispatch('toggleLoadingProgress', true)
      
      axios.get(`/roles`)
        .then(res => {
          this.positions = res.data.data
          this.$store.dispatch('toggleLoadingProgress', false)
        })
        .catch(err => {
          showErrorMessage(err)
        })
    },
    filterPositions() {
      this.showModal = false
      this.filterModalShow = false

      this.getEmployees()
    },
    debounceSearch(event) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.query = event.target.value
        this.resetPage()
        this.getEmployees()
      }, 600)
    },
    resetFilter(){
      this.showModal = false
      this.filterModalShow = false
      this.position = ''
      this.getEmployees()
    },
    setCurrentPage(val) {
      this.pages.current = val;
      this.getEmployees();
    },
    resetPage() {
      this.pages.total = 1;
      this.pages.current = 1;
    },
    getOverallIndex(index) {
      return this.pages.current * this.entries - this.entries + index + 1
    },
  }, 
  mounted() {
    this.getEmployees()
    this.getRoles()
  },
  computed: {
    AppActiveUser() { return this.$store.state.AppActiveUser },
    hideAddEmployeeBtn() {
      return this.AppActiveUser.permissions.includes('Create Employee')
    },
    hideEditEmployeeBtn() {
      return this.AppActiveUser.permissions.includes('Edit Employee') || this.AppActiveUser.permissions.includes('Delete Employee')
    },
  },
  watch: {
    entries(){
      this.resetPage()
      this.getEmployees()
    }
  }
}
</script>

<style>

</style>