<template>
  <div class="tab-main">
    <input v-if="viewMySchedules" class="radio-tab" id="tab1" type="radio" name="crm-tab" v-model="activeTab" value="mysch">
    <label v-if="viewMySchedules" for="tab1" class="tab-main-item tab1">
      My Schedule
    </label>
    <input v-if="viewMySchedules" id="tab2" class="radio-tab" type="radio" name="crm-tab" v-model="activeTab" value="inv">
    <label v-if="viewMySchedules" for="tab2" class="tab-main-item tab2">
      Invitation
    </label>
    <input v-if="viewAllSchedules" id="tab3" class="radio-tab" type="radio" name="crm-tab" v-model="activeTab" value="all">
    <label v-if="viewAllSchedules" for="tab3" class="tab-main-item tab3">
      All Schedule
    </label>

    <section class="tab-container">
      <div class="tabs tab1" v-if="viewMySchedules">
        <div class="white-box">
          <div class="header-toolbar">
            <div class="left-side">
              <div class="row align-items-center">
                <div class="col-xl-2 col-sm-2 col-3">
                  <div class="grey mm15"> Entries</div>
                </div>
                <div class="col-xl-3 col-sm-4 col-9">
                  <select class="form-select mm15" aria-label="Default select" v-model="myschedule.entries">
                    <option v-for="(item, i) in listEntries" :key="i" :value="item.value">{{item.label}}</option>
                  </select>
                </div>
                <div class="col-xl-7 col-sm-6">
                  <a v-if="hideAddBtn" @click.prevent="$router.push({path: '/schedule/add'})" href="#" class="btn btn-orange w-100 mm15">Add Schedule</a>
                </div>
              </div>
            </div>
            <div class="right-side">
              <div class="row">
                <div class="col-xl-7 col-sm-6">
                  <input type="text" class="form-control mm15" placeholder="Search" @input="event => debounceSearch(event, 'mysch')">
                </div>
                <div class="col-xl-5 col-sm-6">
                  <button @click="showModalAction('filter')" class="btn btn-filter" type="button">Filter by</button>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Schedule Type</th>
                  <th>About</th>
                  <th>Place</th>
                  <th class="tnd">Start Date and Time</th>
                  <th class="tnd">End Date and Time</th>
                  <th>Participants</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody v-if="myschedule.schedules.length > 0">
                <tr v-for="(schedule, index) in myschedule.schedules" :key="schedule.id">
                  <td>{{getOverallIndex(index, 'mysch')}}</td>
                  <td>{{schedule.type.name}}</td>
                  <td>{{truncate(schedule.about, 20)}}</td>
                  <td>{{truncate(schedule.place, 20)}}</td>
                  <td>{{moment(schedule.start_datetime).format('DD MMM YYYY, HH : mm')}} WIB</td>
                  <td>{{moment(schedule.end_datetime).format('DD MMM YYYY, HH : mm')}} WIB</td>
                  <td>
                    <span>{{assigneesEmployee(schedule.participants)}}</span>
                  </td>
                  <td>
                    <div class="status" :class="schedule.status === 'Closed' ? 'grey' : 'yellow'">{{schedule.status}}</div>
                  </td>
                  <td>
                    <a v-if="hideEditBtn" @click.prevent="$router.push({name: 'EditSchedule',params:{id: schedule.id}})" href="#" class="link-edit"><span class="icon-ico-pencil"></span></a>
                    <button @click="showModalAction('mysch', schedule.id)" class="btn btn-see rbm"><span class="icon-ico-eye"></span></button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="8" class="text-center">No data found</td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination :total="myschedule.pages.total" :current="myschedule.pages.current" @setCurrentPage="value => setCurrentPage(value, 'mysch')" />
        </div>
      </div><!-- end of tab1 -->

      <div class="tabs tab2" v-if="viewMySchedules">
        <div class="white-box">
          <div class="header-toolbar">
            <div class="left-side">
              <div class="row align-items-center">
                <div class="col-xl-2 col-sm-2 col-3">
                  <div class="grey"> Entries</div>
                </div>
                <div class="col-xl-3 col-sm-4 col-9">
                  <select class="form-select mm15" aria-label="Default select" v-model="invitation.entries">
                    <option v-for="(item, i) in listEntries" :key="i" :value="item.value">{{item.label}}</option>
                  </select>
                </div>
                <div class="col-xl-7 col-sm-6">
                  <a v-if="hideAddBtn" @click.prevent="$router.push({path: '/schedule/add'})" href="#" class="btn btn-orange w-100 mm15">Add Schedule</a>
                </div>
              </div>
            </div>
            <div class="right-side">
              <div class="row">
                <div class="col-xl-7 col-sm-6">
                  <input type="text" class="form-control mm15" placeholder="Search" @input="value => debounceSearch(value, 'inv')">
                </div>
                <div class="col-xl-5 col-sm-6">
                  <button @click="showModalAction('filterInv')" class="btn btn-filter" type="button">Filter by</button>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Schedule Type</th>
                  <th>About</th>
                  <th>Participants</th>
                  <th>Start Date and Time</th>
                  <th>End Date and Time</th>
                  <th>Invited by</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody v-if="invitation.schedules.length > 0">
                <tr v-for="(schedule, index) in invitation.schedules" :key="schedule.id">
                  <td>{{getOverallIndex(index, 'inv')}}</td>
                  <td>{{schedule.type.name}}</td>
                  <td>{{truncate(schedule.about,15)}}</td>
                  <td>{{assigneesEmployee(schedule.participants)}}</td>
                  <td>{{moment(schedule.start_datetime).format('DD MMM YYYY, HH : mm')}}</td>
                  <td>{{moment(schedule.end_datetime).format('DD MMM YYYY, HH : mm')}}</td>
                  <td>
                    <div v-if="schedule.invited_by" class="status" :style="`background-color: ${schedule.invited_by.roles[0].color}1a;color: ${schedule.invited_by.roles[0].color}`">{{schedule.invited_by.roles[0].name}}</div>
                    {{schedule.invited_by.name}}
                  </td>
                  <td>
                    <div class="status" :class="schedule.status === 'Closed' ? 'grey' : 'yellow'">{{schedule.status}}</div>
                  </td>
                  <td>
                    <button @click="showModalAction('inv', schedule.id)" class="btn btn-see rbe"><span class="icon-ico-eye"></span></button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="9" class="text-center">No data found</td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination :total="invitation.pages.total" :current="invitation.pages.current" @setCurrentPage="value => setCurrentPage(value, 'inv')" />
        </div>
      </div><!-- end of tab2 -->

      <div class="tabs tab3" v-if="viewAllSchedules">
        <div class="white-box">
          <div class="header-toolbar">
            <div class="left-side">
              <div class="row align-items-center">
                <div class="col-xl-2 col-sm-2 col-3">
                  <div class="grey"> Entries</div>
                </div>
                <div class="col-xl-3 col-sm-4 col-9">
                  <select class="form-select mm15" aria-label="Default select" v-model="allSchedules.entries">
                    <option v-for="(item, i) in listEntries" :key="i" :value="item.value">{{item.label}}</option>
                  </select>
                </div>
                <div class="col-xl-7 col-sm-6">
                  <a v-if="hideAddBtn" @click.prevent="$router.push({path: '/schedule/add'})" href="#" class="btn btn-orange w-100 mm15">Add Schedule</a>
                </div>
              </div>
            </div>
            <div class="right-side">
              <div class="row">
                <div class="col-xl-7 col-sm-6">
                  <input type="text" class="form-control mm15" placeholder="Search" @input="debounceSearch">
                </div>
                <div class="col-xl-5 col-sm-6">
                  <button @click="showModalAction('filterAll')" class="btn btn-filter" type="button">Filter by</button>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Schedule Type</th>
                  <th>About</th>
                  <th>Participants</th>
                  <th>Start Date and Time</th>
                  <th>End Date and Time</th>
                  <th>Created by</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody v-if="allSchedules.schedules.length > 0">
                <tr v-for="(schedule, index) in allSchedules.schedules" :key="schedule.id">
                  <td>{{getOverallIndex(index)}}</td>
                  <td>{{schedule.type.name}}</td>
                  <td>{{truncate(schedule.about,15)}}</td>
                  <td>{{assigneesEmployee(schedule.participants)}}</td>
                  <td>{{moment(schedule.start_datetime).format('DD MMM YYYY, HH : mm')}}</td>
                  <td>{{moment(schedule.end_datetime).format('DD MMM YYYY, HH : mm')}}</td>
                  <td>
                    <div v-if="schedule.invited_by" class="status" :style="`background-color: ${schedule.invited_by.roles[0].color}1a;color: ${schedule.invited_by.roles[0].color}`">{{schedule.invited_by.roles[0].name}}</div>
                    {{schedule.invited_by.name}}
                  </td>
                  <td>
                    <div class="status" :class="schedule.status === 'Closed' ? 'grey' : 'yellow'">{{schedule.status}}</div>
                  </td>
                  <td>
                    <button @click="showModalAction('all', schedule.id)" class="btn btn-see rbe"><span class="icon-ico-eye"></span></button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="9" class="text-center">No data found</td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination :total="allSchedules.pages.total" :current="allSchedules.pages.current" @setCurrentPage="setCurrentPage" />
        </div>
      </div><!-- end of tab3 -->

    </section>
    <div class="overlay" :class="{'overlayshow': modal.overlay}"></div>
    <!--Popup-->
    <div v-if="myschedule.detailSchedule" id="rbm-content" class="popup-wrapper" :class="{'showing': modal.mysch}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center">
          <div class="font-bold">Detail Schedule</div>
          <button @click="showModalAction('mysch')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="inner-scroll">
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">Status</div>
                <div class="status" :class="myschedule.detailSchedule.status === 'Closed' ? 'grey' : 'yellow'">{{myschedule.detailSchedule.status}}</div>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6  col-6">
                <div class="grey">Schedule Type</div>
                <span v-if="myschedule.detailSchedule.type">{{myschedule.detailSchedule.type.name || '-'}}</span>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">About</div>
                <span>{{myschedule.detailSchedule.about || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Place</div>
                <span>{{myschedule.detailSchedule.place || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">Start Date and Time</div>
                <span>{{moment(myschedule.detailSchedule.start_datetime).format('DD MMM YYYY, HH : mm')}} WIB</span>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">End Date and Time</div>
                <span>{{moment(myschedule.detailSchedule.end_datetime).format('DD MMM YYYY, HH : mm')}} WIB</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Participants</div>
                <div v-for="participant in myschedule.detailSchedule.participants" :key="participant.nik" class="status ori" style="margin-right: 3px">{{participant.name}}</div>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Customer Name</div>
                <span>{{myschedule.detailSchedule.customer_name || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Goals</div>
                <span>{{myschedule.detailSchedule.goals || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item">
            <div class="row">
              <div class="col-sm-12">
                <div class="bg-yellow">
                  <div class="grey">Result</div>
                  <div class="result-schedule" v-if="myschedule.detailSchedule.result" v-html="myschedule.detailSchedule.result"></div>
                  <span v-else class="italic">-</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="invitation.detailSchedule" id="rbe-content" class="popup-wrapper" :class="{'showing': modal.inv}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center">
          <div class="font-bold">Detail Invitation</div>
          <button @click="showModalAction('inv')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="inner-scroll">
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">Status</div>
                <div class="status" :class="invitation.detailSchedule.status === 'Closed' ? 'grey' : 'yellow'">{{invitation.detailSchedule.status}}</div>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">Invited by</div>
                <div v-if="invitation.detailSchedule.invited_by" class="status" :style="`background-color: ${invitation.detailSchedule.invited_by.roles[0].color}1a;color: ${invitation.detailSchedule.invited_by.roles[0].color}`">{{invitation.detailSchedule.invited_by.roles[0].name}}</div>
                <span v-if="invitation.detailSchedule.invited_by"> {{invitation.detailSchedule.invited_by.name}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6  col-6">
                <div class="grey">Schedule Type</div>
                <span v-if="invitation.detailSchedule.type">{{invitation.detailSchedule.type.name}}</span>
              </div>
              <div class="col-sm-6  col-6">
                <div class="grey">About</div>
                <span>{{invitation.detailSchedule.about}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Place</div>
                <span>{{invitation.detailSchedule.place}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6  col-6">
                <div class="grey">Start Date and Time</div>
                <span>{{moment(invitation.detailSchedule.start_datetime).format('DD MMM YYYY, HH : mm')}} WIB</span>
              </div>
              <div class="col-sm-6  col-6">
                <div class="grey">End Date and Time</div>
                <span>{{moment(invitation.detailSchedule.end_datetime).format('DD MMM YYYY, HH : mm')}} WIB</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Participants</div>
                <div v-for="participant in invitation.detailSchedule.participants" :key="participant.nik" class="status ori" style="margin-right: 3px">{{participant.name}}</div>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Customer Name</div>
                <span>{{invitation.detailSchedule.customer_name || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Goals</div>
                <span>{{invitation.detailSchedule.goals || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item">
            <div class="row">
              <div class="col-sm-12">
                <div class="bg-yellow">
                  <div class="grey">Result</div>
                  <div class="result-schedule" v-if="invitation.detailSchedule.result" v-html="invitation.detailSchedule.result"></div>
                  <span v-else class="italic">-</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="allSchedules.detailSchedule" id="rbe-content" class="popup-wrapper" :class="{'showing': modal.all}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center">
          <div class="font-bold">Detail Invitation</div>
          <button @click="showModalAction('all')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="inner-scroll">
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">Status</div>
                <div class="status" :class="allSchedules.detailSchedule.status === 'Closed' ? 'grey' : 'yellow'">{{allSchedules.detailSchedule.status}}</div>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">Invited by</div>
                <div v-if="allSchedules.detailSchedule.invited_by" class="status" :style="`background-color: ${allSchedules.detailSchedule.invited_by.roles[0].color}1a;color: ${allSchedules.detailSchedule.invited_by.roles[0].color}`">{{allSchedules.detailSchedule.invited_by.roles[0].name}}</div>
                <span v-if="allSchedules.detailSchedule.invited_by"> {{allSchedules.detailSchedule.invited_by.name}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6  col-6">
                <div class="grey">Schedule Type</div>
                <span v-if="allSchedules.detailSchedule.type">{{allSchedules.detailSchedule.type.name}}</span>
              </div>
              <div class="col-sm-6  col-6">
                <div class="grey">About</div>
                <span>{{allSchedules.detailSchedule.about}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Place</div>
                <span>{{allSchedules.detailSchedule.place}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6  col-6">
                <div class="grey">Start Date and Time</div>
                <span>{{moment(allSchedules.detailSchedule.start_datetime).format('DD MMM YYYY, HH : mm')}} WIB</span>
              </div>
              <div class="col-sm-6  col-6">
                <div class="grey">End Date and Time</div>
                <span>{{moment(allSchedules.detailSchedule.end_datetime).format('DD MMM YYYY, HH : mm')}} WIB</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Participants</div>
                <div v-for="participant in allSchedules.detailSchedule.participants" :key="participant.nik" class="status ori" style="margin-right: 3px">{{participant.name}}</div>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Customer Name</div>
                <span>{{allSchedules.detailSchedule.customer_name || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Goals</div>
                <span>{{allSchedules.detailSchedule.goals || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item">
            <div class="row">
              <div class="col-sm-12">
                <div class="bg-yellow">
                  <div class="grey">Result</div>
                  <div class="result-schedule" v-if="allSchedules.detailSchedule.result" v-html="allSchedules.detailSchedule.result"></div>
                  <span v-else class="italic">-</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="filter-content" class="popup-wrapper" :class="{'showing': modal.filter}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center mb15">
          <div class="font-bold">Filter</div>
          <button @click="showModalAction('filter')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="inner-land">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <div class="grey mb5">Schedule Type</div>
                <select class="form-select" v-model="myschedule.filter.type">
                  <option value="" disabled>Choose One</option>
                  <option v-for="(type, index) in types" :key="index" :value="type.value">{{type.label}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">Start date</div>
                <date-picker v-model="myschedule.filter.start_date" :config="options.start_date" @dp-change="(value) => doSomethingOnChange(value, 'mysch')"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-date"></span>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">End date</div>
                <date-picker :disabled="disabledEndDate('myschedule')" v-model="myschedule.filter.end_date" :config="options.end_date" @dp-change="value => doSomethingOnChangeEnd(value, 'mysch')"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-date"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button @click="resetFilter('mysch')" class="btn btn-border-red mx-2" type="button">Reset</button>
            <button @click="filterSchedule('mysch')" class="btn btn-orange mx-2" type="button">Save</button>
          </div>
        </div>
      </div>
    </div>
    <div id="filter-content-inv" class="popup-wrapper" :class="{'showing': modal.filterInv}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center mb15">
          <div class="font-bold">Filter</div>
          <button @click="showModalAction('filterInv')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="inner-land">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <div class="grey mb5">Schedule Type</div>
                <select class="form-select" v-model="invitation.filter.type">
                  <option value="" disabled>Choose One</option>
                  <option v-for="(type, index) in types" :key="index" :value="type.value">{{type.label}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">Start date</div>
                <date-picker v-model="invitation.filter.start_date" :config="options.start_dateInv" @dp-change="(value) => doSomethingOnChange(value, 'inv')"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-date"></span>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">End date</div>
                <date-picker :disabled="disabledEndDate('invitation')" v-model="invitation.filter.end_date" :config="options.end_dateInv" @dp-change="value => doSomethingOnChangeEnd(value, 'inv')"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-date"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button @click="resetFilter('inv')" class="btn btn-border-red mx-2" type="button">Reset</button>
            <button @click="filterSchedule('inv')" class="btn btn-orange mx-2" type="button">Save</button>
          </div>
        </div>
      </div>
    </div>
    <div id="filter-content-all" class="popup-wrapper" :class="{'showing': modal.filterAll}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center mb15">
          <div class="font-bold">Filter</div>
          <button @click="showModalAction('filterAll')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="inner-land">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <div class="grey mb5">Schedule Type</div>
                <select class="form-select" v-model="allSchedules.filter.type">
                  <option value="" disabled>Choose One</option>
                  <option v-for="(type, index) in types" :key="index" :value="type.value">{{type.label}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">Start date</div>
                <date-picker v-model="allSchedules.filter.start_date" :config="options.start_dateAll" @dp-change="doSomethingOnChange"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-date"></span>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">End date</div>
                <date-picker :disabled="disabledEndDate('allSchedules')" v-model="allSchedules.filter.end_date" :config="options.end_dateAll" @dp-change="doSomethingOnChangeEnd"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-date"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button @click="resetFilter()" class="btn btn-border-red mx-2" type="button">Reset</button>
            <button @click="filterSchedule()" class="btn btn-orange mx-2" type="button">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import moment from 'moment'
import {truncate, showErrorMessage} from '@/utils'
import {Pagination} from '../../components'
export default {
  components: { Pagination },
  data() {
    return {
      truncate,
      moment,
      activeTab: 'mysch',
      modal: {
        overlay: false,
        filter: false,
        filterInv: false,
        filterAll: false,
        mysch: false,
        inv: false,
        all: false
      },
      types: [],
      options: {
        start_date: {
          format: 'DD-MM-YYYY',
          maxDate: moment('01-01-3000').format()
        },
        start_dateInv: {
          format: 'DD-MM-YYYY',
          maxDate: moment('01-01-3000').format()
        },
        start_dateAll: {
          format: 'DD-MM-YYYY',
          maxDate: moment('01-01-3000').format()
        },
        end_date: {
          format: 'DD-MM-YYYY',
          minDate: moment('01-01-1600').format()
        },
        end_dateInv: {
          format: 'DD-MM-YYYY',
          minDate: moment('01-01-1600').format()
        },
        end_dateAll: {
          format: 'DD-MM-YYYY',
          minDate: moment('01-01-1600').format()
        },
        time: {
          format: 'LT'
        }
      },
      listEntries: [
        {
          label: '10',
          value: '10'
        },
        {
          label: '20',
          value: '20'
        },
        {
          label: '40',
          value: '40'
        },
        {
          label: '100',
          value: '100'
        },
      ],

      // My Shcedule Tab data
      myschedule: {
        schedules: [],
        filter: {
          start_date: null,
          end_date: null,
          type: "",
        },
        start_date: null,
        end_date: null,
        type: "",   
        entries: '10',
        pages: {
          total: 1,
          current: 1
        },
        query: '',
        debounce: null,
        detailSchedule: {},
      },
      // Invitation Tab data
      invitation: {
        schedules: [],
        filter: {
          start_date: "",
          end_date: "",
          type: "",
        },
        start_date: "",
        end_date: "",
        type: "",   
        entries: '10',
        pages: {
          total: 1,
          current: 1
        },
        query: '',
        debounce: null,
        detailSchedule: {},
      },

      allSchedules: {
        schedules: [],
        filter: {
          start_date: "",
          end_date: "",
          type: "",
        },
        start_date: "",
        end_date: "",
        type: "",   
        entries: '10',
        pages: {
          total: 1,
          current: 1
        },
        query: '',
        debounce: null,
        detailSchedule: {},
      },

    }
  },
  methods: {
    // My Schedule Methods
    showModalAction(type, id) {
      if(type == 'filter') {
        this.modal.filter = !this.modal.filter
      } else if (type == 'mysch') {
        // Reported By Me Modal
        if(this.modal.mysch === false){
          this.getDetailSchedule(id, 'mysch')
        }
        this.modal.mysch = !this.modal.mysch
      } else if (type == 'inv') {
        // Reported By Employee Modal
        if(this.modal.inv === false){
          this.getDetailSchedule(id, 'inv')
        }
        this.modal.inv = !this.modal.inv
      } else if (type === 'filterInv') {
        this.modal.filterInv = !this.modal.filterInv
      } else if (type == 'all') {
        // Reported By Employee Modal
        if(this.modal.all === false){
          this.getDetailSchedule(id)
        }
        this.modal.all = !this.modal.all
      } else if(type === 'filterAll') {
        this.modal.filterAll = !this.modal.filterAll
      }
      this.modal.overlay = !this.modal.overlay
    },
    doSomethingOnChange(e, type) {
      if(type === 'mysch'){
        this.options.end_date.minDate = e.date._d
      }else if(type === 'inv'){
        this.options.end_dateInv.minDate = e.date._d
      }else {
        this.options.end_dateAll.minDate = e.date._d
      }
    },
    doSomethingOnChangeEnd(e, type) {
      if(type === 'mysch'){
        this.options.start_date.maxDate = e.date._d
      }else if(type === 'inv'){
        this.options.start_dateInv.maxDate = e.date._d
      }else {
        this.options.start_dateAll.maxDate = e.date._d
      }
    },
    setCurrentPage(val, type) {
      if(type === 'mysch') {
        this.myschedule.pages.current = val;
        this.getSchedules();
      } else if (type === 'inv') {
        this.invitation.pages.current = val;
        this.getInvitationSchedule();
      } else {
        this.allSchedules.pages.current = val;
        this.getAllSchedule();
      }
    },
    disabledEndDate(type) {
      return this[type].filter.start_date === null || this[type].filter.start_date === ''
    },
    resetPage(type) {
      if(type === 'mysch') {
        this.myschedule.pages.total = 1;
        this.myschedule.pages.current = 1;
      } else if(type === 'inv') {
        this.invitation.pages.total = 1;
        this.invitation.pages.current = 1;
      } else {
        this.allSchedules.pages.total = 1;
        this.allSchedules.pages.current = 1;
      }
    },
    getOverallIndex(index, type) {
      if(type === 'mysch') {
        return this.myschedule.pages.current * this.myschedule.entries - this.myschedule.entries + index + 1
      }else if(type === 'inv'){
        return this.invitation.pages.current * this.invitation.entries - this.invitation.entries + index + 1
      }else {
        return this.allSchedules.pages.current * this.allSchedules.entries - this.allSchedules.entries + index + 1
      }
    },
    
    resetFilter(type) {
      if(type === 'mysch'){
        this.modal.overlay = false
        this.modal.filter = false
        this.myschedule.type = ""
        this.myschedule.start_date = ""
        this.myschedule.end_date = ""
        this.myschedule.filter.type = ""
        this.myschedule.filter.start_date = ""
        this.myschedule.filter.end_date = ""
        this.getSchedules()
      }else if (type === 'inv'){
        this.modal.overlay = false
        this.modal.filterInv = false
        this.invitation.filter.type = ""
        this.invitation.filter.start_date = ""
        this.invitation.filter.end_date = ""
        this.invitation.type = ""
        this.invitation.start_date = ""
        this.invitation.end_date = ""
        this.getInvitationSchedule()
      } else {
        this.modal.overlay = false
        this.modal.filterAll = false
        this.allSchedules.filter.type = ""
        this.allSchedules.filter.start_date = ""
        this.allSchedules.filter.end_date = ""
        this.allSchedules.type = ""
        this.allSchedules.start_date = ""
        this.allSchedules.end_date = ""
        this.getAllSchedule()
      }
    },
    filterSchedule(type) {
      if(type === 'mysch'){
        this.modal.overlay = false
        this.modal.filter = false
        this.myschedule.type = this.myschedule.filter.type
        this.myschedule.start_date = this.myschedule.filter.start_date
        this.myschedule.end_date = this.myschedule.filter.end_date
        this.resetPage(type)
        this.getSchedules()
      }else if(type === 'inv'){
        this.modal.overlay = false
        this.modal.filterInv = false
        this.invitation.type = this.invitation.filter.type
        this.invitation.start_date = this.invitation.filter.start_date
        this.invitation.end_date = this.invitation.filter.end_date
        this.resetPage(type)
        this.getInvitationSchedule()
      } else {
        this.modal.overlay = false
        this.modal.filterAll = false
        this.allSchedules.type = this.allSchedules.filter.type
        this.allSchedules.start_date = this.allSchedules.filter.start_date
        this.allSchedules.end_date = this.allSchedules.filter.end_date
        this.resetPage()
        this.getAllSchedule()
      }
    },

    // get My Schedules
    getSchedules() {
      this.$store.dispatch('toggleLoadingProgress', true)
      let start_date = "";
      let end_date = "";
      if(this.myschedule.start_date) {
        start_date = this.myschedule.start_date.split('-').reverse().join('-')
      }
      if(this.myschedule.end_date) {
        end_date = this.myschedule.end_date.split('-').reverse().join('-')
      }

      axios.get(`/schedules?per_page=${this.myschedule.entries}&page=${this.myschedule.pages.current}&search=${this.myschedule.query}&schedule_type=${this.myschedule.type}&start_date=${start_date}&end_date=${end_date}`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)  
          this.myschedule.pages.total = res.data.data.last_page
          this.myschedule.pages.current = res.data.data.current_page
          let schedules = res.data.data.data
          schedules.map(schedule => {
            schedule.start_datetime = new Date(schedule.start_datetime)
            schedule.end_datetime = new Date(schedule.end_datetime)
          })
          this.myschedule.schedules = schedules
        })
        .catch(err => {
          showErrorMessage(err)
        })
    },
    // Invitation Tab Method
    getInvitationSchedule() {
      this.$store.dispatch('toggleLoadingProgress', true)
      let start_date = "";
      let end_date = "";
      if(this.invitation.start_date !== "" && this.invitation.start_date !== null) {
        start_date = this.invitation.start_date.split('-').reverse().join('-')
      }
      if(this.invitation.end_date !== "" && this.invitation.end_date !== null) {
        end_date = this.invitation.end_date.split('-').reverse().join('-')
      }

      axios.get(`/schedules?scheduled_by=others&per_page=${this.invitation.entries}&page=${this.invitation.pages.current}&search=${this.invitation.query}&schedule_type=${this.invitation.type}&start_date=${start_date}&end_date=${end_date}`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)  
          this.invitation.pages.total = res.data.data.last_page
          this.invitation.pages.current = res.data.data.current_page
          let schedules = res.data.data.data
          schedules.map(schedule => {
            schedule.start_datetime = new Date(schedule.start_datetime)
            schedule.end_datetime = new Date(schedule.end_datetime)
          })
          this.invitation.schedules = schedules
        })
        .catch(err => {
          showErrorMessage(err)
        })
    },
    // All Schedule Tab Method
    getAllSchedule() {
      this.$store.dispatch('toggleLoadingProgress', true)
      let start_date = "";
      let end_date = "";
      if(this.allSchedules.start_date !== "" && this.allSchedules.start_date !== null) {
        start_date = this.allSchedules.start_date.split('-').reverse().join('-')
      }
      if(this.allSchedules.end_date !== "" && this.allSchedules.end_date !== null) {
        end_date = this.allSchedules.end_date.split('-').reverse().join('-')
      }

      axios.get(`/schedules?scheduled_by=all&per_page=${this.allSchedules.entries}&page=${this.allSchedules.pages.current}&search=${this.allSchedules.query}&schedule_type=${this.allSchedules.type}&start_date=${start_date}&end_date=${end_date}`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)  
          this.allSchedules.pages.total = res.data.data.last_page
          this.allSchedules.pages.current = res.data.data.current_page
          let schedules = res.data.data.data
          schedules.map(schedule => {
            schedule.start_datetime = new Date(schedule.start_datetime)
            schedule.end_datetime = new Date(schedule.end_datetime)
          })
          this.allSchedules.schedules = schedules
        })
        .catch(err => {
          showErrorMessage(err)
        })
    },

    getTypesSchedule() {
      axios.get('/schedule-types').then(res => {
        const {data} = res.data
        data.map(type => {
          this.types.push({
            label: type.name,
            value: type.id
          })
        })
      }).catch(err => {
        showErrorMessage(err)
      })
    },
    debounceSearch(event, type) {
      if(type === 'mysch') {
        clearTimeout(this.myschedule.debounce)
        this.myschedule.debounce = setTimeout(() => {
          this.myschedule.query = event.target.value
          this.resetPage('mysch')
          this.getSchedules()
        }, 600)
      } else if (type === 'inv') {
        clearTimeout(this.invitation.debounce)
        this.invitation.debounce = setTimeout(() => {
          this.invitation.query = event.target.value
          this.resetPage('inv')
          this.getInvitationSchedule()
        }, 600)
      } else {
        clearTimeout(this.allSchedules.debounce)
        this.allSchedules.debounce = setTimeout(() => {
          this.allSchedules.query = event.target.value
          this.resetPage()
          this.getAllSchedule()
        }, 600)
      }
    },
    getDetailSchedule(id, type) {
      if(type === 'mysch') {
        let schedule = this.myschedule.schedules.filter(schedule => schedule.id === id)
        this.myschedule.detailSchedule = schedule[0]
      }else if (type === 'inv'){
        axios.get(`schedules/${id}`).then(() => {
          let schedule = this.invitation.schedules.filter(schedule => schedule.id === id)
          this.invitation.detailSchedule = schedule[0]
        })
      }else{
        let schedule = this.allSchedules.schedules.filter(schedule => schedule.id === id)
        this.allSchedules.detailSchedule = schedule[0]
      }
    },



    assigneesEmployee(assignees){
      let items = assignees.map(item => item.name)
      return truncate(items.join(', '), 20)
    },
  },
  computed: {
    AppActiveUser()     { return this.$store.state.AppActiveUser },
    myScheduleEntries() { return this.myschedule.entries },
    invitationEntries() { return this.invitation.entries },
    allScheduleEntries() { return this.allSchedules.entries },
    viewAllSchedules() {
      return this.AppActiveUser.permissions.includes('View All Schedules')
    },
    viewMySchedules() {
      return this.AppActiveUser.permissions.includes('View My Schedules') || this.AppActiveUser.permissions.includes('View All Schedules')
    },
    hideAddBtn() {
      return this.AppActiveUser.permissions.includes('Create Schedule')
    },
    hideEditBtn() {
      return this.AppActiveUser.permissions.includes('Edit Schedule') || this.AppActiveUser.permissions.includes('Delete Schedule')
    },
  },
  mounted() {
    if(this.$route.params?.tab) {
      this.activeTab = this.$route.params.tab
    }
    this.getSchedules()
    this.getInvitationSchedule()
    this.getAllSchedule()

    this.getTypesSchedule()
  },
  watch: {
    myScheduleEntries(){
      this.resetPage('mysch')
      this.getSchedules()
    },
    invitationEntries() {
      this.resetPage('inv')
      this.getInvitationSchedule()
    },
    allScheduleEntries() {
      this.resetPage()
      this.getAllSchedule()
    },
  }
}
</script>
<style>
.result-schedule p, .result-schedule ol, .result-schedule ul  {
  margin-bottom: 0 !important;
}
</style>