<template>
  <main>
    <a @click.prevent="$router.go(-1)" href="#" class="link-back"><span class="icon-ico-arrow-left"></span> Add New Broadcast</a>
    <div class="white-box p30">
      <div class="row">
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb15">Broadcast type</div>
            <div class="row">
              <div class="col-sm-6 col-xl-4" v-for="(type, index) in types" :key="index">
                <div class="form-check">
                  <input class="form-check-input" name="radio-com1" type="radio" :id="type" :value="type" v-model="data.broadcast_type">
                  <label class="form-check-label" :for="type">
                    {{type}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6"></div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group" v-if="data.broadcast_type === 'Group Label'">
            <div class="grey mb5">Label</div>
            <v-select placeholder="Choose One" :closeOnSelect="false" multiple v-model="labels" :options="listLabels.filter(o => labels.indexOf(o) < 0)">
              <template
                #selected-option-container="{ option }"
              >
                <div class="selected_custom" :style="`background-color: ${option.color}1a;color: ${option.color}`">
                  <span>{{ option.label }}</span>
                  <button @click.capture="deleteLabelFromCurrent(option.value)" class="vs__deselect"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path></svg></button>
                </div>
              </template>
            </v-select>
          </div>
          <div class="form-group" v-else>
            <div class="grey mb5">Customers</div>
            <v-select 
              :filterable="false" 
              @search="debounceSearch" 
              placeholder="Choose One" 
              :closeOnSelect="false" 
              multiple 
              v-model="customers" 
              :options="listCustomers.filter(o => customers.indexOf(o) < 0)"
            >
              <template v-if="listCustomers.length >= 10 && (listCustomers.length < totalCustomers)" #list-footer>
                <button @click="loadMoreCustomers" class="d-flex py-2" style="opacity: 0.8; margin: auto; background: transparent; border: none">
                 {{ onLoadMore ? 'Please wait...' : 'Click to load more' }}
                </button>
              </template>
            </v-select>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">

        </div>

        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb15">Communication by</div>
            <div class="row">
              <div class="col-sm-6 col-xl-4" v-for="(communication, index) in communication_with" :key="index">
                <div class="form-check">
                  <input class="form-check-input" name="radio-com" type="radio" :id="communication" :value="communication" v-model="data.communication_with">
                  <label class="form-check-label" :for="communication">
                    {{communication}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Title</div>
            <input type="text" class="form-control" v-model="data.title">
          </div>
        </div>
        <div class="col-xl-12">
          <div class="form-group">
            <div class="grey mb5">Message</div>
              <quill-editor
                v-show="data.communication_with === 'Email'"
                v-model="data.messageEmail"
                :options="editorOptionEmail"
                class="quill-editor-custom"
              />
            <quill-editor
              v-show="data.communication_with === 'Whatsapp'"
              :options="editorOption"
              v-model="data.messageWhatsapp"
              class="quill-editor-custom"
            />  
          </div>
        </div>
        <div class="col-xl-12">
          <div class="form-group">
            <div class="grey mb5">Attachment</div>
            <div class="row align-items-center g-1 mb5">
              <div class="col-10 col-sm-8 col-xl-7">
                <div class="box-form-comment flex-column px-3" @click="$refs.input.click()">
                  <div v-if="files.length > 0">
                    <div v-for="file, index in files" :key="index">
                      <a @click.prevent="previewDocs(file.name)" href="#" class="blue" style="cursor: pointer;">{{file.name}}</a>
                      <button @click.stop="deleteFile(index)" class="btn btn-close-file"><span class="red icon-ico-close"></span></button>
                    </div>
                  </div>
                  <span v-else>Choose File</span>
                </div>
              </div>
              <div class="col-2 col-sm-2 col-xl-1">
                <div class='file'>
                  <label for='input-file'>
                    <span class="icon-ico-attach"></span>
                  </label>
                  <input multiple id='input-file' type='file' :accept="acceptTypes" @change="uploadAttach" ref="input"/>
                </div>
              </div>
            </div>
            <small v-if="files.length == 0" class="text-danger text-sm px-3">*format files: .jpg, .png, .pdf with max size 5mb</small>

          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button :disabled="disabledBtn" @click.prevent="sendBroadcast" class="btn btn-orange" type="button">Broadcast</button>
      </div>
    </div>
  </main>
</template>

<script>
// Rich Text
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor, Quill} from 'vue-quill-editor'
import {ImageExtend, QuillWatch} from 'quill-image-extend-module'
Quill.register('modules/ImageExtend', ImageExtend)

window.Quill = Quill
const ImageResize = require('quill-image-resize-module').default
Quill.register('modules/imageResize', ImageResize);

import axios from '@/axios';
import Vue from 'vue'
import {showErrorMessage} from '@/utils';


export default {
  components: {
    quillEditor
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic'],
            ['clean']   
          ],
        }
      },

      editorOptionEmail: {
        modules: {
          imageResize: {
            modules: ['Resize']
          },
          ImageExtend: {
            loading: true,
            name: 'image',
            size: 5,
            action: `${process.env.VUE_APP_BASE_URL_API}/broadcasts/upload`,
            headers: (xhr) => {
              xhr.setRequestHeader('Accept','application/json')
              xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('token')}`)
            }, 
            response: (res) => {
              return res.data.data.url
            },
          },
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              [{ 'header': 1 }, { 'header': 2 }],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              ['link', 'image'],
              ['clean']   
            ],
            handlers: {
              image: function() {
                let fileInput = document.querySelector('.quill-image-input');
                if (fileInput === null) {
                    fileInput = document.createElement('input');
                    fileInput.setAttribute('type', 'file');
                    fileInput.setAttribute('accept', 'image/*');
                    fileInput.classList.add('quill-image-input');
                    fileInput.style.display = 'none'
                    
                    fileInput.addEventListener('change', function () {
                        let self = QuillWatch.active
                        self.file = fileInput.files[0]
                        fileInput.value = ''

                        if(!self.file.type.includes('image/')){
                          Vue.notify({ type: 'error', title: 'Error', text: 'Format file not supported'})
                          return
                        }
                        
                        if (self.config.size && self.file.size >= self.config.size * 1024 * 1024 && self.config.loading) {
                          Vue.notify({ type: 'error', title: 'Error', text: 'Total file size must be lower than 5mb'})
                          return 
                        }
                        if (self.config.action) {
                          self.uploadImg(self.config.change)
                        } else {
                          self.toBase64()
                        }
                    })
                    document.body.appendChild(fileInput);
                }
                QuillWatch.emit(this.quill.id)
              }
            },
          }
        }
      },

      listLabels: [],
      labels: [],
      listCustomers: [],
      customers: [],
      acceptTypes: '.pdf, .png, .jpg, .jpeg',
      types: ['Group Label', 'Customer'],
      communication_with: ['Email', 'Whatsapp'],
      files: [],

      data: {
        broadcast_type: 'Group Label',
        title: '',
        communication_with: 'Email',
        messageEmail: '',
        messageWhatsapp: '',
      },
      totalFilesSize: 0,

      debounce: null,
      totalCustomers: 0,
      query: '',
      onLoadMore: false,
      initialPageCust: 1
    }
  }, 
  methods: {
    getData() {
      this.$store.dispatch('toggleLoadingProgress', true)

      const getCustomers = axios.get('/customers?per_page=10&page=1')
      const getLabels = axios.get('/labels')
      Promise.all([getLabels, getCustomers]).then(res => {
        this.$store.dispatch('toggleLoadingProgress', false)  
        const labels = res[0].data.data
        labels.map(label => {
          this.listLabels.push({
            label: label.name,
            value: label.id,
            color: label.color,
          })
        })

        const customers = res[1].data.data
        this.totalCustomers = customers.total
        customers.data.map(customer => {
          this.listCustomers.push({
            label: customer.name,
            value: customer.id,
          })
        })
      })
    },
    
    loadMoreCustomers(){
      this.onLoadMore = true
      this.initialPageCust++
      this.getCustomers(null, this.initialPageCust)
        .then(() => {
          this.onLoadMore = false
        })
    },
    debounceSearch(query, loading){
      clearTimeout(this.debounce)
      this.query = query
      this.initialPageCust = 1
      this.debounce = setTimeout(() => {
        this.listCustomers = []
        this.getCustomers(loading, this.initialPageCust)
      }, 500)
    },
    getCustomers(loading, page) {
      loading && loading(true)
      return axios.get(`/customers?per_page=10&page=${page}&search=${this.query}`)
        .then(res => {
          loading && loading(false)
          const customers = res.data.data
          this.totalCustomers = customers.total
          customers.data.map(customer => {
            this.listCustomers.push({
              label: customer.name,
              value: customer.id,
            })
          })
      }).catch(err => {
          showErrorMessage(err)
          loading && loading(false)
      }) 
    },


    deleteLabelFromCurrent(value) {
      this.labels = this.labels.filter(item => item.value !== value)
    },
    deleteFile(index) {
      let file = this.files.splice(index, 1)
      this.totalFilesSize -= file[0].size
    },
    uploadAttach(e) {
      let fileSizeLimit = 5120; // In MB
      let files = e.target.files
      let exitLoop = false
      Array.from(files).forEach(file => {
        // exit loop when totalfilessize in bigger than filesizelimit
        if(exitLoop) return

        if(file.type !== 'application/pdf' && file.type !== 'image/png' && file.type !== 'image/jpeg'){
          this.$notify({ type: 'error', title: 'Error', text: 'Format file not supported'})
          return
        }  

        this.totalFilesSize += file.size

        if (this.totalFilesSize <= fileSizeLimit * 1024) {    
          this.files.push(file)
          e.target.value = ''
        } else {
          this.totalFilesSize -= file.size
          this.$notify({ type: 'error', title: 'Error', text: 'Total file size must be lower than 5mb'})
          exitLoop = true
        }
      })
    },
    previewDocs(fileName) {
      let file = this.files.filter(({name}) => name === fileName)
      let url = URL.createObjectURL(file[0])
      window.open(url)
    },
    sendBroadcast(){
      this.$store.dispatch('toggleLoadingProgress', true)

      let form = new FormData()
      let text = this.data.communication_with === 'Email' ? this.data.messageEmail : this.data.messageWhatsapp
      const message = text.replace("<p><br></p>", "<br>");
      form.append('broadcast_type', this.data.broadcast_type)
      form.append('title', this.data.title)
      form.append('communication_with', this.data.communication_with)
      form.append('message', message)

      if(this.files.length > 0) {
        this.files.forEach(file => {
          form.append('attachments[]', file)
        })
      }

      if(this.data.broadcast_type === 'Group Label') {
        this.labels.forEach(({value}) => {
          form.append('labels[]', value)
        })
      } else {
        this.customers.forEach(({value}) => {
          form.append('customers[]', value)
        })
      }

      axios.post(`/broadcasts`, form, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
      }).then(() => {
          this.$store.dispatch('toggleLoadingProgress', false)
          this.$notify({ type: 'success', title: 'Success', text: 'Success send broadcast' })
          this.$router.push({name: 'Broadcast'})
      }).catch(error => {
          showErrorMessage(error)
      })
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    disabledBtn() {
      return this.data.title === '' || (this.data.communication_with === 'Email' && this.data.messageEmail === '') || (this.data.communication_with === 'Whatsapp' && this.data.messageWhatsapp === '') || (this.data.broadcast_type === 'Group Label' && this.labels.length === 0) || (this.data.broadcast_type === 'Customer' && this.customers.length === 0)
    },
  },
}
</script>

<style>
.ql-container .ql-editor {
  max-height: 360px;
  width: 100%;
  overflow-y: auto;
}
</style>