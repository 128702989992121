<template>
  <main>
    <a @click.prevent="$router.go(-1)" href="#" class="link-back"><span class="icon-ico-arrow-left"></span> Add PIC</a>
    <div class="white-box p30">
      <div class="row">
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Address ID</div>
            <input type="text" class="form-control" :value="$route.params.idAddress" disabled>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Label</div>
            <v-select placeholder="Choose One" :closeOnSelect="false" multiple v-model="labels" :options="listLabels.filter(o => labels.indexOf(o) < 0)">
              <template
                #selected-option-container="{ option }"
              >
                <div class="selected_custom" :style="`background-color: ${option.color}1a;color: ${option.color}`">
                  <span>{{ option.label }}</span>
                  <button @click.capture="deleteLabelFromCurrent(option.value)" class="vs__deselect"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path></svg></button>
                </div>
              </template>
            </v-select>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Name<span class="text-danger">*</span></div>
            <input type="text" class="form-control" v-model="data.name">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Phone number<span class="text-danger">*</span></div>
            <input v-validate="'required|numeric'" @keypress="checkValidationNumber" v-model="data.phone_number" type="tel" class="form-control" name="Phone Number">
            <span class="text-danger text-sm" v-show="errors.has('Phone Number')">{{ errors.first('Phone Number') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Email<span class="text-danger">*</span></div>
            <input v-validate="'required|email'" v-model="data.email" type="email" class="form-control" name="Email">
            <span class="text-danger text-sm" v-show="errors.has('Email')">{{ errors.first('Email') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Date of Birth</div>
            <date-picker v-model="date_of_birth" :config="options.date"></date-picker>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Hobby</div>
            <input type="text" class="form-control" v-model="data.hobby">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Position<span class="text-danger">*</span></div>
            <input type="text" class="form-control" v-model="data.position">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Role PIC</div>
            <input type="text" class="form-control" v-model="data.role">
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button @click="addPic" :disabled="disabledBtn" class="btn btn-orange" type="button">Save</button>
      </div>
    </div>
  </main>
</template>

<script>
import axios from '@/axios'
import {checkValidationNumber, showErrorMessage} from '@/utils'
import moment from 'moment'
export default {
  data() {
    return {
      checkValidationNumber,
      options: {
        date: {
          format: 'DD MMMM YYYY',
          maxDate: moment().subtract(1, 'days')        }
      },
      date_of_birth: null,
      listLabels: [],
      labels: [],
      data: {
        name: '',
        phone_number: '',
        email: '',
        date_of_birth: '',
        hobby: '',
        position: '',
        role: '',
        labels: []
      }
    }
  },
  methods: {
    getLabels() {
      this.$store.dispatch('toggleLoadingProgress', true)
      
      axios.get('/labels')
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)  
          let {data} = res.data
          data.map(label => {
            this.listLabels.push({
              label: label.name,
              value: label.id,
              color: label.color,
            })
          })
        })
        .catch(err => {
          showErrorMessage(err)
        })
    },
    deleteLabelFromCurrent(value) {
      this.labels = this.labels.filter(item => item.value !== value)
    },
    addPic(){
      this.$validator.validate().then(valid => {
        if(valid){
          if(!this.disabledBtn){
            this.$store.dispatch('toggleLoadingProgress', true)
            let date_of_birth = this.date_of_birth === '' || this.date_of_birth ? moment(new Date(this.date_of_birth)).format('YYYY-MM-DD') : ''
            let labels = []
            this.labels.map(label => {
              labels.push(label.value)
            })
            const data = {
              name: this.data.name,
              phone_number: this.data.phone_number,
              email: this.data.email,
              date_of_birth,
              hobby: this.data.hobby,
              position: this.data.position,
              role: this.data.role,
              labels
            }
      
            axios.post(`/customers/${this.$route.params.id}/addresses/${this.$route.params.idAddress}/pics`, data)
              .then(() => {
                this.$store.dispatch('toggleLoadingProgress', false)
                this.$notify({ type: 'success', text: 'Success add pic' }) 
                this.$router.push({path: `/customer/${this.$route.params.id}/address/${this.$route.params.idAddress}/pic`})
              }).catch(error => {
                showErrorMessage(error)
              })
          }
        }
      })
    }
  },
  computed: {
    disabledBtn() {
      return this.data.name === '' ||
             this.data.phone_number === '' ||
             this.data.email === '' ||
             this.data.position === ''
    }
  },
  mounted() {
    this.getLabels()
  }
}
</script>

<style>
.v-list .v-list-item--active { 
  background-color: green!important; 
}
.v-list .v-list-item--active .v-list-item__title {
  color: #ffd54f !important;
}
</style>