const mutations = {
  TOGGLE_MENU_RESPONSIVE(state, val) { state.showMenuResponsive       = val },
  TOGGLE_LOADING_PROGRESS(state, val) { state.loading       = val },
  SET_PERMISSIONS(state, val) { state.permissions       = val },

  UPDATE_USER_INFO(state, payload) {
    state.AppActiveUser = payload
  },
  UPDATE_ROLE_ID(state, payload) {
    state.RoleIdUser = payload
  },
}

export default mutations