<template>
  <main>
    <a @click.prevent="$router.go(-1)" href="#" class="link-back"><span class="icon-ico-arrow-left"></span> Add Employee</a>
    <div class="white-box p30">
      <div class="row">
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">NIK</div>
            <input v-validate="'required|numeric'" v-model="data.nik" type="text" class="form-control" name="NIK">
            <span class="text-danger text-sm" v-show="errors.has('NIK')">{{ errors.first('NIK') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Employee name</div>
            <input v-validate="'required'" v-model="data.name" type="text" class="form-control" name="Employee Name">
            <span class="text-danger text-sm" v-show="errors.has('Employee Name')">{{ errors.first('Employee Name') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Position</div>
            <select v-if="positions" class="form-select" v-model="data.position">
              <option disabled value="">Choose One</option>
              <option v-for="(position, index) in positions" :key="index" :value="position.id">{{position.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Phone number</div>
            <input v-validate="'required|numeric'" v-model="data.phone_number" type="tel" class="form-control" name="Phone Number">
            <span class="text-danger text-sm" v-show="errors.has('Phone Number')">{{ errors.first('Phone Number') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Email</div>
            <input v-validate="'required|email'" v-model="data.email" type="text" class="form-control" name="Email">
            <span class="text-danger text-sm" v-show="errors.has('Email')">{{ errors.first('Email') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Password</div>
            <div class="form-group-input">
            <input v-validate="'required|min:8'" v-model="data.password" :type="type.password" name="Password" class="form-control" id="password-field2">
            <div class="for-btn-show">
              <button @click.prevent="togglePassword('password')" toggle="#password-field2" class="btn btn-show"></button>
            </div>
            <span class="text-danger text-sm error-message" v-show="errors.has('Password')">{{ errors.first('Password') }}</span>
          </div>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button @click.prevent="addEmployee" class="btn btn-orange" type="button" :disabled="disabledBtn">Save</button>
      </div>
    </div>
  </main>
</template>

<script>
import axios from '@/axios'
import {showErrorMessage} from '@/utils';

export default {
  data() {
    return {
      type: {
        password: 'password',
      },
      data: {
        nik: '',
        name: '',
        position: '',
        phone_number: '',
        email: '',
        password: ''
      },
      positions: []
    }
  },
  methods: {
    togglePassword(form_type) {
      if(this.type[form_type] === 'password') {
          this.type[form_type] = 'text'
       } else {
          this.type[form_type] = 'password'
       }
    },
    getRoles() {
      this.$store.dispatch('toggleLoadingProgress', true)
      
      axios.get(`/roles`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)
          this.positions = res.data.data
        })
        .catch(err => {
          showErrorMessage(err)
        })
    },
    addEmployee() {
      if(!this.disabledBtn){
        this.$validator.validate().then(valid => {
          if (valid) {
              this.$store.dispatch('toggleLoadingProgress', true)
              axios.post('/employees', this.data).then(() => {
                this.$notify({ type: 'success', text: 'Success add employee' })
                this.$store.dispatch('toggleLoadingProgress', false)
                this.$router.push({name: 'EmployeeManagement'})
              }).catch(error => {
                showErrorMessage(error)
              })
          }
        });
      }
    }
  },
  computed: {
    disabledBtn() {
      return this.data.nik === '' ||
             this.data.name === '' ||
             this.data.position === null ||
             this.data.phone_number === '' ||
             this.data.email === '' ||
             this.data.password === ''
    },
  },
  mounted() {
    this.getRoles(0)
  }
}
</script>

<style>

</style>