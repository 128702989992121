<template>
  <main>
    <a @click.prevent="$router.go(-1)" href="#" class="link-back"><span class="icon-ico-arrow-left"></span> Feedback & Complaint Detail</a>
    <div class="row">
      <div class="col-lg-5 col-sm-5 col-xl-6">
        <div class="white-box mm15 p30">
          <div class="popup-item pt-0 wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">Status</div>
                <div class="status" :class="status_fac(fac.status)">{{fac.status}}</div>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">Type</div>
                <span>{{fac.type}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">ID</div>
                <span>{{fac.id}}</span>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">Date and Time</div>
                <span>{{moment(fac.date_time).format('DD MMM YYYY')}}, {{moment(fac.date_time).format('HH:mm')}} WIB</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Subject</div>
                <span class="description-fac" v-html="fac.subject"></span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Description</div>
                <span class="description-fac" v-html="fac.description"></span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">Category</div>
                <span>{{fac.category.name}}</span>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">Priority</div>
                <div class="status" :class="priority_fac(fac.priority)">{{fac.priority}}</div>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div v-if="fac.labels.length" class="col-sm-6 col-6">
                <div class="grey">Label</div>
                <div v-for="(label, index) in fac.labels" :key="index" class="status" :style="`background-color: ${label.color}1a;color: ${label.color}; margin-right: 3px;`">{{label.name}}</div>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">Label</div>
                <div>-</div>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div v-if="fac.assignees.length" class="col-sm-12">
                <div class="grey">Assigned to</div>
                <div v-for="(employee, index) in fac.assignees" :key="index" class="status ori" style="margin-right: 3px;">{{employee.name}}</div>
              </div>
              <div v-else class="col-sm-12">
                <div class="grey">Assigned to</div>
                <div>-</div>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">Customer</div>
                <span>{{fac.customer ? fac.customer.name : '-'}}</span>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">Product</div>
                <span>{{fac.product || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Lot No.</div>
                <span>{{fac.lot_no || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">PIC</div>
                <span>{{fac.pic.name}}</span>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">No. Hp PIC</div>
                <span>{{fac.no_hp_pic}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Transporter</div>
                <span>{{fac.transporter ? fac.transporter.name : '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">No. Surat Jalan</div>
                <span>{{fac.no_surat_jalan || '-'}}</span>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">Tgl Surat Jalan</div>
                <span>{{moment(fac.tgl_surat_jalan).format('DD-MM-YYYY')}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-sm-7 col-xl-6">
        <div class="white-box p30">
          <div class="ft-18 font-bold mb15">Comments</div>

          <div class="mb24">
            <div class="row align-items-center g-1 mb5">
              <div class="col-10 col-sm-10 col-xl-11">
                <div class="box-form-comment ">
                  <input @keyup.enter.prevent="checkComment" class="form-control" type="text" placeholder="Write your comment here..." v-model="message">
                  <button :disabled="disabledBtn" @click.prevent="addComment" class="btn btn-comment" type="button">Send</button>
                </div>
              </div>
              <div class="col-2 col-sm-2 col-xl-1">
                <div class='file'>
                  <label for='input-file'>
                    <span class="icon-ico-attach"></span>
                  </label>
                  <input multiple id='input-file' type='file' :accept="types" @change="uploadAttach"/>
                </div>
              </div>
            </div>
            <small v-if="files.length === 0" class="text-danger text-sm px-3">*format files: .jpg, .png, .pdf with max size 5mb</small>

            <div v-if="files.length > 0">
              <div class="attach-file" v-for="(file, index) in files" :key="index">
                <a @click.prevent="previewDocs(null, file.name)" href="#" class="blue"><span class="icon-ico-attach grey"></span> &nbsp; <span
                    class="icon-ico-file"></span> {{file.name}}</a>
                <button @click="deleteFile(index)" class="btn btn-close-file"><span class="red icon-ico-close"></span></button>
              </div>
            </div>
          </div>

          <div v-for="(comment, index) in fac.comments" :key="index" class="box-comment-item mb15">
            <div class="inner-ci">
              <div class="comment-side">
                <div class="d-flex mb15">
                  <div class="for-pic">
                    <div class="img-comment">
                      <img src="../../assets/img/user.png" class="w-100">
                    </div>
                  </div>
                  <div class="for-inner-comment">
                    <div class="name-commenter">{{comment.commented_by.name}} <span class="time-comment ml5 font-normal">{{moment(comment.created_at).fromNow()}}</span></div>
                    <div class="comment-section">
                      {{comment.message}}
                    </div>
                    <div v-if="comment.attachments.length > 0">
                      <div v-for="(attachment,index) in comment.attachments" :key="index" class="attach-file">
                        <a @click.prevent="previewDocs(attachment.filepath)"  class="blue" style="cursor: pointer;"><span class="icon-ico-attach grey"></span> &nbsp; <span
                            class="icon-ico-file"></span> {{attachment.filename}}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from '@/axios'
import moment from 'moment'
import Pusher from 'pusher-js'
import {showErrorMessage} from '@/utils';


export default {
  data() {
    return {
      moment,
      types: '.pdf, .png, .jpg, .jpeg',
      files: [],
      totalFilesSize: 0,
      fac: null,
      message: '',
      disabledBtn: false
    }
  },
  methods: {
    getDataForm() {
      this.$store.dispatch('toggleLoadingProgress', true)  
      const getFac = axios.get(`/feedback-and-complaints/${this.$route.params.id}`)

      Promise.all([getFac]).then(res => {
        this.$store.dispatch('toggleLoadingProgress', false)  
        this.fac = res[0].data.data

        let pusher = new Pusher(process.env.VUE_APP_KEY_PUSHER, { cluster: process.env.VUE_APP_CLUSTER_PUSHER })
        pusher.subscribe(`fac.${this.fac.id}`)
        pusher.bind('fac-comment.sent', ({facComment: data}) => {
          this.fac.comments.unshift(data)
        })
      }).catch(err => {
        showErrorMessage(err)
      })
    },
    uploadAttach(e) {
      let fileSizeLimit = 5120; // In MB
      let files = e.target.files
      let exitLoop = false
      Array.from(files).forEach(file => {
        // exit loop when totalfilessize in bigger than filesizelimit
        if(exitLoop) return

        if(file.type !== 'application/pdf' && file.type !== 'image/png' && file.type !== 'image/jpeg'){
          return alert('Format file not supported')
        }  

        this.totalFilesSize += file.size

        if (this.totalFilesSize <= fileSizeLimit * 1024) {    
          this.files.push(file)
          e.target.value = ''
        } else {
          this.totalFilesSize -= file.size
          this.$notify({ type: 'error', title: 'Error', text: 'Total file size must be lower than 5mb'})
          exitLoop = true
        }
      })
    },
    previewDocs(path, fileName) {
      if(path) {
        let url = process.env.VUE_APP_BASE_URL_API
        let newUrl = url.replace('/api', '/');
        window.open(newUrl+path)
      } else {
        let file = this.files.filter(({name}) => name === fileName)
        let url = URL.createObjectURL(file[0])
        window.open(url)
      }
    },
    checkComment() {
      if(!this.disabledBtn){
        this.addComment()
      }
    },
    deleteFile(index) {
      let file = this.files.splice(index, 1)
      this.totalFilesSize -= file[0].size
    },
    addComment() {
      if(this.message !== '') {
        this.disabledBtn = true
        let form = new FormData()
        form.append('message', this.message)

        if(this.files.length > 0) {
          this.files.forEach(file => {
            form.append('attachments[]', file)
          })
        }
        axios.post(`/feedback-and-complaints/${this.$route.params.id}/comments`, form, {
          headers: {
              'Content-Type': 'multipart/form-data'
          },
        })
          .then(() => {
            this.message = ''
            this.files = []
            this.disabledBtn = false
          }).catch(error => {
            this.disabledBtn = false
            showErrorMessage(error)
          })
      }
   },
    status_fac(status) {
      return status === 'New' ? 'green' : status === 'In Progress' ? 'yellow' : status === 'No Status' ? 'red' : status === 'Checked In' ? 'blue' : status === 'Reopened' ? 'purple' : 'grey'
    },
    priority_fac(status) {
      return status === 'Low' ? 'green' : status === 'Medium' ? 'yellow' : 'red'
    },
  },
  mounted() {
    this.getDataForm()
  }
}
</script>

<style>
.description-fac p, .description-fac ol, .description-fac ul  {
  margin-bottom: 0 !important;
}
</style>