<template>
  <div class="white-box mt-3">
    <div class="p30">
      <div  v-if="showActivity">
        <div class="ft-16 font-bold mb15">Activity</div>
        <div class="row">
          <div class="col-xl-3 col-sm-6" v-if="viewSchedules">
            <a @click.prevent="redirectToSchedule" href="#" class="box-item-dasboard p20 mb30">
              <div class="icon-bid">
                <img src="../assets/img/icon-invitation.png" class="img-fluid">
              </div>
              <div class="name-bid">
                <div class="for-total">
                  <div class="for-number">{{dashboard.new_invitations}}</div>
                  <div class="deco"></div>
                </div>
                <div class="desc-bid">
                  New Invitation
                </div>
              </div>
            </a>
          </div>
          <div class="col-xl-3 col-sm-6">
            <router-link to="/feedback" class="box-item-dasboard p20 mb30">
              <div class="icon-bid">
                <img src="../assets/img/icon-comment.png" class="img-fluid">
              </div>
              <div class="name-bid">
                <div class="for-total">
                  <div class="for-number">{{dashboard.new_facs}}</div>
                  <div class="deco"></div>
                </div>
                <div class="desc-bid">
                  New Complaint and Feedback
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6" v-if="viewAddEmployee">
          <div class="ft-16 font-bold mb15">User</div>
          <div class="box-item-dasboard p10 mb30">
            <div class="row g-1 align-items-center">
              <div class="col-sm-8 for-land">
                <div class="row g-2 align-items-center mm15">
                  <div class="col-2"><img src="../assets/img/icon-user.png" class="img-fluid"></div>
                  <div class="col-10">You can add a new user here</div>
                </div>
              </div>
              <div class="col-sm-4 for-land">
                <router-link to="/employee/add" class="btn btn-orange w-100">Add New</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6" v-if="viewAddBroadcast">
          <div class="ft-16 font-bold mb15">Quick Broadcast</div>
          <div class="box-item-dasboard p10 mb30">
            <div class="row g-1 align-items-center">
              <div class="col-sm-8 for-land">
                <div class="row g-0 align-items-center mm15">
                  <div class="col-2"><img src="../assets/img/icon-boradcast.png" class="img-fluid"></div>
                  <div class="col-10">You can add a new broadcast here</div>
                </div>
              </div>
              <div class="col-sm-4 for-land">
                <router-link to="/broadcast/add" class="btn btn-orange w-100">Add New</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12" v-if="dashboard">
          <div class="ft-16 font-bold mb15">Target</div>
          <div class="font-500 mb24"><span class="icon-ico-date yellow ft-16"></span> {{currentDate}}</div>
          <div id="dash" class="row">
            <div class="col-sm-4 col-xl-3">
              <div class="target-box-item mb30 p20">
                <div class="d-flex">
                  <div><img src="../assets/img/icon-target.png" class="img-fluid"></div>
                  <div class="ml15">
                    <div class="head-target mb5">{{dashboard.total_target}}<span>ton</span></div>
                    <span>Total Target</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4 col-xl-3">
              <div class="target-box-item mb30 p20">
                <div class="d-flex">
                  <div><img src="../assets/img/icon-target-2.png" class="img-fluid"></div>
                  <div class="ml15">
                    <div class="head-target mb5">{{dashboard.total_sales}}<span>ton</span></div>
                    <span>Total Sales</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4 col-xl-3">
              <div class="target-box-item mb30 p20">
                <div class="d-flex">
                  <div><img src="../assets/img/icon-target-3.png" class="img-fluid"></div>
                  <div class="ml15">
                    <div class="head-target mb5">{{dashboard.remaining_target}}<span>ton</span></div>
                    <span>Remaining Target</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12" v-if="viewAllSchedules">
          <div class="ft-16 font-bold mb15">Ongoing Schedule</div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Schedule Type</th>
                  <th>About</th>
                  <th>Participants</th>
                  <th>Start Date and Time</th>
                  <th>End Date and Time</th>
                  <th>Created by</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody v-if="ongoing.schedules.length > 0">
                <tr v-for="(schedule, index) in ongoing.schedules" :key="schedule.id">
                  <td>{{getOverallIndex(index, 'ongoing')}}</td>
                  <td>{{schedule.type.name}}</td>
                  <td>{{truncate(schedule.about,15)}}</td>
                  <td>{{assigneesEmployee(schedule.participants)}}</td>
                  <td>{{moment(schedule.start_datetime).format('DD MMM YYYY, HH : mm')}} WIB</td>
                  <td>{{moment(schedule.end_datetime).format('DD MMM YYYY, HH : mm')}} WIB</td>
                  <td>
                    <div v-if="schedule.invited_by" class="status" :style="`background-color: ${schedule.invited_by.roles[0].color}1a;color: ${schedule.invited_by.roles[0].color}`">{{schedule.invited_by.roles[0].name}}</div>
                    {{schedule.invited_by.name}}
                  </td>
                  <td>
                    <div class="status" :class="schedule.status === 'Closed' ? 'grey' : 'yellow'">{{schedule.status}}</div>
                  </td>
                  <td>
                    <button @click="showModalAction('ongoing', schedule.id)" class="btn btn-see rbe"><span class="icon-ico-eye"></span></button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="9" class="text-center">No data found</td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination :total="ongoing.pages.total" :current="ongoing.pages.current" @setCurrentPage="value => setCurrentPage(value, 'ongoing')" />

        </div>
        <div class="col-sm-12" v-if="viewAllSchedules">
          <div class="ft-16 font-bold mb15">Upcoming Schedule</div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Schedule Type</th>
                  <th>About</th>
                  <th>Participants</th>
                  <th>Start Date and Time</th>
                  <th>End Date and Time</th>
                  <th>Created by</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody v-if="upcoming.schedules.length > 0">
                <tr v-for="(schedule, index) in upcoming.schedules" :key="schedule.id">
                  <td>{{getOverallIndex(index, 'upcoming')}}</td>
                  <td>{{schedule.type.name}}</td>
                  <td>{{truncate(schedule.about,15)}}</td>
                  <td>{{assigneesEmployee(schedule.participants)}}</td>
                  <td>{{moment(schedule.start_datetime).format('DD MMM YYYY, HH : mm')}} WIB</td>
                  <td>{{moment(schedule.end_datetime).format('DD MMM YYYY, HH : mm')}} WIB</td>
                  <td>
                    <div v-if="schedule.invited_by" class="status" :style="`background-color: ${schedule.invited_by.roles[0].color}1a;color: ${schedule.invited_by.roles[0].color}`">{{schedule.invited_by.roles[0].name}}</div>
                    {{schedule.invited_by.name}}
                  </td>
                  <td>
                    <div class="status" :class="schedule.status === 'Closed' ? 'grey' : 'yellow'">{{schedule.status}}</div>
                  </td>
                  <td>
                    <button @click="showModalAction('upcoming', schedule.id)" class="btn btn-see rbe"><span class="icon-ico-eye"></span></button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="9" class="text-center">No data found</td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination :total="upcoming.pages.total" :current="upcoming.pages.current" @setCurrentPage="value => setCurrentPage(value, 'upcoming')" />

        </div>
      </div>
    </div>

    <div class="overlay" :class="{'overlayshow': modal.overlay}"></div>
    <!--Popup-->
    <div v-if="detailSchedule" id="rbm-content" class="popup-wrapper" :class="{'showing': modal.content}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center">
          <div class="font-bold">Detail Schedule</div>
          <button @click="showModalAction()" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="inner-scroll">
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">Status</div>
                <div class="status" :class="detailSchedule.status === 'Closed' ? 'grey' : 'yellow'">{{detailSchedule.status}}</div>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6  col-6">
                <div class="grey">Schedule Type</div>
                <span v-if="detailSchedule.type">{{detailSchedule.type.name || '-'}}</span>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">About</div>
                <span>{{detailSchedule.about || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Place</div>
                <span>{{detailSchedule.place || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">Start Date and Time</div>
                <span>{{moment(detailSchedule.start_datetime).format('DD MMM YYYY, HH : mm')}} WIB</span>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">End Date and Time</div>
                <span>{{moment(detailSchedule.end_datetime).format('DD MMM YYYY, HH : mm')}} WIB</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Participants</div>
                <div v-for="participant in detailSchedule.participants" :key="participant.nik" class="status ori" style="margin-right: 3px">{{participant.name}}</div>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Customer Name</div>
                <span>{{detailSchedule.customer_name || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Goals</div>
                <span>{{detailSchedule.goals || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item">
            <div class="row">
              <div class="col-sm-12">
                <div class="bg-yellow">
                  <div class="grey">Result</div>
                  <div class="result-schedule" v-if="detailSchedule.result" v-html="detailSchedule.result"></div>
                  <span v-else class="italic">-</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import moment from 'moment'
import {truncate, showErrorMessage} from '@/utils'
import {Pagination} from '../components'
export default {
  components: { Pagination },
  data() {
    return {
      truncate,
      moment,
      modal: {
        overlay: false,
        content: false,
      },
      dashboard: null,
      ongoing: {
        schedules: [],
        pages: {
          total: 1,
          current: 1
        },
        entries: 5
      },
      upcoming: {
        schedules: [],
        pages: {
          total: 1,
          current: 1
        },
        entries: 5
      },
      detailSchedule: null
    }
  },
  methods: {
    redirectToSchedule() {
      this.$router.push({
        name: 'Schedule',
        params: {
          tab: 'inv'
        }
      })
    },

    showModalAction(type, id) {
      this.modal.overlay = !this.modal.overlay
      if(this.modal.content === false){
        this.getDetailSchedule(id, type)
      }
      this.modal.content = !this.modal.content
    },
    getDetailSchedule(id, type) {
      if(type === 'ongoing') {
        let schedule = this.ongoing.schedules.filter(schedule => schedule.id === id)
        this.detailSchedule = schedule[0]
      }else if (type === 'upcoming'){
        let schedule = this.upcoming.schedules.filter(schedule => schedule.id === id)
        this.detailSchedule = schedule[0]
      }
    },

    getOverallIndex(index, type) {
      if(type === 'ongoing') {
        return this.ongoing.pages.current * this.ongoing.entries - this.ongoing.entries + index + 1
      }else if(type === 'upcoming'){
        return this.upcoming.pages.current * this.upcoming.entries - this.upcoming.entries + index + 1
      }
    },

    setCurrentPage(val, type) {
      if(type === 'ongoing') {
        this.ongoing.pages.current = val;
        this.getOngoingSchedule();
      } else if (type === 'upcoming') {
        this.upcoming.pages.current = val;
        this.getUpcomingSchedule();
      }
    },

    getDetailDashboard() {
      this.$store.dispatch('toggleLoadingProgress', true)
      axios.get('/dashboard').then(res => {
        this.$store.dispatch('toggleLoadingProgress', false)
        const {data} = res.data
        this.dashboard = data
      }).catch(err => {
        showErrorMessage(err) 
      })
      
    },

    getOngoingSchedule() {
      this.$store.dispatch('toggleLoadingProgress', true)
      axios.get(`/schedules/ongoing?per_page=${this.ongoing.entries}&page=${this.ongoing.pages.current}`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)
          this.ongoing.pages.total = res.data.data.last_page
          this.ongoing.pages.current = res.data.data.current_page
          let schedules = res.data.data.data
          schedules.map(schedule => {
            schedule.start_datetime = new Date(schedule.start_datetime)
            schedule.end_datetime = new Date(schedule.end_datetime)
          })
          this.ongoing.schedules = schedules
        })
        .catch(err => {
          showErrorMessage(err)
        })
    },
    getUpcomingSchedule() {
      this.$store.dispatch('toggleLoadingProgress', true)
      let tomorrow = moment().add(1, 'days').format('YYYY-MM-DD')
      axios.get(`/schedules?scheduled_by=all&per_page=${this.upcoming.entries}&page=${this.upcoming.pages.current}&start_date=${tomorrow}`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)
          this.upcoming.pages.total = res.data.data.last_page
          this.upcoming.pages.current = res.data.data.current_page
          let schedules = res.data.data.data
          schedules.map(schedule => {
            schedule.start_datetime = new Date(schedule.start_datetime)
            schedule.end_datetime = new Date(schedule.end_datetime)
          })
          this.upcoming.schedules = schedules
        })
        .catch(err => {
          showErrorMessage(err)
        })
    },
    assigneesEmployee(assignees){
      let items = assignees.map(item => item.name)
      return truncate(items.join(', '), 20)
    },
  },
  mounted(){
    this.getOngoingSchedule()
    this.getUpcomingSchedule()
    this.getDetailDashboard()
  },
  computed: {
    AppActiveUser()     { return this.$store.state.AppActiveUser },
    viewSchedules() {
      return this.AppActiveUser.permissions.includes('View My Schedules') || this.AppActiveUser.permissions.includes('View All Schedules')
    },
    viewAllSchedules() {
      return this.AppActiveUser.permissions.includes('View All Schedules')
    },
    viewFacs() {
      return this.AppActiveUser.permissions.includes('View All Feedback and Complaints')
    },
    viewAddEmployee() {
      return this.AppActiveUser.permissions.includes('Create Employee')
    },
    viewAddBroadcast() {
      return this.AppActiveUser.permissions.includes('Create Broadcast')
    },
    showActivity() {
      return this.dashboard && (this.viewSchedules || this.viewFacs)
    },
    currentDate() {
      return moment().format('MMMM YYYY')
    }
  }
}
</script>

<style>

</style>