<template>
  <main v-if="permissions">
    <a @click.prevent="$router.go(-1)" href="#" class="link-back"><span class="icon-ico-arrow-left"></span> Add Role</a>
    <div class="white-box p30">
      <div class="row">
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Name role</div>
            <input v-validate="'required'" v-model="data.name" type="text" class="form-control" name="Role Name">
            <span class="text-danger text-sm" v-show="errors.has('Role Name')">{{ errors.first('Role Name') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Color</div>
            <div class="box-pick">
              <div class="color-picker showed">
                <label for="colorPicker" class="d-flex align-items-center text-uppercase grey">
                  <input type="color" id="colorPicker" v-model="data.color" ref="picker">
                  <span>{{currentColor}}</span>
                </label>
              </div>
              <div>
                <button @click="$refs.picker.click()" class="btn btn-for-pick">Select color</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Atasan</div>
            <select class="form-select" v-model="data.superior">
              <option disabled value="">Choose One</option>
              <option :value="null">Tidak Ada</option>
              <option v-for="(role, index) in roles" :key="Math.random(index)" :value="role.id">{{role.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6"></div>
        <div class="col-xl-12">
          <div class="form-group">
            <div class="text-black mb15">Access</div>
            <div class="row">
              <div class="col-lg-6">
                <div class="mb15">Communication History</div>
                <div class="form-check mb15" v-for="(permission, index) in communicationHistoryPermission" :key="Math.random(index)">
                  <input class="form-check-input" type="checkbox" :value="permission" :id="permission" v-model="data.permissions" @input="checkedPermission">
                  <label class="form-check-label" :for="permission">
                    {{permission}}
                  </label>
                </div>

                <div class="mb15">Schedule</div>
                <div class="form-check mb15" v-for="(permission, index) in schedulePermission" :key="Math.random(index)">
                  <input class="form-check-input" type="checkbox" :value="permission" :id="permission" v-model="data.permissions" @input="checkedPermission">
                  <label class="form-check-label" :for="permission">
                    {{permission}}
                  </label>
                </div>

                <div class="mb15">Target</div>
                <div class="form-check mb15" v-for="(permission, index) in targetPermission" :key="Math.random(index)">
                  <input class="form-check-input" type="checkbox" :value="permission" :id="permission" v-model="data.permissions" @input="checkedPermission">
                  <label class="form-check-label" :for="permission">
                    {{permission}}
                  </label>
                </div>

                <div class="mb15">Feedback and Complaint</div>
                <div class="form-check mb15" v-for="(permission, index) in fncPermission" :key="Math.random(index)">
                  <input class="form-check-input" type="checkbox" :value="permission" :id="permission" v-model="data.permissions" @input="checkedPermission">
                  <label class="form-check-label" :for="permission">
                    {{permission}}
                  </label>
                </div>

                <div class="mb15">Feedback and Complaint Labels</div>
                <div class="form-check mb15" v-for="(permission, index) in fncLabelsPermission" :key="Math.random(index)">
                  <input class="form-check-input" type="checkbox" :value="permission" :id="permission" v-model="data.permissions" @input="checkedPermission">
                  <label class="form-check-label" :for="permission">
                    {{permission}}
                  </label>
                </div>

                <div class="mb15">Broadcast</div>
                <div class="form-check mb15" v-for="(permission, index) in broadcastPermission" :key="Math.random(index)">
                  <input class="form-check-input" type="checkbox" :value="permission" :id="permission" v-model="data.permissions" @input="checkedPermission">
                  <label class="form-check-label" :for="permission">
                    {{permission}}
                  </label>
                </div>

                <div class="mb15">Employee Management</div>
                <div class="form-check mb15" v-for="(permission, index) in employeePermission" :key="Math.random(index)">
                  <input class="form-check-input" type="checkbox" :value="permission" :id="permission" v-model="data.permissions" @input="checkedPermission">
                  <label class="form-check-label" :for="permission">
                    {{permission}}
                  </label>
                </div>

              </div>
              <div class="col-lg-6">
                <div class="mb15">Role Management</div>
                <div class="form-check mb15" v-for="(permission, index) in rolePermission" :key="Math.random(index)">
                  <input class="form-check-input" type="checkbox" :value="permission" :id="permission" v-model="data.permissions" @input="checkedPermission">
                  <label class="form-check-label" :for="permission">
                    {{permission}}
                  </label>
                </div>
                <div class="mb15">Customer Management</div>
                <div class="form-check mb15" v-for="(permission, index) in customerPermission" :key="Math.random(index)">
                  <input class="form-check-input" type="checkbox" :value="permission" :id="permission" v-model="data.permissions" @input="checkedPermission">
                  <label class="form-check-label" :for="permission">
                    {{permission}}
                  </label>
                </div>
                <div class="mb15">Customer Management - Address</div>
                <div class="form-check mb15" v-for="(permission, index) in customerAddressPermission" :key="Math.random(index)">
                  <input class="form-check-input" type="checkbox" :value="permission" :id="permission" v-model="data.permissions" @input="checkedPermission">
                  <label class="form-check-label" :for="permission">
                    {{permission}}
                  </label>
                </div>
                <div class="mb15">Customer Management - PIC</div>
                <div class="form-check mb15" v-for="(permission, index) in customerPicPermission" :key="Math.random(index)">
                  <input class="form-check-input" type="checkbox" :value="permission" :id="permission" v-model="data.permissions" @input="checkedPermission">
                  <label class="form-check-label" :for="permission">
                    {{permission}}
                  </label>
                </div>
                <div class="mb15">Customer Management - Technical</div>
                <div class="form-check mb15" v-for="(permission, index) in customerTechnicalPermission" :key="Math.random(index)">
                  <input class="form-check-input" type="checkbox" :value="permission" :id="permission" v-model="data.permissions" @input="checkedPermission">
                  <label class="form-check-label" :for="permission">
                    {{permission}}
                  </label>
                </div>
                <div class="mb15">Label Management</div>
                <div class="form-check mb15" v-for="(permission, index) in labelPermission" :key="Math.random(index)">
                  <input class="form-check-input" type="checkbox" :value="permission" :id="permission" v-model="data.permissions" @input="checkedPermission">
                  <label class="form-check-label" :for="permission">
                    {{permission}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button @click.prevent="addRole" class="btn btn-orange" type="button" :disabled="disabledBtn">Save</button>
      </div>
    </div>
  </main>
</template>

<script>
import axios from '@/axios'
import {showErrorMessage} from '@/utils';

export default {
  data() {
    return {
      data: {
        name: '',
        color: '#000000',
        superior: '',
        permissions: []
      },
      roles: [],
    }
  },
  methods: {
    addRole() {
      if(!this.disabledBtn){
        this.$store.dispatch('toggleLoadingProgress', true)
        axios.post('/roles', this.data).then(() => {
          this.$store.dispatch('toggleLoadingProgress', false)
          this.$router.push({name: 'RoleManagement'})
          this.$notify({ type: 'success', title: 'Success', text: 'Success add role' })
        }).catch(error => {
          showErrorMessage(error)
        })
      }
    },
    checkedPermission(e) {
      let value = e.target.value
      let comhisPermissions = {
        view: 'View Communication Histories',
        items: ["Create Communication History", "Edit Communication History", "Delete Communication History", "View Communication Histories"]
      }
      let schedulesPermissions = {
        view: 'View My Schedules',
        items: ["Create Schedule", "Edit Schedule", "Delete Schedule", "View My Schedules"]
      }
      let targetPermissions = {
        view: 'View Target',
        items: ["Create Target", "Edit Target", "Delete Target", "Close Target", "Create Quantity Adjustment", "Edit Quantity Adjustment", "View Target"]
      }
      let employeePermissions = {
        view: 'View Employee Management',
        items: ["Create Employee", "Edit Employee", "Active/Inactive Employee", "View Employee Management"]
      }
      let broadcastPermissions = {
        view: 'View All Broadcasts',
        items: ["Create Broadcast", "View All Broadcasts"]
      }
      let fncPermissions = {
        view: 'View All Feedback and Complaints',
        items: ["Create Feedback and Complaint", "Edit Feedback and Complaint", "Delete Feedback and Complaint", "View All Feedback and Complaints"]
      }
      let fncLabelsPermissions = {
        view: 'View All Feedback and Complaint Labels',
        items: ["Create Feedback and Complaint Label", "Edit Feedback and Complaint Label", "Delete Feedback and Complaint Label", "View All Feedback and Complaint Labels"]
      }
      let rolePermissions = {
        view: 'View Role Management',
        items: ["Create Role Employee", "Edit Role Employee", "Delete Role Employee", "View Role Management"]
      }
      let labelPermissions = {
        view: 'View Label Management',
        items: ["Create Label Customer", "Edit Label Customer", "Delete Label Customer", "View Label Management"]
      }
      let customerPermissions = {
        view: 'View Customer Management',
        items: [
          "View Customer Management",
          "View Customer Address Management", 
          "Create Customer Address", 
          "Edit Customer Address", 
          "Edit Customer Address Status", 
          "Delete Customer Address", 
          "View PIC Management",
          "Create PIC",
          "Edit PIC",
          "Delete PIC",
          "View Technical Management",
          "Create Technical",
          "Edit Technical",
          "Delete Technical",
        ],
        subView: {
          view: 'View Customer Address Management',
          items: [
            "View Customer Address Management", 
            "Create Customer Address", 
            "Edit Customer Address", 
            "Edit Customer Address Status", 
            "Delete Customer Address",
            "View PIC Management",
            "Create PIC",
            "Edit PIC",
            "Delete PIC",
            "View Technical Management",
            "Create Technical",
            "Edit Technical",
            "Delete Technical"
          ],
          addressItems: [
            "View Customer Address Management", 
            "Create Customer Address", 
            "Edit Customer Address", 
            "Edit Customer Address Status", 
            "Delete Customer Address"],
          picItems: [
            "View PIC Management",
            "Create PIC",
            "Edit PIC",
            "Delete PIC"],
          technicalItems: [
            "View Technical Management",
            "Create Technical",
            "Edit Technical",
            "Delete Technical"]
        }
      }
      if(schedulesPermissions.items.includes(value)){
        this.setNewPermission(schedulesPermissions, value)
      } else if (employeePermissions.items.includes(value)){
        this.setNewPermission(employeePermissions, value)
      } else if (broadcastPermissions.items.includes(value)){
        this.setNewPermission(broadcastPermissions, value)
      } else if (rolePermissions.items.includes(value)){
        this.setNewPermission(rolePermissions, value)
      } else if (labelPermissions.items.includes(value)){
        this.setNewPermission(labelPermissions, value)
      } else if (comhisPermissions.items.includes(value)){
        this.setNewPermission(comhisPermissions, value)
      } else if (targetPermissions.items.includes(value)){
        this.setNewPermission(targetPermissions, value)
      } else if (fncPermissions.items.includes(value)){
        this.setNewPermission(fncPermissions, value)
      } else if (fncLabelsPermissions.items.includes(value)){
        this.setNewPermission(fncLabelsPermissions, value)
      } else if (customerPermissions.items.includes(value)){
        this.setNewPermissionCustomer(customerPermissions, value)
      }
    },
    setNewPermission(permission, value) {
      if(!this.data.permissions.includes(permission.view)){
        this.data.permissions.push(permission.view)
      } else if (value === permission.view) {
        this.data.permissions = this.data.permissions.filter(permiss => !permission.items.includes(permiss))
      }
    },
    setNewPermissionCustomer(permission, value) {
      if(!this.data.permissions.includes(permission.view)){
        this.data.permissions.push(permission.view)
      } else if (value === permission.view) {
        this.data.permissions = this.data.permissions.filter(permiss => !permission.items.includes(permiss))
      }

      if(!this.data.permissions.includes(permission.subView.view) && permission.subView.items.includes(value)){
        this.data.permissions.push(permission.subView.view)
      } else if (value === permission.subView.view) {
        this.data.permissions = this.data.permissions.filter(permiss => !permission.subView.items.includes(permiss))
      }

      let items = ["picItems", "technicalItems"]
      items.filter(item => {
        if(permission.subView[item].includes(value)) {
          if(!this.data.permissions.includes(permission.subView[item][0])){
            this.data.permissions.push(permission.subView[item][0])
          } else if (value === permission.subView[item][0]){
            this.data.permissions = this.data.permissions.filter(permiss => !permission.subView[item].includes(permiss))
          }
        }
      })
    },

    getRoles(){
      this.$store.dispatch('toggleLoadingProgress', true)  
      axios.get(`/roles`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)
          this.roles = res.data.data
        })
        .catch(err => {
          showErrorMessage(err)
        })
    }
  },
  computed: {
    permissions() { return this.$store.state.permissions },
    currentColor() {
      return this.data.color
    },
    disabledBtn() {
      return this.data.name === '' || this.data.superior === '' || this.data.permissions.length === 0
    },
    communicationHistoryPermission() {
      return this.permissions.filter(permission => permission.includes('Communication Histor'))
    },
    schedulePermission() {
      return this.permissions.filter(permission => permission.includes('Schedule'))
    },
    targetPermission() {
      return this.permissions.filter(permission => permission.includes('Target') || permission.includes('Quantity'))
    },
    fncPermission() {
      return this.permissions.filter(permission => permission === 'View All Feedback and Complaints' || permission === 'Create Feedback and Complaint' || permission === 'Edit Feedback and Complaint' || permission === 'Delete Feedback and Complaint')
    },
    fncLabelsPermission() {
      return this.permissions.filter(permission => permission === 'View All Feedback and Complaint Labels' || permission === 'Create Feedback and Complaint Label' || permission === 'Edit Feedback and Complaint Label' || permission === 'Delete Feedback and Complaint Label')
    },
    employeePermission() {
      return this.permissions.filter(permission => permission.includes('View Employee Management') || permission.includes('Create Employee') || permission.includes('Edit Employee') || permission.includes('Active/Inactive Employee'))
    },
    broadcastPermission() {
      return this.permissions.filter(permission => permission.includes('Broadcast'))
    },
    rolePermission() {
      return this.permissions.filter(permission => permission.includes('Role'))
    },
    customerPermission() {
      return this.permissions.filter(permission => permission.includes('Customer Management'))
    },
    customerAddressPermission() {
      return this.permissions.filter(permission => permission.includes('Customer Address'))
    },
    customerPicPermission() {
      return this.permissions.filter(permission => permission.includes('PIC'))
    },
    customerTechnicalPermission() {
      return this.permissions.filter(permission => permission.includes('Technical'))
    },
    labelPermission() {
      return this.permissions.filter(permission => permission === 'View Label Management' || permission === 'Create Label Customer' || permission === 'Edit Label Customer' || permission === 'Delete Label Customer')
    },
  },
  mounted() {
    this.getRoles()
  },
}
</script>

<style>

</style>