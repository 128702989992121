<template>
  <main>
    <div class="close-menu">
      <button @click.prevent="$store.dispatch('toggleMenuResponsive', false)" class="btn btn-close-menu"><span class="icon-ico-close"></span></button>
    </div>
    <div class="logo">
      <a @click.prevent="$router.push({name: 'Dashboard'})" href="#"><img src="../assets/img/logo.png" class="img-fluid"></a>
      <span>PT POLYTAMA PROPINDO</span>
    </div>
    <nav class="side-menu">
      <div class="accordion" id="accordion-order">
        <router-link :class="{'active': activeMenu('dashboard')}" class="link-side" to="/dashboard"><span class="icon-ico-dashboard"></span> Dashboard</router-link>
        <router-link v-if="hideCommunication" :class="{'active': activeMenu('communication-history')}" class="link-side" to="/communication-history"><span class="icon-ico-history"></span> Communication History</router-link>
        <router-link v-if="hideSchedule" :class="{'active': activeMenu('schedule')}" class="link-side" to="/schedule"><span class="icon-ico-schedule"></span> Schedule</router-link>
        <router-link v-if="hideTarget" :class="{'active': activeMenu('target')}" class="link-side" to="/target"><span class="icon-ico-target"></span> Target</router-link>
        <div class="side-tabs" v-if="hideFac || hideFacLabels">
          <div class="side-tab">
            <input type="checkbox" id="chck1" :checked="checkedMenu('feedback')">
            <label class="side-menu-btn" for="chck1"><span class="icon-ico-feedback"></span> Feedback and Complaint </label>
            <div class="menu-content">
              <ul class="link-inside">
                <li v-if="hideFac">
                  <router-link :class="{'inner-ls active': activeMenu('feedback') }" to="/feedback">Feedback and Complaint</router-link>
                </li>
                <li v-if="hideFacLabels">
                  <router-link :class="{'inner-ls active': activeMenu('feedback/label') }" to="/feedback/label">Label Management</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- <router-link  :class="{'active': activeMenu('feedback')}" class="link-side" to="/feedback"><span class="icon-ico-feedback"></span> Feedback and Complaint</router-link> -->
        <router-link v-if="hideBroadcast" :class="{'active': activeMenu('broadcast')}" class="link-side" to="/broadcast"><span class="icon-ico-broadcast"></span> Broadcast</router-link>
        <div class="side-tabs" v-if="hideEmployeeManag || hideRoleManag">
          <div class="side-tab">
            <input type="checkbox" id="chck2" :checked="checkedMenu('employee')">
            <label class="side-menu-btn" for="chck2"><span class="icon-ico-employee"></span> Employee </label>
            <div class="menu-content">
              <ul class="link-inside">
                <li v-if="hideEmployeeManag">
                  <router-link :class="{'inner-ls active': activeMenu('employee') }" to="/employee">Employee Management</router-link>
                </li>
                <li v-if="hideRoleManag">
                  <router-link :class="{'inner-ls active': activeMenu('role') }" to="/role">Role Management</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="side-tabs" v-if="hideCustomerManag || hideLabelManag">
          <div class="side-tab">
            <input type="checkbox" id="chck3" :checked="checkedMenu('customer')">
            <label class="side-menu-btn" for="chck3"><span class="icon-ico-customer"></span> Customer</label>
            <div class="menu-content">
              <ul class="link-inside">
                <li v-if="hideCustomerManag">
                  <router-link :class="{'inner-ls active': activeMenu('customer') }" to="/customer">Customer Management</router-link>
                </li>
                <li v-if="hideLabelManag">
                  <router-link :class="{'inner-ls active': activeMenu('label') }" to="/label">Label Management</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </main>
</template>

<script>
export default {
  methods: {
    checkedMenu(type) {
      if(type == 'employee'){
        return Boolean(this.$route.path.includes('employee') || this.$route.path.includes('role'))
      } else if(type == 'feedback') {
        return Boolean(this.$route.path.includes('feedback') || this.$route.path.includes('feedback/label'))
      } else if(type == 'customer') {
        if(!this.$route.path.includes('feedback/label')){
          return Boolean(this.$route.path.includes('customer') || this.$route.path.includes('label'))
        }
      }
    },
    activeMenu(type) {
      let viewUrl = `/${type}`
      let addUrl = `/${type}/add`
      let editUrl = `/${type}/edit`

      if(this.$route.path.includes('/feedback/label')) {
        return Boolean(this.$route.path === '/'+type || this.$route.path === '/'+type+'/add' || this.$route.path.includes('/'+type+'/edit'))
      }else {
        return Boolean(this.$route.path.includes(viewUrl) || this.$route.path.includes(addUrl) || this.$route.path.includes(editUrl))
      }

      // return Boolean(this.$route.path === '/'+type || this.$route.path === '/'+type+'/add' || this.$route.path === '/'+type+'/edit')
    },
  },
  computed: {
    AppActiveUser() { return this.$store.state.AppActiveUser },
    hideCommunication() {
      return this.AppActiveUser?.permissions.includes('View Communication Histories')
    },
    hideBroadcast() {
      return this.AppActiveUser?.permissions.includes('View All Broadcasts')
    },
    hideTarget() {
      return this.AppActiveUser?.permissions.includes('View Target')
    },
    hideFac() {
      return this.AppActiveUser?.permissions.includes('View All Feedback and Complaints')
    },
    hideFacLabels() {
      return this.AppActiveUser?.permissions.includes('View All Feedback and Complaint Labels')
    },
    hideSchedule() {
      return this.AppActiveUser?.permissions.includes('View All Schedules') || this.AppActiveUser?.permissions.includes('View My Schedules')
    },
    hideEmployeeManag() {
      return this.AppActiveUser?.permissions.includes('View Employee Management')
    },
    hideRoleManag() {
      return this.AppActiveUser?.permissions.includes('View Role Management')
    },
    hideLabelManag() {
      return this.AppActiveUser?.permissions.includes('View Label Management')
    },
    hideCustomerManag(){
      return this.AppActiveUser?.permissions.includes('View Customer Management')
    }
  }
}
</script>

<style>

</style>