<template>
  <div class="tab-main">
    <input checked="checked" class="radio-tab" id="tab1" type="radio" name="crm-tab">
    <label for="tab1" class="tab-main-item tab1">
      Report By Me
    </label>
    <input id="tab2" class="radio-tab" type="radio" name="crm-tab">
    <label for="tab2" class="tab-main-item tab2">
      Report By Employee
    </label>
    <input v-if="viewAllComHis" id="tab3" class="radio-tab" type="radio" name="crm-tab">
    <label v-if="viewAllComHis" for="tab3" class="tab-main-item tab3">
      All Communication History
    </label>


    <section class="tab-container">
      <div class="tabs tab1">
        <div class="white-box">
          <div class="header-toolbar">
            <div class="left-side">
              <div class="row align-items-center">
                <div class="col-xl-2 col-sm-2 col-3">
                  <div class="grey mm15"> Entries</div>
                </div>
                <div class="col-xl-3 col-sm-4 col-9">
                  <select class="form-select mm15" aria-label="Default select" v-model="reportByMe.entries">
                    <option v-for="(item, i) in listEntries" :key="i" :value="item.value">{{item.label}}</option>
                  </select>
                </div>
                <div class="col-xl-7 col-sm-6">
                  <a v-if="hideAddBtn" @click.prevent="addCommunication" href="#" class="btn btn-orange w-100 mm15">Add Communication</a>
                </div>
              </div>
            </div>
            <div class="right-side">
              <div class="row">
                <div class="col-xl-7 col-sm-6">
                  <input type="text" class="form-control mm15" placeholder="Search" @input="value => debounceSearch(value, 'byMe')">
                </div>
                <div class="col-xl-5 col-sm-6">
                  <button @click.prevent="showModalAction('filter')" class="btn btn-filter" type="button">Filter by</button>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th class="tnd">Date and Time</th>
                  <th>Customer</th>
                  <th>PIC</th>
                  <th>Comunication By</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="reportByMe.communication_histories.length > 0">
                <tr v-for="(item, index) in reportByMe.communication_histories" :key="index">
                  <td>{{getOverallIndex(index, 'byMe')}}</td>
                  <td>{{item.communicated_at ? displayDateAndTime(item.communicated_at) : '-'}}</td>
                  <td>{{item.customer.name || ''}}</td>
                  <td v-if="item.pic">{{item.pic.name}}</td>
                  <td v-else>-</td>
                  <td>{{item.communication_with || '-'}}</td>
                  <td>{{truncate(item.description || '-', 40)}}</td>
                  <td>
                    <a v-if="hideEditBtn" @click.prevent="editCommunication(item.id)" href="#" class="link-edit"><span class="icon-ico-pencil"></span></a>
                    <button @click.prevent="showModalAction('rbm', item.id)" class="btn btn-see rbm"><span class="icon-ico-eye"></span></button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="7" class="text-center">No data found</td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination :total="reportByMe.pages.total" :current="reportByMe.pages.current" @setCurrentPage="value => setCurrentPage(value, 'byMe')" />
        </div>
      </div><!-- end of tab1 -->

      <div class="tabs tab2">
        <div class="white-box">
          <div class="header-toolbar">
            <div class="left-side">
              <div class="row align-items-center">
                <div class="col-xl-2 col-sm-2 col-3">
                  <div class="grey mm15"> Entries</div>
                </div>
                <div class="col-xl-3 col-sm-4 col-9">
                  <select class="form-select mm15" aria-label="Default select" v-model="reportByEmployee.entries">
                    <option v-for="(item, i) in listEntries" :key="i" :value="item.value">{{item.label}}</option>
                  </select>
                </div>
                <div class="col-xl-7 col-sm-6">
                  <a v-if="hideAddBtn" @click.prevent="addCommunication" href="#" class="btn btn-orange w-100 mm15">Add Communication</a>
                </div>
              </div>
            </div>
            <div class="right-side">
              <div class="row">
                <div class="col-xl-7 col-sm-6">
                  <input type="text" class="form-control mm15" placeholder="Search" @input="value => debounceSearch(value)">
                </div>
                <div class="col-xl-5 col-sm-6">
                  <button @click.prevent="showModalAction('filterByEm')" class="btn btn-filter" type="button">Filter by</button>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th class="tnd">Date and Time</th>
                  <th>Customer</th>
                  <th>PIC</th>
                  <th>Comunication By</th>
                  <th>Description</th>
                  <th>Report by</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="reportByEmployee.communication_histories.length > 0">
                <tr v-for="(item, index) in reportByEmployee.communication_histories" :key="index">
                  <td>{{getOverallIndex(index, 'byMe')}}</td>
                  <td>{{item.communicated_at ? displayDateAndTime(item.communicated_at) : '-'}}</td>
                  <td>{{item.customer.name || ''}}</td>
                  <td v-if="item.pic">{{item.pic.name}}</td>
                  <td v-else>-</td>
                  <td>{{item.communication_with || '-'}}</td>
                  <td>{{truncate(item.description || '-', 40)}}</td>
                  <td>
                    <div v-if="item.reported_by" class="status" :style="`background-color: ${item.reported_by.roles[0].color}1a;color: ${item.reported_by.roles[0].color}`">{{item.reported_by.roles[0].name}}</div>
                    {{item.reported_by.name}}
                  </td>
                  <td>
                    <button @click.prevent="showModalAction('rbe', item.id)" class="btn btn-see rbm"><span class="icon-ico-eye"></span></button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="7" class="text-center">No data found</td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination :total="reportByEmployee.pages.total" :current="reportByEmployee.pages.current" @setCurrentPage="value => setCurrentPage(value)" />
        </div>
      </div><!-- end of tab2 -->

      <div class="tabs tab3">
        <div class="white-box">
          <div class="header-toolbar">
            <div class="left-side">
              <div class="row align-items-center">
                <div class="col-xl-2 col-sm-2 col-3">
                  <div class="grey mm15"> Entries</div>
                </div>
                <div class="col-xl-3 col-sm-4 col-9">
                  <select class="form-select mm15" aria-label="Default select" v-model="allCommunications.entries">
                    <option v-for="(item, i) in listEntries" :key="i" :value="item.value">{{item.label}}</option>
                  </select>
                </div>
                <div class="col-xl-7 col-sm-6">
                  <a v-if="hideAddBtn" @click.prevent="addCommunication" href="#" class="btn btn-orange w-100 mm15">Add Communication</a>
                </div>
              </div>
            </div>
            <div class="right-side">
              <div class="row">
                <div class="col-xl-7 col-sm-6">
                  <input type="text" class="form-control mm15" placeholder="Search" @input="value => debounceSearch(value, 'all')">
                </div>
                <div class="col-xl-5 col-sm-6">
                  <button @click.prevent="showModalAction('filterAll')" class="btn btn-filter" type="button">Filter by</button>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th class="tnd">Date and Time</th>
                  <th>Customer</th>
                  <th>PIC</th>
                  <th>Comunication By</th>
                  <th>Description</th>
                  <th>Report by</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="allCommunications.communication_histories.length > 0">
                <tr v-for="(item, index) in allCommunications.communication_histories" :key="index">
                  <td>{{getOverallIndex(index, 'all')}}</td>
                  <td>{{item.communicated_at ? displayDateAndTime(item.communicated_at) : '-'}}</td>
                  <td>{{item.customer.name || ''}}</td>
                  <td v-if="item.pic">{{item.pic.name}}</td>
                  <td v-else>-</td>
                  <td>{{item.communication_with || '-'}}</td>
                  <td>{{truncate(item.description || '-', 40)}}</td>
                  <td>
                    <div v-if="item.reported_by" class="status" :style="`background-color: ${item.reported_by.roles[0].color}1a;color: ${item.reported_by.roles[0].color}`">{{item.reported_by.roles[0].name}}</div>
                    {{item.reported_by.name}}
                  </td>
                  <td>
                    <button @click.prevent="showModalAction('all', item.id)" class="btn btn-see rbm"><span class="icon-ico-eye"></span></button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="7" class="text-center">No data found</td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination :total="allCommunications.pages.total" :current="allCommunications.pages.current" @setCurrentPage="value => setCurrentPage(value, 'all')" />
        </div>
      </div><!-- end of tab3 -->
    </section>

    <!-- Modal Content -->
    <div class="overlay" :class="{'overlayshow': modal.overlay}"></div>


    <!-- Reported By Me Content  -->
    <div v-if="reportByMe.detail" id="rbm-content" class="popup-wrapper" :class="{'showing': modal.rbmDetail}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center">
          <div class="font-bold">Detail Communication History</div>
          <button @click.prevent="showModalAction('rbm')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="inner-land">
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">Customer name</div>
                <span>{{reportByMe.detail.customer.name}}</span>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">PIC name</div>
                <span v-if="reportByMe.detail.pic">{{reportByMe.detail.pic.name}}</span>
                <span v-else>-</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">Date and Time</div>
                <span>{{reportByMe.detail.communicated_at ? displayDateAndTime(reportByMe.detail.communicated_at) : '-'}}</span>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">Communication by</div>
                <span>{{reportByMe.detail.communication_with || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">Category</div>
                <span>{{reportByMe.detail.category ? reportByMe.detail.category.name : '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item">
            <div class="col-sm-12">
              <div class="grey">Description</div>
              <span>{{reportByMe.detail.description || '-'}}</span>
            </div>
          </div>
          <div class="popup-item" v-if="reportByMe.detail.attachments.length > 0">
            <div class="col-sm-12">
              <div class="grey">Attachments</div>
              <ul>
                <li v-for="attachment, index in reportByMe.detail.attachments" :key="index">
                  <a target="_blank" class="d-block" style="cursor: pointer;" @click="previewDocs(attachment.filepath)">{{attachment.filename}}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Reported By Employee Content  -->
    <div v-if="reportByEmployee.detail" id="rbe-content" class="popup-wrapper" :class="{'showing': modal.rbeDetail}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center">
          <div class="font-bold">Detail Communication History</div>
          <button @click.prevent="showModalAction('rbe')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="inner-land">
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6">
                <div class="grey">Report by</div>
                <div v-if="reportByEmployee.detail.reported_by" class="status" :style="`background-color: ${reportByEmployee.detail.reported_by.roles[0].color}1a;color: ${reportByEmployee.detail.reported_by.roles[0].color}`">{{reportByEmployee.detail.reported_by.roles[0].name}}</div> <span>{{reportByEmployee.detail.reported_by.name}}</span>
              </div>
              <div class="col-sm-6">
                <div class="grey">Customer name</div>
                <span>{{reportByEmployee.detail.customer.name || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6">
                <div class="grey">PIC name</div>
                <span v-if="reportByEmployee.detail.pic">{{reportByEmployee.detail.pic.name}}</span>
                <span v-else>-</span>
              </div>
              <div class="col-sm-6  col-6">
                <div class="grey">Date and Time</div>
                <span>{{reportByEmployee.detail.communicated_at ? displayDateAndTime(reportByEmployee.detail.communicated_at) : '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6  col-6">
                <div class="grey">Communication by</div>
                <span>{{reportByEmployee.detail.communication_with || '-'}}</span>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">Category</div>
                <span>{{reportByEmployee.detail.category ? reportByEmployee.detail.category.name : '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Description</div>
                <span>{{reportByEmployee.detail.description || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item" v-if="reportByEmployee.detail.attachments.length > 0">
            <div class="col-sm-12">
              <div class="grey">Attachments</div>
              <ul>
                <li v-for="attachment, index in reportByEmployee.detail.attachments" :key="index">
                  <a target="_blank" class="d-block" style="cursor: pointer;" @click="previewDocs(attachment.filepath)">{{attachment.filename}}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- All Communications Content  -->
    <div v-if="allCommunications.detail" id="all-content" class="popup-wrapper" :class="{'showing': modal.allDetail}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center">
          <div class="font-bold">Detail Communication History</div>
          <button @click.prevent="showModalAction('all')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="inner-land">
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6">
                <div class="grey">Report by</div>
                <div v-if="allCommunications.detail.reported_by" class="status" :style="`background-color: ${allCommunications.detail.reported_by.roles[0].color}1a;color: ${allCommunications.detail.reported_by.roles[0].color}`">{{allCommunications.detail.reported_by.roles[0].name}}</div> <span>{{allCommunications.detail.reported_by.name}}</span>
              </div>
              <div class="col-sm-6">
                <div class="grey">Customer name</div>
                <span>{{allCommunications.detail.customer.name || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6">
                <div class="grey">PIC name</div>
                <span v-if="allCommunications.detail.pic">{{allCommunications.detail.pic.name}}</span>
                <span v-else>-</span>
              </div>
              <div class="col-sm-6  col-6">
                <div class="grey">Date and Time</div>
                <span>{{allCommunications.detail.communicated_at ? displayDateAndTime(allCommunications.detail.communicated_at) : '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6  col-6">
                <div class="grey">Communication by</div>
                <span>{{allCommunications.detail.communication_with || '-'}}</span>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">Category</div>
                <span>{{allCommunications.detail.category ? allCommunications.detail.category.name : '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Description</div>
                <span>{{allCommunications.detail.description || '-'}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item" v-if="allCommunications.detail.attachments.length > 0">
            <div class="col-sm-12">
              <div class="grey">Attachments</div>
              <ul>
                <li v-for="attachment, index in allCommunications.detail.attachments" :key="index">
                  <a target="_blank" class="d-block" style="cursor: pointer;" @click="previewDocs(attachment.filepath)">{{attachment.filename}}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Filter Modal Content - Report By Me -->
    <div id="filter-content" class="popup-wrapper" :class="{'showing': modal.filter}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center mb15">
          <div class="font-bold">Filter</div>
          <button @click="showModalAction('filter')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="inner-land">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <div class="grey mb15">Communication by</div>
                <div class="row">
                  <div class="col-sm-6 col-4 col-lg-3" v-for="(type, index) in communication_with_type" :key="index">
                    <div class="form-check">
                      <input class="form-check-input" name="radio-com" type="radio" :id="type+'a'" :value="type" v-model="reportByMe.filter.communication_with">
                      <label class="form-check-label" :for="type+'a'">
                        {{type}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">Start date</div>
                <date-picker v-model="reportByMe.filter.start_date" :config="options.date" @dp-change="value => doSomethingOnChange(value, 'byMe')"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-date"></span>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">End date</div>
                <date-picker v-model="reportByMe.filter.end_date" :config="options.end_date"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-date"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button @click="resetFilter('byMe')" class="btn btn-border-red mx-2" type="button">Reset</button>
            <button @click="filterCommunication('byMe')" class="btn btn-orange mx-2" type="button">Save</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Filter Modal Content - Report By Employee -->
    <div id="filter-content" class="popup-wrapper" :class="{'showing': modal.filterByEm}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center mb15">
          <div class="font-bold">Filter</div>
          <button @click="showModalAction('filterByEm')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="inner-land">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <div class="grey mb15">Communication by</div>
                <div class="row">
                  <div class="col-sm-6 col-4 col-lg-3" v-for="(type, index) in communication_with_type" :key="index">
                    <div class="form-check">
                      <input class="form-check-input" name="radio-com2" type="radio" :id="type" :value="type" v-model="reportByEmployee.filter.communication_with">
                      <label class="form-check-label" :for="type">
                        {{type}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">Start date</div>
                <date-picker v-model="reportByEmployee.filter.start_date" :config="options.date" @dp-change="value => doSomethingOnChange(value)"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-date"></span>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">End date</div>
                <date-picker v-model="reportByEmployee.filter.end_date" :config="options.end_dateEm"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-date"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button @click="resetFilter()" class="btn btn-border-red mx-2" type="button">Reset</button>
            <button @click="filterCommunication()" class="btn btn-orange mx-2" type="button">Save</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Filter Modal Content - All Communications -->
    <div id="filter-content" class="popup-wrapper" :class="{'showing': modal.filterAll}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center mb15">
          <div class="font-bold">Filter all</div>
          <button @click="showModalAction('filterAll')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="inner-land">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <div class="grey mb15">Communication by</div>
                <div class="row">
                  <div class="col-sm-6 col-4 col-lg-3" v-for="(type, index) in communication_with_type" :key="index">
                    <div class="form-check">
                      <input class="form-check-input" name="radio3" type="radio" :id="type + 'all'" :value="type" v-model="allCommunications.filter.communication_with">
                      <label class="form-check-label" :for="type + 'all'">
                        {{type}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">Start date</div>
                <date-picker v-model="allCommunications.filter.start_date" :config="options.date" @dp-change="value => doSomethingOnChange(value, 'all')"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-date"></span>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">End date</div>
                <date-picker v-model="allCommunications.filter.end_date" :config="options.end_dateAll"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-date"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button @click="resetFilter('all')" class="btn btn-border-red mx-2" type="button">Reset</button>
            <button @click="filterCommunication('all')" class="btn btn-orange mx-2" type="button">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import moment from 'moment'
import {truncate, showErrorMessage} from '@/utils'
import {Pagination} from '../../components'
export default {
  components: { Pagination },
  data() {
    return {
      truncate,
      moment,
      modal: {
        overlay: false,
        rbmDetail: false,
        rbeDetail: false,
        allDetail: false,
        filter: false,
        filterByEm: false,
        filterAll: false,
      },
      options: {
        date: {
          format: 'DD-MM-YYYY'
        },
        end_date: {
          format: 'DD-MM-YYYY',
          minDate: new Date()
        }, 
        end_dateEm: {
          format: 'DD-MM-YYYY',
          minDate: new Date()
        }, 
        end_dateAll: {
          format: 'DD-MM-YYYY',
          minDate: new Date()
        }, 
      },
      listEntries: [
          {
            label: '10',
            value: '10'
          },
          {
            label: '20',
            value: '20'
          },
          {
            label: '40',
            value: '40'
          },
          {
            label: '100',
            value: '100'
          },
        ],
      communication_with_type: ["All", "Email", "Whatsapp", "Phone"],
      reportByMe: {
        pages: {
          total: 1,
          current: 1
        },
        debounce: null,    
        search: "",
        entries: '10',
        filter: {
          start_date: "",
          end_date: "",
          communication_with: "All",
        },
        start_date: "",
        end_date: "",
        communication_with: "All",
        communication_histories: [],
        detail: null
      },
      reportByEmployee: {
        pages: {
          total: 1,
          current: 1
        },
        debounce: null,    
        search: "",
        entries: '10',
        filter: {
          start_date: "",
          end_date: "",
          communication_with: "All",
        },
        start_date: "",
        end_date: "",
        communication_with: "All",
        communication_histories: [],
        detail: null
      },
      allCommunications: {
        pages: {
          total: 1,
          current: 1
        },
        debounce: null,    
        search: "",
        entries: '10',
        filter: {
          start_date: "",
          end_date: "",
          communication_with: "All",
        },
        start_date: "",
        end_date: "",
        communication_with: "All",
        communication_histories: [],
        detail: null
      }
    }
  },
  methods: {
    doSomethingOnChange(e, type) {
      if(type === 'byMe'){
        this.options.end_date.minDate = e.date._d
      }else if(type === 'all'){
        this.options.end_dateAll.minDate = e.date._d
      }else{
        this.options.end_dateEm.minDate = e.date._d
      }
    },

    previewDocs(path) {
      let url = process.env.VUE_APP_BASE_URL_API
      let newUrl = url.replace('/api', '/');
      window.open(newUrl+path)
    },

    showModalAction(type, id) {
      this.modal.overlay = !this.modal.overlay
      if(type == 'filter') {
        this.modal.filter = !this.modal.filter
      } else if (type == 'filterByEm') {
        this.modal.filterByEm = !this.modal.filterByEm
      } else if (type == 'filterAll') {
        this.modal.filterAll = !this.modal.filterAll
      } else if (type == 'rbm') {
        // Reported By Me Modal
        if(this.modal.rbmDetail === false){
          this.getDetail(id, 'byMe')
        }
        this.modal.rbmDetail = !this.modal.rbmDetail
      } else if (type == 'all') {
        // Reported By Me Modal
        if(this.modal.allDetail === false){
          this.getDetail(id, 'all')
        }
        this.modal.allDetail = !this.modal.allDetail
      } else if (type == 'rbe') {
        // Reported By Employee Modal
        if(this.modal.rbeDetail === false){
          this.getDetail(id)
        }
        this.modal.rbeDetail = !this.modal.rbeDetail
      }
    },
    addCommunication() {
      this.$router.push({
        path: '/communication-history/add'
      })
    },
    displayDateAndTime(date) {
      return `${moment(date).format("DD MMM YYYY")}, ${moment(date).format("HH:mm")} WIB`
    },
    editCommunication(id){
      this.$router.push({
        name: 'EditCommunicationHistory',
        params: {
          id
        }
      })
    },

    setCurrentPage(val, type) {
      if(type === 'byMe') {
        this.reportByMe.pages.current = val;
        this.getComhisByMe()
      } else if(type === 'all') {
        this.allCommunications.pages.current = val;
        this.getAllComhis()
      } else {
        this.reportByEmployee.pages.current = val;
        this.getComhisByEmployee()
      }
    },

    getOverallIndex(index, type) {
      if(type === 'byMe') {
        return this.reportByMe.pages.current * this.reportByMe.entries - this.reportByMe.entries + index + 1
      } else if(type === 'all') {
        return this.allCommunications.pages.current * this.allCommunications.entries - this.allCommunications.entries + index + 1
      }else{
        return this.reportByEmployee.pages.current * this.reportByEmployee.entries - this.reportByEmployee.entries + index + 1
      }
    },

    getComhisByMe() {
      this.$store.dispatch('toggleLoadingProgress', true)

      axios.get(`/communication-histories?per_page=${this.reportByMe.entries}&page=${this.reportByMe.pages.current}&search=${this.reportByMe.search}&communication_with=${this.reportByMe.communication_with}&start_date=${this.reportByMe.start_date}&end_date=${this.reportByMe.end_date}`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)  
          this.reportByMe.pages.total = res.data.data.last_page
          this.reportByMe.pages.current = res.data.data.current_page
          let comhis = res.data.data.data
          comhis.map(item => {
            if(item.communicated_at){
              item.communicated_at = moment(item.communicated_at).format()
            }
          })
          this.reportByMe.communication_histories = comhis
        })
        .catch(err => {
          showErrorMessage(err)
        })
    },
    getComhisByEmployee() {
      this.$store.dispatch('toggleLoadingProgress', true)

      axios.get(`/communication-histories?per_page=${this.reportByEmployee.entries}&page=${this.reportByEmployee.pages.current}&search=${this.reportByEmployee.search}&communication_with=${this.reportByEmployee.communication_with}&start_date=${this.reportByEmployee.start_date}&end_date=${this.reportByEmployee.end_date}&reported_by=employee`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false) 
          this.reportByEmployee.pages.total = res.data.data.last_page
          this.reportByEmployee.pages.current = res.data.data.current_page
          let comhis = res.data.data.data
          comhis.map(item => {
            if(item.communicated_at) {
              item.communicated_at = moment(item.communicated_at).format()
            }
          })
          this.reportByEmployee.communication_histories = comhis
        })
        .catch(err => {
          showErrorMessage(err)
        })
    },
    getAllComhis() {
      this.$store.dispatch('toggleLoadingProgress', true)

      axios.get(`/communication-histories?per_page=${this.allCommunications.entries}&page=${this.allCommunications.pages.current}&search=${this.allCommunications.search}&communication_with=${this.allCommunications.communication_with}&start_date=${this.allCommunications.start_date}&end_date=${this.allCommunications.end_date}&reported_by=all`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false) 
          this.allCommunications.pages.total = res.data.data.last_page
          this.allCommunications.pages.current = res.data.data.current_page
          let comhis = res.data.data.data
          comhis.map(item => {
            if(item.communicated_at) {
              item.communicated_at = moment(item.communicated_at).format()
            }
          })
          this.allCommunications.communication_histories = comhis
        })
        .catch(err => {
          showErrorMessage(err)
        })
    },
    debounceSearch(event, type){
      if(type === 'byMe') {
        clearTimeout(this.reportByMe.debounce)
        this.reportByMe.debounce = setTimeout(() => {
          this.reportByMe.search = event.target.value
          this.resetPage('byMe')
          this.getComhisByMe()
        }, 600)
      } else if (type === 'all') {
        clearTimeout(this.allCommunications.debounce)
        this.allCommunications.debounce = setTimeout(() => {
          this.allCommunications.search = event.target.value
          this.resetPage('all')
          this.getAllComhis()
        }, 600)
      } else {
        clearTimeout(this.reportByEmployee.debounce)
        this.reportByEmployee.debounce = setTimeout(() => {
          this.reportByEmployee.search = event.target.value
          this.resetPage()
          this.getComhisByEmployee()
        }, 600)
      }
    },
    resetPage(type) {
      if(type === 'byMe') {
        this.reportByMe.pages.total = 1;
        this.reportByMe.pages.current = 1;
      } else if(type === 'all') {
        this.allCommunications.pages.total = 1;
        this.allCommunications.pages.current = 1;
      } else {
        this.reportByEmployee.pages.total = 1;
        this.reportByEmployee.pages.current = 1;
      }
    },
    getDetail(id, type) {
      if(type === 'byMe') {
        let comhis = this.reportByMe.communication_histories.filter(comhis => comhis.id === id)
        this.reportByMe.detail = comhis[0]
      } else if(type === 'all') {
        let comhis = this.allCommunications.communication_histories.filter(comhis => comhis.id === id)
        this.allCommunications.detail = comhis[0]
      } else {
        let comhis = this.reportByEmployee.communication_histories.filter(comhis => comhis.id === id)
        this.reportByEmployee.detail = comhis[0]
      }
    },
    filterCommunication(type){
      this.modal.overlay = false
      this.modal.filter = false
      this.modal.filterByEm = false
      this.modal.filterAll = false
      if(type === 'byMe'){
        if(this.reportByMe.filter.start_date){
          this.reportByMe.start_date = this.reportByMe.filter.start_date.split('-').reverse().join('-')
        } else {
          this.reportByMe.start_date = ''
          this.reportByMe.filter.start_date = ''
        }
        if(this.reportByMe.filter.end_date){
          this.reportByMe.end_date = this.reportByMe.filter.end_date.split('-').reverse().join('-')
        } else {
          this.reportByMe.end_date = ''
          this.reportByMe.filter.end_date = ''
        }
        this.reportByMe.communication_with = this.reportByMe.filter.communication_with
        this.resetPage('byMe')
        this.getComhisByMe()
      } else if(type === 'all'){
        if(this.allCommunications.filter.start_date){
          this.allCommunications.start_date = this.allCommunications.filter.start_date.split('-').reverse().join('-')
        } else {
          this.allCommunications.start_date = ''
          this.allCommunications.filter.start_date = ''
        }
        if(this.allCommunications.filter.end_date){
          this.allCommunications.end_date = this.allCommunications.filter.end_date.split('-').reverse().join('-')
        } else {
          this.allCommunications.end_date = ''
          this.allCommunications.filter.end_date = ''
        }
        this.allCommunications.communication_with = this.allCommunications.filter.communication_with
        this.resetPage('all')
        this.getAllComhis()
      } else {
        if(this.reportByEmployee.filter.start_date){
          this.reportByEmployee.start_date = this.reportByEmployee.filter.start_date.split('-').reverse().join('-')
        } else {
          this.reportByEmployee.start_date = ''
          this.reportByEmployee.filter.start_date = ''
        }
        if(this.reportByEmployee.filter.end_date){
          this.reportByEmployee.end_date = this.reportByEmployee.filter.end_date.split('-').reverse().join('-')
        } else {
          this.reportByEmployee.end_date = ''
          this.reportByEmployee.filter.end_date = ''
        }
        this.reportByEmployee.communication_with = this.reportByEmployee.filter.communication_with
        this.resetPage()
        this.getComhisByEmployee()
      }
    },
    resetFilter(type){
      this.modal.overlay = false
      this.modal.filter = false
      this.modal.filterByEm = false
      this.modal.filterAll = false
      if(type === 'byMe') {
        this.reportByMe.filter.start_date = ""
        this.reportByMe.filter.end_date = ""
        this.reportByMe.filter.communication_with = "All"
        this.reportByMe.start_date = ""
        this.reportByMe.end_date = ""
        this.reportByMe.communication_with = "All"
        this.options.end_date.minDate = new Date()
        this.resetPage('byMe')
        this.getComhisByMe()
      } else if(type === 'all') {
        this.allCommunications.filter.start_date = ""
        this.allCommunications.filter.end_date = ""
        this.allCommunications.filter.communication_with = "All"
        this.allCommunications.start_date = ""
        this.allCommunications.end_date = ""
        this.allCommunications.communication_with = "All"
        this.options.end_dateAll.minDate = new Date()
        this.resetPage('all')
        this.getAllComhis()
      } else {
        this.reportByEmployee.filter.start_date = ""
        this.reportByEmployee.filter.end_date = ""
        this.reportByEmployee.filter.communication_with = "All"
        this.reportByEmployee.start_date = ""
        this.reportByEmployee.end_date = ""
        this.reportByEmployee.communication_with = "All"
        this.options.end_dateEm.minDate = new Date()
        this.resetPage()
        this.getComhisByEmployee()
      }
    },
  },
  computed: {
    AppActiveUser() { return this.$store.state.AppActiveUser },
    reportByMeEntries() { return this.reportByMe.entries },
    reportByEmEntries() { return this.reportByEmployee.entries },
    viewAllComHis() {
      return this.AppActiveUser.permissions.includes('View All Communication Histories')
    },
    hideAddBtn() {
      return this.AppActiveUser.permissions.includes('Create Communication History')
    },
    hideEditBtn() {
      return this.AppActiveUser.permissions.includes('Edit Communication History') || this.AppActiveUser.permissions.includes('Delete Communication History')
    },
  },
  mounted() {
    this.getComhisByMe()
    this.getComhisByEmployee()

    if(this.viewAllComHis) {
      this.getAllComhis()
    }
  },
  watch: {
    reportByMeEntries() {
      this.resetPage('byMe')
      this.getComhisByMe()
    },
    reportByEmEntries() {
      this.resetPage()
      this.getComhisByEmployee()
    }
  }
}
</script>

<style>
</style>