<template>
  <div class="white-box mt-3">
    <div class="header-toolbar">
      <div class="left-side">
        <div class="row align-items-center">
          <div class="col-xl-2 col-sm-2 col-3">
            <div class="grey"> Entries</div>
          </div>
          <div class="col-xl-3 col-sm-4 col-9">
            <select class="form-select mm15" aria-label="Default select" v-model="entries">
              <option v-for="(item, i) in listEntries" :key="i" :value="item.value">{{item.label}}</option>
            </select>
          </div>
          <div class="col-xl-7 col-sm-6">
            <a v-if="hideAddBtn" @click.prevent="$router.push({path: '/label/add'})" href="#" class="btn btn-orange w-100 mm15">Add New</a>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>No</th>
            <th width="80%">Label</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody v-if="labels.length > 0">
          <tr v-for="(label, index) in labels" :key="label.id">
            <td>{{getOverallIndex(index)}}</td>
            <td>
              <div class="status" :style="`background-color: ${label.color}1a;color: ${label.color}`">{{label.name}}</div>
            </td>
            <td>
              <a v-if="hideEditBtn" @click.prevent="$router.push({path: `/label/edit/${label.id}`})" href="#" class="link-edit"><span class="icon-ico-pencil"></span></a>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="3" class="text-center">No data found</td>
          </tr>
        </tbody>
      </table>
    </div>

    <pagination :total="pages.total" :current="pages.current" @setCurrentPage="setCurrentPage" />
  </div>
</template>

<script>
import axios from '@/axios'
import {showErrorMessage} from '@/utils';

import {Pagination} from '../../../components'
export default {
  components: {
    Pagination
  },
  data() {
    return {
      labels: [],
      listEntries: [
        {
          label: '10',
          value: '10'
        },
        {
          label: '20',
          value: '20'
        },
        {
          label: '40',
          value: '40'
        },
        {
          label: '100',
          value: '100'
        },
      ],
      entries: '10',
      pages: {
        total: 1,
        current: 1
      }
    }
  },
  methods: {
    getLabels() {
      this.$store.dispatch('toggleLoadingProgress', true)
      
      axios.get(`/labels?per_page=${this.entries}&page=${this.pages.current}`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)
          this.pages.total = res.data.data.last_page
          this.pages.current = res.data.data.current_page
          this.labels = res.data.data.data
        })
        .catch(err => {
          showErrorMessage(err)
        })
    },
    setCurrentPage(val) {
      this.pages.current = val;
      this.getLabels();
    },
    resetPage() {
      this.pages.total = 1;
      this.pages.current = 1;
    },
    getOverallIndex(index) {
      return this.pages.current * this.entries - this.entries + index + 1
    }
  },
  computed:{
    AppActiveUser() { return this.$store.state.AppActiveUser },
    hideAddBtn() {
      return this.AppActiveUser.permissions.includes('Create Label Customer')
    },
    hideEditBtn() {
      return this.AppActiveUser.permissions.includes('Edit Label Customer') || this.AppActiveUser.permissions.includes('Delete Label Customer')
    },
  },
  mounted() {
    this.getLabels()
  },
  watch: {
    entries(){
      this.resetPage()
      this.getLabels()
    }
  }
}
</script>

<style>

</style>