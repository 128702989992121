<template>
  <main>
    <a @click.prevent="$router.go(-1)" href="#" class="link-back"><span class="icon-ico-arrow-left"></span> Edit Employee</a>
    <div class="white-box p30">
      <div class="row">
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">NIK</div>
            <input type="text" class="form-control" v-model="employee.nik" name="NIK" v-validate="'required|numeric'">
            <span class="text-danger text-sm" v-show="errors.has('NIK')">{{ errors.first('NIK') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Employee name</div>
            <input type="text" class="form-control" v-model="employee.name" v-validate="'required'" name="Employee Name">
            <span class="text-danger text-sm" v-show="errors.has('Employee Name')">{{ errors.first('Employee Name') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Position</div>
            <select v-if="employee.role" class="form-select" v-model="employee.role.id">
              <option v-for="position in positions" :key="position.id" :value="position.id">{{position.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Phone number</div>
            <input type="text" class="form-control" v-model="employee.phone_number" v-validate="'required|numeric'" name="Phone Number">
            <span class="text-danger text-sm" v-show="errors.has('Phone Number')">{{ errors.first('Phone Number') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Email</div>
            <input type="email" class="form-control" v-model="employee.email" v-validate="'required|email'" name="Email">
            <span class="text-danger text-sm" v-show="errors.has('Email')">{{ errors.first('Email') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Password</div>
            <input v-model="employee.password" :type="type.password" class="form-control" id="password-field1" v-validate="'min:8'" name="Password">
            <div class="for-btn-show">
              <button @click.prevent="togglePassword('password')" toggle="#password-field1" class="btn btn-show"></button>
            </div>
            <span class="text-danger text-sm error-message" v-show="errors.has('Password')">{{ errors.first('Password') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button v-if="hideDeleteBtn" @click.prevent="updateStatus(employee.nik, employee.status)" class="btn btn-border-red mx-2" type="button">{{statusEmployee}}</button>
        <button v-if="hideEditBtn()" @click.prevent="updateEmployee()" class="btn btn-orange mx-2" type="button" :disabled="disabledBtn">Update</button>
      </div>
    </div>
  </main>
</template>

<script>
import axios from '@/axios'
import {showErrorMessage} from '@/utils';

export default {
  data() {
    return {
      type: {
        password: 'password',
      },
      employee: {},
      positions: []
    }
  },
  methods: {
    togglePassword(form_type) {
      if(this.type[form_type] === 'password') {
          this.type[form_type] = 'text'
       } else {
          this.type[form_type] = 'password'
       }
    },
    getEmployee() {
      this.$store.dispatch('toggleLoadingProgress', true)
      
      axios.get(`/employees/${this.$route.params.id}`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)
          const {data} = res.data
          data.password = ''
          this.employee = data
        })
        .catch(err => {
          showErrorMessage(err)
        })
    },
    getRoles() {
      this.$store.dispatch('toggleLoadingProgress', true)
      
      axios.get(`/roles`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)
          this.positions = res.data.data
        })
        .catch(err => {
          showErrorMessage(err)
        })
    },
    updateStatus(nik, status) {
      this.$store.dispatch('toggleLoadingProgress', true)
      let data = status === 'active' ? 'inactive' : 'active'
      axios.post(`/employees/${nik}/update-status`, {status: data}).then(() => {
        this.$store.dispatch('toggleLoadingProgress', false)
        this.$notify({ type: 'success', title: 'Success', text: 'Success update employee' })
        this.$router.push({
          name: 'EmployeeManagement'
        })
      }).catch(error => {
        showErrorMessage(error)
      })
    },
    updateEmployee(){
      if(!this.disabledBtn){
        this.$validator.validate().then(valid => {
          if(valid) {
            let data = {
              nik: this.employee.nik,
              name: this.employee.name,
              position: this.employee.role.id,
              phone_number: this.employee.phone_number,
              email: this.employee.email,
              password: this.employee.password
            }

            this.$store.dispatch('toggleLoadingProgress', true)
      
            axios.put(`/employees/${this.$route.params.id}`, data).then(() => {
              this.$store.dispatch('toggleLoadingProgress', false)
              this.$notify({ type: 'success', title: 'Success', text: 'Success edit employee' })
              this.$router.push({
                name: 'EmployeeManagement'
              })
            }).catch(err => {
              showErrorMessage(err)
            })
          }
        })
      }
    },
    deleteEmployee(){
      if(confirm('Are you sure you want to delete this data?')){
        this.$store.dispatch('toggleLoadingProgress', true)

        axios.delete(`/employees/${this.$route.params.id}`)
          .then(() => {
            this.$notify({ type: 'success', title: 'Success', text: 'Success delete employee' })
            this.$store.dispatch('toggleLoadingProgress', false)
            this.$router.push({
              name: 'EmployeeManagement'
            })
          })
          .catch(err => {
            showErrorMessage(err)
          })
      }
    },


    
    hideEditBtn() {
      let ifSuperAdmin = Boolean(this.AppActiveUser.role === 'Super Admin')
      if(ifSuperAdmin) {
        return this.AppActiveUser.permissions.includes('Edit Employee')
      } else {
        return this.AppActiveUser.permissions.includes('Edit Employee') && this.employee.role?.name !== 'Super Admin'
      }
    },
  }, 
  computed: {
    disabledBtn() {
      return this.employee.nik === '' ||
             this.employee.name === '' ||
             this.employee.phone_number === '' ||
             this.employee.email === ''
    },
    statusEmployee() {
      return this.employee.status === 'active' ? 'Inactive' : 'Active'
    },

    AppActiveUser() { return this.$store.state.AppActiveUser },
    hideDeleteBtn() {
      return this.AppActiveUser.permissions.includes('Active/Inactive Employee') && (this.employee.nik !== this.AppActiveUser?.nik && this.employee.role?.name !== 'Super Admin')
    },
    
  },
  mounted() {
    this.getEmployee()
    this.getRoles()
  }
}
</script>

<style>

</style>