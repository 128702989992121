<template>
  <div class="white-box mt-3">
    <div class="header-toolbar">
      <div class="left-side">
        <div class="row align-items-center">
          <div class="col-xl-2 col-sm-2 col-3">
            <div class="grey mm15"> Entries</div>
          </div>
          <div class="col-xl-3 col-sm-4 col-9">
            <select class="form-select mm15" aria-label="Default select" v-model="entries">
              <option v-for="(item, i) in listEntries" :key="i" :value="item.value">{{item.label}}</option>
            </select>
          </div>
          <div class="col-xl-7 col-sm-6">
            <a v-if="hideAddBtn" @click.prevent="$router.push({path: '/broadcast/add'})" href="#" class="btn btn-orange w-100 mm15">Add New</a>
          </div>
        </div>
      </div>
      <div class="right-side">
        <div class="row">
          <div class="col-xl-7 col-sm-6">
            <input type="text" class="form-control mm15" placeholder="Search" @input="debounceSearch">
          </div>
          <div class="col-xl-5 col-sm-6">
            <button @click.prevent="showModalAction('filter')" class="btn btn-filter" type="button">Filter by</button>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>No</th>
            <th>Broadcast Type</th>
            <th>Title</th>
            <th>Communication By</th>
            <th>Date and Time</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody v-if="broadcasts.length > 0">
          <tr v-for="(broadcast, index) in broadcasts" :key="index">
            <td>{{getOverallIndex(index)}}</td>
            <td>{{broadcast.broadcast_type}}</td>
            <td>{{broadcast.title}}</td>
            <td>{{broadcast.communication_with}}</td>
            <td>{{broadcast.created_at}} WIB</td>
            <td>
              <button @click.prevent="showModalAction('label', broadcast.id)" class="btn btn-see rbm"><span class="icon-ico-eye"></span></button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7" class="text-center">No data found</td>
          </tr>
        </tbody>
      </table>
    </div>

    <pagination :total="pages.total" :current="pages.current" @setCurrentPage="setCurrentPage" />


    <div class="overlay" :class="{'overlayshow': showModal}"></div>
    <!--Popup-->
    <div v-if="detail" id="rbm-content" class="popup-wrapper" :class="{'showing': labelModalShow}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center">
          <div class="font-bold">Detail Broadcast</div>
          <button @click.prevent="showModalAction('label')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="inner-scroll">
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">Broadcast type</div>
                <span>{{detail.broadcast_type}}</span>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">Date and Time</div>
                <span>{{detail.created_at}} WIB</span>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-12" v-if="detail.broadcast_type === 'Group Label'">
                <div class="grey">Labels</div>
                <div v-for="(label, index) in detail.pic_labels" :key="index" class="status" :style="`background-color: ${label.color}1a;color: ${label.color};margin-right: 3px;`">{{label.name}}</div>
              </div>
              <div class="col-sm-12" v-else>
                <div class="grey">Customers</div>
                <div v-for="(customer, index) in detail.customers" :key="index" class="status ori" style="margin-right: 3px;">{{customer.name}}</div>
              </div>
            </div>
          </div>
          <div class="popup-item wb">
            <div class="row">
              <div class="col-sm-6 col-6">
                <div class="grey">Title</div>
                <span>{{detail.title}}</span>
              </div>
              <div class="col-sm-6 col-6">
                <div class="grey">Comunication by</div>
                <span>{{detail.communication_with}}</span>
              </div>
            </div>
          </div>
          <div class="popup-item" :class="{'wb' : detail.attachments.length > 0}">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Message</div>
                <div id="message-content" v-html="detail.message"></div>
              </div>
            </div>
          </div>
          <div v-if="detail.attachments.length > 0" class="popup-item">
            <div class="row">
              <div class="col-sm-12">
                <div class="grey">Attachments</div>
                <ul>
                  <li v-for="attachment, index in detail.attachments" :key="index">
                    <a target="_blank" class="d-block" style="cursor: pointer;" @click="previewDocs(attachment.filepath)">{{attachment.original_filename}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="filter-content" class="popup-wrapper" :class="{'showing': filterModalShow}">
      <div class="popup-card">
        <div class="d-flex justify-content-between align-items-center mb15">
          <div class="font-bold">Filter</div>
          <button @click.prevent="showModalAction('filter')" class="btn btn-for-close"><span class="icon-ico-close"></span></button>
        </div>
        <div class="inner-land">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">Type</div>
                <select class="form-select" v-model="filter.type">
                  <option value="">All</option>
                  <option v-for="(type, index) in types" :key="index" :value="type">{{type}}</option>
                </select>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">Communication by</div>
                <select class="form-select" v-model="filter.communication_with">
                  <option value="">All</option>
                  <option v-for="(communication, index) in communications_with" :key="index" :value="communication">{{communication}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">Start date</div>
                <date-picker v-model="filter.start_date" :config="options.date" @dp-change="doSomethingOnChange"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-date"></span>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">End date</div>
                <date-picker v-model="filter.end_date" :config="options.end_date" @dp-change="doSomethingOnChangeEnd"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-date"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button @click="resetFilter" class="btn btn-border-red mx-2" type="button">Reset</button>
            <button @click="filterBroadcasts" class="btn btn-orange mx-2" type="button">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios';
import {showErrorMessage} from '@/utils';
import moment from 'moment';
import Pagination from '../../components/Pagination.vue';

export default {
  components: { Pagination },
  data() {
    return {
      showModal: false,
      filterModalShow: false,
      labelModalShow: false,

      options: {
        date: {
          format: 'DD-MM-YYYY',
          maxDate: moment('01-01-3000').format()
        },
        end_date: {
          format: 'DD-MM-YYYY',
          minDate: moment('01-01-1600').format()
        },
        time: {
          format: 'LT'
        }
      },
      listEntries: [
        {
          label: '10',
          value: '10'
        },
        {
          label: '20',
          value: '20'
        },
        {
          label: '40',
          value: '40'
        },
        {
          label: '100',
          value: '100'
        },
      ],
      types: ["Group Label", "Customer"],
      communications_with: ["Email", "Whatsapp"],
      broadcasts: [],
      pages: {
        total: 1,
        current: 1
      },
      debounce: null,    
      search: "",
      entries: '10',
      filter: {
        start_date: null,
        end_date: null,
        communication_with: "",
        type: ''
      },
      communication_with: "",
      start_date: "",
      end_date: "",
      type: '',
      detail: null
    }
  },
  methods: {
    showModalAction(type, id) {
      this.showModal = !this.showModal
      if(type == 'filter') {
        this.filterModalShow = !this.filterModalShow
      } else if (type == 'label') {
        if(this.labelModalShow === false){
          this.getDetail(id)
        }
        this.labelModalShow = !this.labelModalShow
      }
    },
    getDetail(id) {
        let broadcast = this.broadcasts.filter(broadcast => broadcast.id === id)
        let link = `href="//`
        this.detail = broadcast[0]
        this.detail.message = broadcast[0].message.replaceAll("\n", "<br/>")
        this.detail.message = broadcast[0].message.replaceAll(`href="http://`, '')
        this.detail.message = broadcast[0].message.replaceAll(`href="https://`, '')
        this.detail.message = broadcast[0].message.replaceAll(`href="`, `${link}`)
    },
    getBroadcasts() {
      this.$store.dispatch('toggleLoadingProgress', true)

      axios.get(`/broadcasts?per_page=${this.entries}&page=${this.pages.current}&type=${this.type}&search=${this.search}&communication_with=${this.communication_with}&start_date=${this.start_date}&end_date=${this.end_date}`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)  
          this.pages.total = res.data.data.last_page
          this.pages.current = res.data.data.current_page
          let broadcasts = res.data.data.data
          broadcasts.map(item => {
            if(item.created_at){
              item.created_at = moment(item.created_at).format('DD MMM YYYY, HH : mm')
            }
          })
          this.broadcasts = broadcasts
        }).catch(err => {
          showErrorMessage(err)
        })
    },
    getOverallIndex(index) {
      return this.pages.current * this.entries - this.entries + index + 1
    },
    resetPage() {
        this.pages.total = 1;
        this.pages.current = 1;
    },
    debounceSearch(event){
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.search = event.target.value
        this.resetPage()
        this.getBroadcasts()
      }, 600)
    },
    filterBroadcasts(){
      this.showModal = false
      this.filterModalShow = false
      if(this.filter.start_date){
        this.start_date = this.filter.start_date.split('-').reverse().join('-')
      } else {
        this.start_date = ''
        this.filter.start_date = null
      }
      if(this.filter.end_date){
        this.end_date = this.filter.end_date.split('-').reverse().join('-')
      } else {
        this.end_date = ''
        this.filter.end_date = null
      }
      this.type = this.filter.type
      this.communication_with = this.filter.communication_with
      this.resetPage()
      this.getBroadcasts()
    },
    resetFilter(){
      this.showModal = false
      this.filterModalShow = false
      this.filter.start_date = ""
      this.filter.end_date = ""
      this.filter.type = ""
      this.filter.communication_with = ""
      this.start_date = ""
      this.end_date = ""
      this.type = ""
      this.communication_with = ""
      this.resetPage()
      this.getBroadcasts()
    },
    setCurrentPage(val) {
        this.pages.current = val;
        this.getBroadcasts()
    },
    doSomethingOnChange(e) {
      this.options.end_date.minDate = e.date._d
    },
    doSomethingOnChangeEnd(e) {
      this.options.date.maxDate = e.date._d
    },
    previewDocs(path) {
      let url = process.env.VUE_APP_BASE_URL_API
      let newUrl = url.replace('/api', '/');
      window.open(newUrl+path)
    },
  },
  mounted() {
    this.getBroadcasts()
  },
  computed: {
    AppActiveUser() { return this.$store.state.AppActiveUser },
    hideAddBtn() {
      return this.AppActiveUser.permissions.includes('Create Broadcast')
    },
  },
  watch: {
    entries() {
      this.resetPage()
      this.getBroadcasts()
    }
  }
}
</script>

<style>
#message-content * {
  margin: 0;
  padding: 0;
  box-sizing: border-box; 
  white-space: pre-wrap;
}

#message-content ul, #message-content ol {
  list-style-type: revert;
  padding-left: 2.25rem;
}

#message-content a {
  text-decoration: underline;
  color: blue;
}

#message-content img {
  width: 50%;
  cursor: unset !important;
}
</style>