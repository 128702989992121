<template>
  <main v-if="data">
    <a @click.prevent="$router.go(-1)" href="#" class="link-back"><span class="icon-ico-arrow-left"></span> Edit New Feedback &
        Complaint</a>
    <div class="white-box p30">
      <div class="row">
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">ID</div>
            <input type="text" class="form-control" :value="$route.params.id" disabled>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Subject<span class="text-danger">*</span></div>
            <input type="text" class="form-control" v-model="data.subject">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">Date<span class="text-danger">*</span></div>
                <date-picker v-model="date" :config="options.date"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-date"></span>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <div class="grey mb5">Time<span class="text-danger">*</span></div>
                <date-picker v-model="time" :config="options.time"></date-picker>
                <div class="icon-form">
                  <span class="icon-ico-time"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Complaint/Feedback Description<span class="text-danger">*</span></div>
            <quill-editor
              ref="myQuillEditor"
              v-model="data.description"
              :options="editorOption"
              class="quill-editor-custom"
            />
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb15">Type</div>
            <div class="row">
              <div class="col-sm-4" v-for="(type, index) in types" :key="index">
                <div class="form-check">
                  <input class="form-check-input" name="radio-com" type="radio" :id="type" :value="type" v-model="data.type">
                  <label class="form-check-label" :for="type">
                    {{type}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
           <div class="form-group">
            <div class="grey mb5">Category<span class="text-danger">*</span></div>
            <select class="form-select" v-model="data.category_id">
              <option value="" disabled>Choose One</option>
              <option v-for="(category, index) in categories" :key="index" :value="category.id">{{category.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Label</div>
            <v-select placeholder="Choose One" :closeOnSelect="false" multiple v-model="temp_labels" :options="labels.filter(({ label: id1 }) => !temp_labels.some(({ label: id2 }) => id2 === id1))">
              <template
                #selected-option-container="{ option }"
              >
                <div class="selected_custom" :style="`background-color: ${option.color}1a;color: ${option.color}`">
                  <span>{{ option.label }}</span>
                  <button @click.capture="deleteLabelFromCurrent(option.value)" class="vs__deselect"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path></svg></button>
                </div>
              </template>
            </v-select>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb15">Priority</div>
            <div class="row">
              <div class="col-sm-4" v-for="(priority, index) in priorities" :key="index">
                <div class="form-check">
                  <input class="form-check-input" name="radio-prio" type="radio" :id="priority" :value="priority" v-model="data.priority">
                  <label class="form-check-label" :for="priority">
                    {{priority}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Assigned to</div>
            <v-select placeholder="Choose One" :closeOnSelect="false" multiple v-model="temp_employees" :options="employees.filter(({ label: id1 }) => !temp_employees.some(({ label: id2 }) => id2 === id1))"></v-select>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Status<span class="text-danger">*</span></div>
            <select class="form-select" v-model="data.status">
              <option value="" disabled>Choose One</option>
              <option v-for="(status, index) in statuses" :key="index" :value="status">{{status}}</option>
            </select>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="separator dashed"></div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Customers<span class="text-danger">*</span></div>
            <v-select 
              :filterable="false"
              @search="debounceSearch" 
              @input="getPicsByCust"
              placeholder="Choose One" 
              v-model="customer" 
              :options="customers"
              >
              <template v-if="customers.length >= 10 && (customers.length < totalCustomers)" #list-footer>
                <button @click="loadMoreCustomers" class="d-flex py-2" style="opacity: 0.8; margin: auto; background: transparent; border: none">
                 {{ onLoadMore ? 'Please wait...' : 'Click to load more' }}
                </button>
              </template>
            </v-select>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Product</div>
            <input type="text" class="form-control" v-model="data.product">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Lot No.</div>
            <input v-model="data.lot_no" type="text" class="form-control" name="Lot No.">
          </div>
        </div>
        <div class="col-xl-6 col-sm-6"></div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">PIC<span class="text-danger">*</span></div>
            <select class="form-select" v-model="data.pic_id" @change="changePicVal">
              <option :value="null" disabled>Choose One</option>
              <option v-for="(pic, index) in pics" :key="index" :value="pic.id">{{pic.name}}</option>
            </select>
            <small v-if="customer && !pics.length" class="text-danger">Customer don't have a PIC, please create in customer menu.</small>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">No. HP PIC</div>
            <input disabled v-validate="'required|numeric'" @keypress="checkValidationNumber" v-model="data.no_hp_pic" type="tel" class="form-control" name="No. HP PIC">
            <span class="text-danger text-sm" v-show="errors.has('No. HP PIC')">{{ errors.first('No. HP PIC') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Transporter</div>
            <select class="form-select" v-model="data.transporter_id">
              <option value="" disabled>Choose One</option>
              <option v-for="(transporter, index) in transporters" :key="index" :value="transporter.id">{{transporter.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6"></div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">No. Surat Jalan</div>
            <input v-validate="'numeric'" @keypress="checkValidationNumber" v-model="data.no_surat_jalan" type="tel" class="form-control" name="No. Surat Jalan">
            <span class="text-danger text-sm" v-show="errors.has('No. Surat Jalan')">{{ errors.first('No. Surat Jalan') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Tgl Surat Jalan</div>
            <date-picker v-model="travel_doc_date" :config="options.date"></date-picker>
            <div class="icon-form">
              <span class="icon-ico-date"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button v-if="hideDeleteBtn" @click="deleteFac" class="btn btn-border-red mx-2" type="button">Delete</button>
        <button :disabled="disabledBtn" v-if="hideEditBtn" @click="updateFac" class="btn btn-orange mx-2" type="button">Update</button>
      </div>
    </div>
  </main>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import axios from '@/axios'
import moment from 'moment'
import {checkValidationNumber, showErrorMessage} from '@/utils'

export default {
  components: {
    quillEditor
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'list': 'bullet' }, { 'list': 'ordered' }]
          ],
        }
      },
      checkValidationNumber,
      options: {
        date: {
          format: 'DD-MM-YYYY'
        },
        time: {
          format: 'HH:mm'
        }
      },
      types: ["Feedback", "Complaint"],
      priorities: ["Low", "Medium", "High"],
      statuses: ["New", "In Progress", "No Status", "Checked In", "Reopened", "Closed"],
      categories: [],
      labels: [],
      temp_labels: [],
      employees: [],
      temp_employees: [],
      customers: [],
      transporters: [],
      pics: [],
      travel_doc_date: null,
      data: null,
      date: null,
      time: null,

      totalCustomers: 0,
      customer: null,
      query: '',
      onLoadMore: false,
      initialPageCust: 1
    }
  },
  methods: {
    loadMoreCustomers(){
      this.onLoadMore = true
      this.initialPageCust++
      this.getCustomers(null, this.initialPageCust)
        .then(() => {
          this.onLoadMore = false
        })
    },
    debounceSearch(query, loading){
      clearTimeout(this.debounce)
      this.query = query
      this.initialPageCust = 1
      this.debounce = setTimeout(() => {
        this.customers = []
        this.getCustomers(loading, this.initialPageCust)
      }, 500)
    },
    getCustomers(loading, page) {
      loading && loading(true)
      return axios.get(`/customers?per_page=10&page=${page}&search=${this.query}`)
        .then(res => {
          const customers = res.data.data.data
          this.totalCustomers = res.data.data.total
          loading && loading(false)
          customers.map(customer => {
            this.customers.push({
              label: customer.name,
              value: customer.id,
            })
          })
      }).catch(err => {
          showErrorMessage(err)
          loading && loading(false)
      }) 
    },
    changePicVal() {
      if(this.data.pic_id !== null) {
        let phonePic = this.pics.filter(pic => pic.id === this.data.pic_id)
        this.data.no_hp_pic = phonePic[0].phone_number
      }
    },
    deleteLabelFromCurrent(value) {
      this.temp_labels = this.temp_labels.filter(item => item.value !== value)
    },
    getPicsByCust(customer) {
      const {value} = customer
      if (value && this.customer) {
        this.$store.dispatch('toggleLoadingProgress', true)
        axios.get(`/customers/${value}/pics`)
          .then(res => {
            this.$store.dispatch('toggleLoadingProgress', false)
            const {data} = res.data
            this.pics = data
          })
          .catch(err => {
            showErrorMessage(err)
          })
      }
    },
    getDataForm() {
      this.$store.dispatch('toggleLoadingProgress', true)  

      const getFac = axios.get(`/feedback-and-complaints/${this.$route.params.id}`)
      const getEmployees = axios.get('/employees')
      const getCustomers = axios.get('/customers?per_page=10&page=1')
      const getTransporters = axios.get('/fac-transporters')
      const getCategories = axios.get('/fac-categories')
      const getLabels = axios.get('/fac-labels')

      Promise.all([getEmployees, getFac, getCustomers, getTransporters, getCategories, getLabels]).then(res => {
        this.$store.dispatch('toggleLoadingProgress', false) 
        let employees = res[0].data.data
        let fac = res[1].data.data

        const customers = res[2].data.data
        this.totalCustomers = customers.total
        customers.data.map(customer => {
          this.customers.push({
            label: customer.name,
            value: customer.id,
          })
        })

        this.transporters = res[3].data.data
        this.categories = res[4].data.data
        let labels = res[5].data.data
        this.customer = fac.customer ? {
          label: fac.customer.name,
          value: fac.customer.id
        } : null
        this.data = fac
        if(fac.pic) {
          this.getPicsByCust({value:fac.customer_id})
        }
        this.date = moment(this.data.date_time).format('DD-MM-YYYY')
        this.time = moment(this.data.date_time).format('HH:mm')
        this.travel_doc_date = this.data.tgl_surat_jalan ? moment(this.data.tgl_surat_jalan).format('DD-MM-YYYY') : ''

        labels.map(label => {
          this.labels.push({
            label: label.name,
            value: label.id,
            color: label.color,
          })
        })

        fac.labels.map(label => {
          this.temp_labels.push({
            label: label.name,
            value: label.id,
            color: label.color,
          })
        })
        fac.assignees.map(item => {
          this.temp_employees.push({
            label: item.name,
            value: item.nik
          })
        })
        employees.map(item => {
          this.employees.push({
            label: item.name,
            value: item.nik
          })
        })

        this.$watch('customersChange', () => {
          this.data.pic_id = null
          this.data.pic = null
        })
      }).catch(err => {
        showErrorMessage(err)
      })
    },
    updateFac() {
      this.$validator.validate().then(valid => {
        if(valid) {
          if(!this.disabledBtn) {
            this.$store.dispatch('toggleLoadingProgress', true)  
            let date_time = `${this.date.split('-').reverse().join('-')} ${this.time}`
            let tgl_surat_jalan = this.travel_doc_date ? this.travel_doc_date.split('-').reverse().join('-') : ''
            let assignees = this.temp_employees.map(employee => employee.value)
            let labels = []
            this.temp_labels.map(label => {
              labels.push(label.value)
            })
            let data = {
              date_time,
              subject: this.data.subject,
              description: this.data.description,
              type: this.data.type,
              category_id: this.data.category_id,
              labels,
              priority: this.data.priority,
              status: this.data.status,
              customer_id: this.customer.value,
              product: this.data.product,
              lot_no: this.data.lot_no,
              pic_id: this.data.pic_id,
              no_hp_pic: this.data.no_hp_pic,
              transporter_id: this.data.transporter_id,
              no_surat_jalan: this.data.no_surat_jalan,
              tgl_surat_jalan,
              assignees
            }
      
            axios.put(`/feedback-and-complaints/${this.$route.params.id}`, data)
              .then(() => {
                this.$notify({ type: 'success', title: 'Success', text: 'Success edit feedback/complaint' })
                this.$store.dispatch('toggleLoadingProgress', false)  
                this.$router.push({name: 'Feedback'})
              })
              .catch(error => {
                showErrorMessage(error)
              })
          }
        }
      })
    },
    deleteFac(){
      if(confirm('Are you sure you want to delete this data?')){
        this.$store.dispatch('toggleLoadingProgress', true)

        axios.delete(`/feedback-and-complaints/${this.$route.params.id}`)
          .then(() => {
            this.$notify({ type: 'success', title: 'Success', text: 'Success delete feedback/complaints' })
            this.$store.dispatch('toggleLoadingProgress', false)
            this.$router.push({
              name: 'Feedback'
            })
          })
          .catch(err => {
            showErrorMessage(err)
          })
      }
    },
  }, 
  computed: {
    AppActiveUser()     { return this.$store.state.AppActiveUser },
    hideDeleteBtn() {
      return this.AppActiveUser.permissions.includes('Delete Feedback and Complaint')
    },
    hideEditBtn() {
      return this.AppActiveUser.permissions.includes('Edit Feedback and Complaint')
    },
    customersChange() {
      return this.customer
    },
    disabledBtn() {
      return this.data.subject === "" ||
             this.date === null ||
             this.time === null ||
             this.data.description === '' ||
             this.data.category_id === '' ||
             this.data.status === '' ||
             this.customer === null ||
             this.data.pic_id === '' ||
             this.data.pic_id === null
    },
  },
  mounted() {
    this.getDataForm()
  },
}
</script>

<style>

</style>