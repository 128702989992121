<template>
  <main>
    <a @click.prevent="$router.go(-1)" href="#" class="link-back"><span class="icon-ico-arrow-left"></span> Add Address</a>
    <div class="white-box p30">
      <div class="row">
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Province</div>
            <v-select label="label" :options="provinces" v-model="province"></v-select>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">City</div>
            <v-select :disabled="disabledCity" label="label" :options="cities" v-model="city"></v-select>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Area </div>
            <input v-validate="'required'" v-model="data.area" type="text" class="form-control" name="Area">
            <span class="text-danger text-sm" v-show="errors.has('Area')">{{ errors.first('Area') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Address</div>
            <input v-validate="'required'" v-model="data.address" type="text" class="form-control" name="Address">
            <span class="text-danger text-sm" v-show="errors.has('Address')">{{ errors.first('Address') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Phone number</div>
            <input v-validate="'required|numeric'" @keypress="checkValidationNumber" v-model="data.phone_number" type="tel" class="form-control" name="Phone Number">
            <span class="text-danger text-sm" v-show="errors.has('Phone Number')">{{ errors.first('Phone Number') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Fax</div>
            <input v-validate="'required|numeric'" @keypress="checkValidationNumber" v-model="data.fax" type="tel" class="form-control" name="Fax">
            <span class="text-danger text-sm" v-show="errors.has('Fax')">{{ errors.first('Fax') }}</span>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6">
          <div class="form-group">
            <div class="grey mb5">Select place</div>
            <select class="form-select" v-model="data.place_type">
              <option disabled value="">Choose One</option>
              <option value="Warehouse">Warehouse</option>
              <option value="Plant">Plant</option>
              <option value="HO">HO</option>
            </select>
          </div>
        </div>
        <div class="col-xl-6 col-sm-6"></div>
      </div>
      <div class="d-flex justify-content-center">
        <button @click.prevent="addAdress" :disabled="disabledBtn" class="btn btn-orange" type="button">Save</button>
      </div>
    </div>
  </main>
</template>

<script>
import axios from '@/axios'
import {checkValidationNumber, showErrorMessage} from '@/utils'
export default {
  data() {
    return {
      checkValidationNumber,
      disabledCity: true,

      provinces: [],
      cities: [],
      city: null,
      province: null,
      data: {
        city_id: '',
        province_id: '',
        area: '',
        address: '',
        phone_number: '',
        fax: '',
        place_type: ''
      }
    }
  },
  methods: {
    getProvinces() {
      this.$store.dispatch('toggleLoadingProgress', true)
      const promiseProvinces = axios.get('/provinces')

      Promise.all([promiseProvinces]).then(res => {
        this.$store.dispatch('toggleLoadingProgress', false)  
        let provinces = res[0].data.data

        provinces.map(province => {
          this.provinces.push({
            label: province.name,
            value: province.id
          })
        })
      }).catch(err => {
        showErrorMessage(err)
      })
    },
    getCityByProvince(id) {
      this.$store.dispatch('toggleLoadingProgress', true)

      axios.get(`/cities?province_id=${id}`)
        .then(res => {
          this.$store.dispatch('toggleLoadingProgress', false)  
          let {data} = res.data
          this.cities = []
          data.map(city => {
            this.cities.push({
              label: city.name,
              value: city.id
            })
          })
        }).catch(err => {
          showErrorMessage(err)
        })
    },
    addAdress() {
      this.$validator.validate().then(valid => {
        if(valid){
          if(!this.disabledBtn){
            this.$store.dispatch('toggleLoadingProgress', true)  
            this.data.city_id = this.city.value
            this.data.province_id = this.province.value
    
            axios.post(`/customers/${this.$route.params.id}/addresses`, this.data)
              .then(() => {
                this.$store.dispatch('toggleLoadingProgress', false) 
                this.$notify({ type: 'success', text: 'Success add address' })
                this.$router.push({path: `/customer/${this.$route.params.id}`})
              })
              .catch(error => {
                showErrorMessage(error)
              })
          }
        }
      })
    }
  },
  computed: {
    disabledBtn() {
      return this.city === null ||
             this.province === null ||
             this.data.area === '' ||
             this.data.address === '' ||
             this.data.phone_number === '' ||
             this.data.fax === '' ||
             this.data.place_type === ''
    },
  },
  mounted() {
    this.getProvinces()
  },
  watch: {
    province(new_val) {
      this.city = null
      if(new_val){
        this.getCityByProvince(new_val.value)
        this.disabledCity = false
      }else {
        this.disabledCity = true
      }
    }
  }
}
</script>

<style>

</style>