<template>
  <main>
    <a @click.prevent="$router.go(-1)" href="#" class="link-back"><span class="icon-ico-arrow-left"></span> Edit Target</a>
    <div class="white-box mb30 no-mh">
      <div class="p20">
        <div class="font-500">Current Target: {{displayDate}}</div>
      </div>
      <div class="table-responsive" style="min-height: 400px;">
        <table class="table">
          <thead>
            <tr>
              <th>No</th>
              <th>Customer</th>
              <th>Grade</th>
              <th>Purch. Type</th>
              <th>TOP</th>
              <th>Trgt. Order</th>
              <th>Trgt. Sales</th>
              <th>W1</th>
              <th>W2</th>
              <th>W3</th>
              <th>W4</th>
              <th>W5</th>
              <th>Remarks</th>
              <th>Outstanding Order</th>
              <th>Ship To</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(target, index) in targets" :key="index">
              <td>{{index + 1}}</td>
              <td>
                <v-select 
                  :disabled="target.status !== 'Open'"
                  :filterable="false"
                  @search="debounceSearch"
                  placeholder="Choose One" 
                  v-model="target.customer" 
                  :options="customers"
                  class="form-select-cust-target"
                  label="label"
                  >
                  <template v-if="customers.length >= 10 && (customers.length < totalCustomers)" #list-footer>
                    <button @click="loadMoreCustomers('customers')" class="d-flex py-2" style="opacity: 0.8; margin: auto; background: transparent; border: none">
                    {{ onLoadMore ? 'Please wait...' : 'Click to load more' }}
                    </button>
                  </template>
                </v-select>
              </td>
              <td>
                <select :disabled="target.status !== 'Open'" class="form-select for-target w70" v-model="target.grade">
                  <option disabled value="">Choose One</option>
                  <option v-for="grade, index in grades" :key="index" :value="grade">{{grade}}</option>
                </select>
              </td>
              <td>
                <select :disabled="target.status !== 'Open'" class="form-select for-target w80" v-model="target.purchase_type">
                  <option disabled value="">Choose One</option>
                  <option v-for="type, index in purchaseTypes" :key="index" :value="type">{{type}}</option>
                </select>
              </td>
              <td>
                <select :disabled="target.status !== 'Open'" class="form-select for-target w80" v-model="target.top">
                  <option disabled value="">Choose One</option>
                  <option v-for="top, index in tops" :key="index" :value="top">{{top}}</option>
                </select>
              </td>
              <td>
                <input :disabled="target.status !== 'Open'" type="number" @keypress="checkValidationNumber" class="form-control target" placeholder="..." v-model="target.target_order">
              </td>
              <td>
                <input disabled type="number" @keypress="checkValidationNumber" class="form-control target " placeholder="..." v-model="target.target_sales">
              </td>
              <td>
                <input :disabled="target.status !== 'Open'" @change="event => sumTotalSales(event, index)" type="number" @keypress="checkValidationNumber" class="form-control target" placeholder="..." v-model="target.first_week">
              </td>
              <td>
                <input :disabled="target.status !== 'Open'" @change="event => sumTotalSales(event, index)" type="number" @keypress="checkValidationNumber" class="form-control target" placeholder="..." v-model="target.second_week">
              </td>
              <td>
                <input :disabled="target.status !== 'Open'" @change="event => sumTotalSales(event, index)" type="number" @keypress="checkValidationNumber" class="form-control target" placeholder="..." v-model="target.third_week">
              </td>
              <td>
                <input :disabled="target.status !== 'Open'" @change="event => sumTotalSales(event, index)" type="number" @keypress="checkValidationNumber" class="form-control target" placeholder="..." v-model="target.fourth_week">
              </td>
              <td>
                <input :disabled="target.status !== 'Open'" @change="event => sumTotalSales(event, index)" type="number" @keypress="checkValidationNumber" class="form-control target" placeholder="..." v-model="target.fifth_week">
              </td>
              <td>
                <input :disabled="target.status !== 'Open'" type="text" class="form-control target" placeholder="..." v-model="target.remarks">
              </td>
              <td>
                <input :disabled="target.status !== 'Open'" type="text" class="form-control target" placeholder="..." v-model="target.outstanding_order">
              </td>
              <td>
                <v-select 
                  :disabled="target.status !== 'Open'"
                  :filterable="false"
                  @search="debounceSearch2"
                  placeholder="Choose One" 
                  v-model="target.shipTo" 
                  :options="shipsTo"
                  class="form-select-cust-target"
                  label="label"
                  >
                  <template v-if="shipsTo.length >= 10 && (shipsTo.length < totalShipsTo)" #list-footer>
                    <button @click="loadMoreCustomers" class="d-flex py-2" style="opacity: 0.8; margin: auto; background: transparent; border: none">
                    {{ onLoadMore ? 'Please wait...' : 'Click to load more' }}
                    </button>
                  </template>
                </v-select>
              </td>
              <td>
                <div v-if="target.status === 'Open'" class="status green">Open</div>
                <div v-else class="status grey">Close</div>
              </td>
              <td>
                <button v-if="target.status === 'Open'" @click="manageTarget(null, index)" class="btn btn-delete del"><span class="icon-ico-delete"></span></button>
              </td>
            </tr>
          </tbody>
        </table>
        <button @click="manageTarget('add')" class="btn btn-add"><span class="icon-ico-add"></span> Add More</button>
      </div>
      <div v-if="showAction" id="target-edit" class="d-flex justify-content-center flex-wrap p30">
        <button v-if="showDelete" @click="deleteTarget" class="btn btn-border-red mx-2 mm15" type="button">Delete</button>
        <button :disabled="disabledUpdate" v-if="showUpdate" @click="updateTarget" class="btn btn-orange mx-2 mm15" type="button">Update</button>
        <button v-if="showCloseTarget" @click="closeTarget" class="btn btn-green del mx-2 mm15" type="button">Close Target</button>
      </div>
    </div>
  </main>
</template>

<script>
import {showErrorMessage, ifBothArrayIsEqual, checkValidationNumber} from '@/utils';
import axios from '@/axios';
import moment from 'moment';


export default {
  data() {
    return {
      checkValidationNumber,
      customers: [],
      shipsTo: [],
      grades: ["MAS332", "ECO201", "OPI555"],
      purchaseTypes: ["Spot", "Distributor"],
      tops: ["Term14", "CORP", "KULT"],

      targets: [],
      temp_targets: [],
      deleted_ids: [],
      has_attempt_close_target: false,
      displayDate: '',
      showAction: true,

      debounce: null,
      debounce2: null,

      totalCustomers: 0,
      totalShipsTo: 0,

      query: '',
      query2: '',
      onLoadMore: false,
      initialPageCust: 2,
      initialPageCust2: 2
    }
  },
  methods: {
    loadMoreCustomers(type){
      this.onLoadMore = true
      const page = type === 'customers' ? this.initialPageCust++ : this.initialPageCust2++
      this.getCustomers(null, page, type)
        .then(() => {
          this.onLoadMore = false
        })
    },
    debounceSearch(query, loading){
      clearTimeout(this.debounce)
      this.query = query
      this.initialPageCust = 1
      this.debounce = setTimeout(() => {
        this.customers = []
        this.getCustomers(loading, this.initialPageCust, 'customers')
      }, 500)
    },
    debounceSearch2(query, loading){
      clearTimeout(this.debounce2)
      this.query2 = query
      this.initialPageCust2 = 1
      this.debounce2 = setTimeout(() => {
        this.shipsTo = []
        this.getCustomers(loading, this.initialPageCust2)
      }, 500)
    },
    getCustomers(loading, page, type) {
      loading && loading(true)
      const query = type === 'customers' ? this.query : this.query2
      return axios.get(`/customers?per_page=10&page=${page}&search=${query}`)
        .then(res => {
          const customers = res.data.data
          type === 'customers' ? this.totalCustomers = customers.total : this.totalShipsTo = customers.total
          loading && loading(false)
          customers.data.map(customer => {
            if(type === 'customers') {
              this.customers.push({
                label: customer.name,
                value: customer.id,
              })
            } else {
              this.shipsTo.push({
                label: customer.name,
                value: customer.id,
              })
            }
          })
      }).catch(err => {
          showErrorMessage(err)
          loading && loading(false)
      }) 
    },

    getFormsData() {
      this.$store.dispatch('toggleLoadingProgress', true)
      this.getDisplayDate()

      const getCustomers = axios.get('/customers?per_page=10&page=1')
      const getCurrentTarget = axios.get(`/target-sales?month_id=${this.$route.params.id}`)
      
      Promise.all([getCustomers, getCurrentTarget]).then(res => {
        this.$store.dispatch('toggleLoadingProgress', false)
        
        const customers = res[0].data.data
        this.totalCustomers = this.totalShipsTo = customers.total
        customers.data.map(customer => {
          this.customers.push({
            label: customer.name,
            value: customer.id,
          })
          this.shipsTo.push({
            label: customer.name,
            value: customer.id,
          })
        })

        const target = res[1].data.data
        if(target.data) {
          const targets = JSON.parse(JSON.stringify(target.data))
          this.temp_targets =  JSON.parse(JSON.stringify(this.targets))
          const status = []
          targets.forEach(target => {
            status.push(target.status)
            target.customer = {
              label: target.customer.name,
              value: target.customer.id
            }
            target.shipTo = {
              label: target.ship_to.name,
              value: target.ship_to.id
            }
            // CHeck if customer not listed in customers list
            const _idx1 = this.customers.findIndex(cust => cust.label === target.customer.label)
            _idx1 === -1 && this.customers.unshift(target.customer)

            // CHeck if ship_to_customer not listed in ship_to-customers list
            const _idx2 = this.shipsTo.findIndex(cust => cust.label === target.shipTo.label)
            _idx2 === -1 && this.shipsTo.unshift(target.shipTo)

            delete target.ship_to
          })

          this.targets = targets

          if(!status.includes('Open')){
            this.showAction = false
            this.$notify({ type: 'warn', title: 'Warning', text: 'Target is closed' })
            setTimeout(() => {
              this.$router.push('/target')
            }, 1000);
          }
        } else {
          this.showAction = false
          this.$notify({ type: 'warn', title: 'Warning', text: 'Data not found' })
          setTimeout(() => {
            this.$router.push('/target')
          }, 500);
        }
      }).catch(error => {
        showErrorMessage(error)
      })
    },
    manageTarget(type, index) {
      if(type == 'add') {
        this.targets.push({
            shipTo: null,
            customer: null,
            customer_id: '',
            grade: '',
            purchase_type: '',
            top: '',
            target_order: '',
            target_sales: '',
            first_week: '',
            second_week: '',
            third_week: '',
            fourth_week: '',
            fifth_week: '',
            remarks: '',
            outstanding_order: '',
            ship_to_id: '',
            status: 'Open'
        })
      } else {
        let target = this.targets.splice(index, 1)
        this.deleted_ids.push(target[0].id)
      }
    },
    updateTarget() {
      if(!this.disabledUpdate){
        this.$store.dispatch('toggleLoadingProgress', true)
        let data = []
        this.targets.forEach(target => {
          let newTarget = {
            customer_id: target.customer.value,
            grade: target.grade,
            purchase_type: target.purchase_type,
            top: target.top,
            target_order: target.target_order,
            target_sales: target.target_sales === '' ? 0 : target.target_sales,
            first_week: target.first_week === '' ? 0 : target.first_week,
            second_week: target.second_week === '' ? 0 : target.second_week,
            third_week: target.third_week === '' ? 0 : target.third_week,
            fourth_week: target.fourth_week === '' ? 0 : target.fourth_week,
            fifth_week: target.fifth_week === '' ? 0 : target.fifth_week,
            outstanding_order: target.outstanding_order,
            remarks: target.remarks,
            ship_to_id: target.shipTo.value,
          }
          if(target.id) newTarget.id = target.id
          if(target.created_by) newTarget.created_by = target.created_by.nik
          data.push(newTarget)
        })
  
        let obj = {
          month_id: this.$route.params.id,
          deleted_ids: this.deleted_ids,
          data
        }
  
        axios.put('/target-sales/bulk', obj)
          .then(() => {
            this.$store.dispatch('toggleLoadingProgress', false)
            this.$notify({ type: 'success', title: 'Success', text: 'Success edit target' })
            if(!this.has_attempt_close_target){
              this.$router.push({name: 'Target'})
            }else{
              this.getFormsData()
              this.has_attempt_close_target = false
            }
          }).catch(error => {
            showErrorMessage(error)
          })
      }
    
    },
    deleteTarget() {
      if(confirm('Are you sure you want to delete this target?')){
        this.$store.dispatch('toggleLoadingProgress', true)

        this.targets.forEach(target => {
          this.deleted_ids.push(target.id)
        })
        let headers = {}
        let data = {
          month_id: this.$route.params.id,
          ids: this.deleted_ids
        }

        axios.delete('/target-sales/bulk', {headers, data})
          .then(() => {
            this.$store.dispatch('toggleLoadingProgress', false)
            this.$notify({ type: 'success', title: 'Success', text: 'Success delete target' })
            this.$router.push({name: 'Target'})
          }).catch(error => {
            showErrorMessage(error)
          })
        }
    },
    closeTarget(){
      if(ifBothArrayIsEqual(this.targets, this.temp_targets)){
        this.$store.dispatch('toggleLoadingProgress', true)
        let ids = []
        this.targets.forEach(({id}) => ids.push(id))
        let data = {
          ids,
          status: "Closed"
        }
        axios.put('/target-sales/bulk/status', data)
          .then(() => {
            this.$store.dispatch('toggleLoadingProgress', false)
            this.$notify({ type: 'success', title: 'Success', text: 'Success close target' })
            this.$router.push({name: 'Target'})
          }).catch(error => {
            showErrorMessage(error)
          })
      }else {
        this.$notify({ type: 'warn', title: 'Warning', text: `Please update target before closing the target` })
        this.has_attempt_close_target = true
      }
    },
    getDisplayDate() {
      const id = this.$route.params.id
      const year = id.substr(0, 4)
      let month_id = id.slice(-2)
      if(month_id[0] == 0) month_id = month_id[1]

      const months = moment.months()
      months.forEach((month, index) => {
        if(index == month_id - 1){
          this.displayDate = `${month} ${year}`
        }
      })
    },
    checkIfTargetsHasNoValue() {
      let targetValue = []
      this.targets.forEach(target => {
         let i = Boolean(target.grade === '' ||
               target.purchase_type === '' ||
               target.top === '' ||
               target.target_order === '' ||
               target.shipsTo === null ||
               target.outstanding_order === '' ||
               target.customer === null)
          targetValue.push(i)
      })
      return targetValue.includes(true)
    },
    sumTotalSales(event, index) {
      const target = this.targets[index]
      let weeks = ["first_week", "second_week", "third_week", "fourth_week", "fifth_week"]
      weeks.forEach(week => {
        if(target[week] !== '') {
          let newVal = parseInt(target[week], 10)
          target[week] = newVal
        }
      })
      target.target_sales = +target.first_week + +target.second_week + +target.third_week + +target.fourth_week + +target.fifth_week
    }
  },
  mounted() {
    this.getFormsData()
  },
  computed: {
    AppActiveUser()     { return this.$store.state.AppActiveUser },
    showCloseTarget() {
      return ((new Date().getDate() >= 25 && (this.$route.params.id == moment().format('YYYYMM'))) && this.AppActiveUser.permissions.includes('Close Target')) || moment(this.$route.params.id).isBefore(moment().format('YYYYMM'))
    },
    showUpdate(){
      return this.AppActiveUser.permissions.includes('Edit Target')
    },
    disabledUpdate() {
      return this.checkIfTargetsHasNoValue()
    },
    showDelete(){
      return this.AppActiveUser.permissions.includes('Delete Target')
    },
  }
}
</script>

<style>

</style>